import React, { useState, useMemo, useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import useApiContext from 'hooks/useApiContext';
import useModalContext from 'hooks/useModalContext';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  TextField,
  InputAdornment,
  Button,
  Alert,
} from '@mui/material';
import {
  useLocation,
  Outlet,
  Link,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { format, isAfter } from 'date-fns';
import useRepo from 'hooks/useRepo';
import { T, O, M, A } from 'TOMA';
import s from './UsersList.module.scss';
import routes from 'router/routes';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Switch } from 'components/Switch';
import { CustomLink } from 'components/CustomLink';
import { eRoles } from '../../interfaces/Roles';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<any>();

function Users(): JSX.Element {
  const [csvError, setCsvError] = useState<string | undefined>(undefined);
  const [csvSuccess, setCsvSuccess] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const { setModalContent, setModalVisible } = useModalContext();
  const { pathname } = useLocation();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const {
    userRepository,
    exhibitorRepository,
    exhibitorEventRepository,
    codificationRepository,
    preferenceRepository,
  } = useApiContext();
  const user = userRepository.getUser();
  const isSuperAdmin = user?.role === eRoles.SUPER_ADMIN;

  const [filterCollectif, setFilterCollectif] = useState<any>();
  const [filterName, setFilterName] = useState<any>();
  const [reload, setReload] = useState<number>(0);
  const [options, setOptions] = useState<any>();
  const { eventId } = useParams();
  const {
    response = [],
    loading,
    error,
  } = useRepo(async () => {
    return await userRepository.getUsers();
  }, [reload]);
  const dataOptions = useRepo(async () => {
    return await userRepository.getOptions().then((e) => e);
  }, [pathname]);
  useEffect(() => {
    if (!dataOptions.loading && dataOptions.response) {
      setOptions(dataOptions.response);
      console.log(dataOptions.response);
    }
  }, [dataOptions.loading, dataOptions.response]);
  const outletRef = useRef<HTMLDivElement>(null);

  const { response: dateLimit } = useRepo(
    async () => await preferenceRepository.find('date_limit'),
    [reload]
  );

  const { response: codifCollectif } = useRepo(
    async () => await codificationRepository.get('collectif')
  );
  const { t } = useTranslation();
  const columns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor('role', {
        header: t('users:role'),
      }),
      columnHelper.accessor('count_exhibitors', {
        header: t('users:number_of_authorized_exhibitors'),
        cell: ({ getValue }) => {
          const { count, role } = getValue();
          if (role === 'EXHIBITOR') {
            return '-';
          }
          return count;
        },
      }),
      columnHelper.accessor('firstname', {
        header: t('users:firstname'),
      }),
      columnHelper.accessor('lastname', {
        header: t('users:lastname'),
      }),
      columnHelper.accessor('email', {
        header: t('users:email'),
      }),
      columnHelper.accessor('edit', {
        header: '',
        cell: ({ getValue }) => {
          const { id, role } = getValue();
          console.log(role, 'ROLE');
          return (
            <div>
              <CustomLink to={routes.home2.path + `users/${id}`} color="info">
                {t('exhibitors:edit')}
              </CustomLink>
            </div>
          );
        },
      }),
    ];

    // Conditionally add count_moderators column if user is not ADMIN
    if (user?.role !== 'ADMIN') {
      baseColumns.splice(
        1,
        0,
        columnHelper.accessor('count_moderators', {
          header: t('admin:number_of_moderators'),
          cell: ({ getValue }) => {
            const { count, role } = getValue();
            if (role === 'EXHIBITOR' || role === 'MODERATOR') {
              return '-';
            }
            return count;
          },
        })
      );
    }
    if (user?.role !== 'ADMIN') {
      baseColumns.splice(
        1,
        0,
        columnHelper.accessor('moderators', {
          header: 'Moderators',
          cell: ({ getValue }) => {
            const { count, role } = getValue();
            if (role === 'EXHIBITOR' || role === 'MODERATOR') {
              return '-';
            }
            return count;
          },
        })
      );
    }

    return baseColumns;
  }, [user]);
  useEffect(() => {
    console.log(response, 'RESR');
    if (!loading) {
      console.log(response, 'RESR');
    }
  }, [response, loading]);
  const data = useMemo(
    () =>
      // @ts-expect-error
      response?.map((e: any) => {
        return {
          role: e.role,
          count_exhibitors: {
            count: e.count_of_exhibitors,
            role: e.role,
          },
          count_moderators: {
            count: e.count_of_moderators,
            role: e.role,
          },
          firstname: e.firstname,
          lastname: e.lastname,
          email: e.email,
          moderators: {
            count: e.moderators,
            role: e.role,
          },
          edit: {
            id: e.id,
            role: e.role,
          },
        };
      }),
    [response, codifCollectif, filterCollectif, filterName]
  );

  const table = useReactTable({
    // data: data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    data,
    columns,
    state: {
      sorting,
      // pagination,
    },
    // pageCount: Math.ceil(data.length / pageSize) || 1,
    manualPagination: true,
    // onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
  });
  const navigate = useNavigate();
  const role =
    user?.role === 'SUPER_ADMIN' ? routes.users.path : routes.profile.path;
  const classActive =
    pathname === role ? false : !pathname.match(/^\/events\/[^/]+$/);

  const handleClickOutside = (event: any): void => {
    if (outletRef.current && !outletRef.current.contains(event.target)) {
      // Determine the redirection path
      const redirectTo = routes.users.path;

      // Trigger navigation (redirect)
      navigate(redirectTo);
      setReload((e) => e + 1);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  console.log(isSuperAdmin, 'SUPER_ADMIN');
  return (
    <div>
      <div className={classActive ? s.blur : undefined}>
        <T.Container>
          <M.Loading loading={loading || csvLoading} error={error} fullWiewport>
            {!pathname.includes('events') ? (
              <>
                <A.Title
                  as="h1"
                  fontSize="48"
                  mb="30"
                  mt="50"
                  fw="black"
                  textAlign="center"
                >
                  {t('users:list_of_users')}
                </A.Title>
                {isSuperAdmin ? (
                  <T.Block className={s.right}>
                    <A.Button>
                      <CustomLink
                        to={routes.home2.path + `users/new-user`}
                        color="white"
                      >
                        {t('users:create_a_new_moderator')}
                      </CustomLink>
                    </A.Button>
                  </T.Block>
                ) : (
                  ''
                )}
                {(options && options.moderators > 0) ||
                options?.exhibitors > 0 ? (
                  <T.Block className={s.right}>
                    <A.Button>
                      <CustomLink
                        to={routes.home2.path + `users/new-user`}
                        color="white"
                      >
                        {t('users:create_a_new_moderator')}
                      </CustomLink>
                    </A.Button>
                  </T.Block>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
            <T.Block mb="20">
              <table className={s.Table}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          <A.Button
                            appearance="link"
                            color="grey-80"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                            {{
                              asc: <A.Icon icon="chevron-down" ml="5" />,
                              desc: <A.Icon icon="chevron-up" ml="5" />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </A.Button>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => {
                    return (
                      <tr
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? 'white' : '#F8F8F8',
                        }}
                        key={row.id}
                        className={s.ExhibitorList__row}
                        data-removed={row.getIsSelected() ? 'true' : undefined}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            <div className={s.ExhibitorList__row__div}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </T.Block>
          </M.Loading>
        </T.Container>
      </div>
      {classActive && (
        <div className={s.ExhibitorList__outlet}>
          <div>
            <Link
              className={s.background}
              to={
                user?.role === 'ADMIN'
                  ? routes.home.path.replace(/\/$/, '') + routes.users.path
                  : routes.users.path
              }
              onClick={() => setReload((e) => e + 1)}
            />
          </div>
          <Link
            className={s.ExhibitorList__outlet__close}
            to={
              user?.role === 'ADMIN'
                ? routes.home.path.replace(/\/$/, '') + routes.users.path
                : routes.users.path
            }
            onClick={() => setReload((e) => e + 1)}
          >
            <div>
              <A.Icon icon="x-lg" />
            </div>
          </Link>
          <div className={s.ExhibitorList__outlet__content} ref={outletRef}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
}

export default Users;
