export default {
  event: {
    events: 'Events',
    societe: 'Company',
    contact: 'Contact for the event',
    list_of_events: 'List of events',
    new_event: 'New Event',
    participer: 'Visible',
    name_of_the_event: 'Name of the event',
    date: 'Date',
    country: 'Country',
    creator: 'Creator',
    status: 'Status',
    simple_extraction: 'Simple Extraction',
    full_extraction: 'Full Extraction',
    upload_csv: 'Upload CSV',
    add_exhibitors: 'Add exhibitors',
    find_an_exhibitors: 'Find an exhibitor',
    list_of_exhibitors: 'List of Exhibitors',
    export_exhibitors: 'Export exhibitor',
    event_informations: 'Event informations',
    start_event: 'Date of the start of the event',
    end_event: 'Date of the end of the event',
    city_of_event: 'City of the event',
    country_of_event: 'Country of the event',
    registration_closing_date: 'Registration closing date',
    showroom_on_event: 'Showroom on this event',
    sample_informations: 'Samples informations',
    numbers_of_sample_send: 'Number of samples to send',
    address_to_send_semple: 'Address to send samples',
    limit_date_to_receive_sample: 'Limit date to receive samples',
    registration: 'Registration',
    event_information: 'Event information',
    contact_for_this_event: 'Contact for this event',
    information_de_la_societe: 'Company Information',
    sur_name: 'Sur name',
    last_name: 'Last name',
    mobile_phone: 'Mobile phone',
    email: 'Email',
    contact_position: 'Contact position',
    your_products: 'Your selection of products',
    possibility_of_presenting_up_to_10_references:
      'Possibility of presenting up to 10 references',
    select_product: 'Select product',
    add_a_new_product: 'Create a new product',
    save: 'Save',
    save_and_transmit: 'Save and transmit',
    possibility_to_reconnect_and_sequence_the_entry_of_information:
      '*Possibility to reconnect and sequence the entry of information.',
    your_entry_is_finalized_the_elements_can_no_longer_be_modified:
      '**Your entry is finalized, the elements can no longer be modified.',
    date_of_event: 'Date of event',
    add_product_from_catalog: 'Add product from your catalog',
    invite: 'Invite',
    subtitle_of_the_event: 'Subtitle of the event',
    logo: 'Logo',
    e_catalogue_url: 'E-catalogue URL',
    inviter_par_email: 'Invite by e-mail',
    send_email: 'Send email',
    copy_the_link: 'Copy the link',
    copy_the_link_success: 'The invitation code has been copied successfully',
    invite_with_email: 'Invite with email*',
    import_exhibitor_database: 'Import exhibitor database',
    active: 'Active',
    icon: 'Add the selected product from the catalog',
    dossier: 'Case',
    edit: 'Edit',
    required: 'This field is required',
    number_exhibitors: 'Number of exhibitors registered for this event',
    add_exhibitor_to_the_event: 'Add exhibitor to the event',
    company: 'Raison sociale',
    type_of_product: 'Type of product',
    color_of_wine: 'Color of wine',
    grape_varietal: 'Grape variety',
    type_of_growing: 'Type of wine-growing',
    add_exhibitor: 'Add exhibitor',
    search: 'Search',
    add_exhibitor_to_the_event_selection: 'Add selected exhibitors',
    exhibitror_successfilly_updated:
      'Exhibitor information has been successfully updated',
  },
};
