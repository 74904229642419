import React, { useEffect, useState } from 'react';
import { A } from 'TOMA';

interface iSwitch {
  name?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange: (e: boolean) => Promise<Boolean>;
  disable?: boolean;
}

export function Switch({
  name,
  defaultChecked,
  checked,
  onChange,
  disable = false,
}: iSwitch): JSX.Element {
  const [isChecked, setChecked] = useState<boolean>(
    defaultChecked ?? checked ?? false
  );

  useEffect(() => {
    if (checked !== undefined) setChecked(checked);
  }, [checked]);

  return (
    <A.InputSwitch
      disabled={disable}
      name={name}
      checked={isChecked}
      onChange={(e) => {
        setChecked(e);
        onChange(e).catch(() => setTimeout(() => setChecked(!e), 500));
      }}
    />
  );
}

Switch.defaultProps = {
  iconBefore: undefined,
  iconAfter: undefined,
  disabled: undefined,
};
