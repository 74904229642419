import React, { useContext, useEffect, useState } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { A } from 'TOMA';
import { FormContext } from '../Form';
import { useTranslation } from 'react-i18next';

export interface iValueAC {
  value: string;
  label: string;
}

type iInputAutocomplete<T extends FieldValues> = {
  name: string;
  control: Control<T, React.ReactNode>;
  label?: string;
  placeholder?: string;
  required?: string | boolean;
  disabled?: boolean;
  options: iValueAC[] | undefined;
} & UseControllerProps<T>;

export function InputAutocomplete<T extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  required,
  disabled,
  options,
}: iInputAutocomplete<T>): JSX.Element {
  const { t } = useTranslation();
  const [opts, setOpts] = useState<any | undefined>(undefined);
  const [values, setValues] = useState<any | undefined>(undefined);
  const translation = useContext(FormContext);
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isTouched },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
    rules: {
      required: required === true ? translation.required : required,
    },
  });

  const path = name.split('.');
  let key;
  let error: any = errors;
  while ((key = path.shift())) {
    if (!Number.isNaN(parseInt(key, 10))) {
      key = parseInt(key, 10);
    }
    if (!error[key]) {
      error = undefined;
      break;
    }
    error = error[key];
  }

  const errorMessage = (isTouched || isSubmitted) && error && error.message;

  useEffect(() => {
    setValues(
      options?.filter((e: any) => value.some((ee: string) => ee === e.value))
    );
    setOpts(options);
  }, [options]);

  useEffect(() => {
    if (opts) {
      setValues(
        opts.filter((e: any) => value.some((ee: string) => ee === e.value))
      );
    }
  }, [value]);

  return (
    <A.InputAutocomplete
      name={name}
      label={label}
      placeholder={placeholder ?? t('common:form.choose')}
      value={values}
      onChange={(e) => {
        if (Array.isArray(e)) onChange(e?.map((e: any) => e.value));
      }}
      onBlur={onBlur}
      required={!!required}
      error={errorMessage}
      disabled={disabled}
      multiple
      options={opts}
      loading={!options}
      ref={ref}
    />
  );
}
InputAutocomplete.defaultProps = {
  inputMode: undefined,
  label: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
};
