import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { A } from '..';
import styles from './InputMedias.module.scss';
import Handle from 'rc-slider/lib/Handles/Handle';

export interface iInputMedias {
  label?: React.ReactNode;
  addFileLabel?: string;
  information?: React.ReactNode;
  name: string;
  id?: string;
  disabled?: boolean;
  error?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFileChange?: (e: FileList | undefined | null) => void;
  onRemove?: () => void;
  onZoom?: (src: string) => void;
  loading?: boolean;
  media?: { src: string; filename: string };
  required?: boolean;
  dragNdrop?: boolean;
}

const InputMedias = React.forwardRef(function InputMedias(
  {
    label,
    addFileLabel,
    information,
    name,
    id,
    disabled,
    loading,
    onFileChange,
    onChange,
    onRemove,
    onZoom,
    error,
    media,
    required,
  }: iInputMedias,
  outerRef: React.ForwardedRef<HTMLInputElement>
): JSX.Element {
  const [dragging, setDragging] = React.useState(false);
  const innerRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(outerRef, () => innerRef.current!, []);

  useEffect(() => {
    if (!labelRef.current) return;

    labelRef.current.addEventListener(
      'dragenter',
      function (evt) {
        evt.preventDefault();
        setDragging(true);
      },
      false
    );

    labelRef.current.addEventListener(
      'dragover',
      function (evt) {
        evt.preventDefault();
        setDragging(true);
      },
      false
    );

    labelRef.current.addEventListener(
      'dragleave',
      function (evt) {
        evt.preventDefault();
        setDragging(false);
      },
      false
    );

    labelRef.current.addEventListener(
      'drop',
      function (evt) {
        evt.preventDefault();
        setDragging(false);

        const dt = evt.dataTransfer;
        const files = dt?.files;

        if (onFileChange) onFileChange(files);
      },
      false
    );
  }, [media, disabled]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onFileChange) onFileChange(e.target.files);
    if (onChange) onChange(e);
  };

  return (
    <div className={styles.InputMedias}>
      {label && (
        <div
          className={styles.InputMedias__label}
          data-error={!!error}
          data-disabled={disabled}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </div>
      )}
      {information && (
        <A.Text
          as="div"
          mb="10"
          mt="-3"
          fontSize="14"
          color={disabled ? 'grey-20' : 'grey-50'}
        >
          {information}
        </A.Text>
      )}
      <div className={styles.InputMedias__wrap}>
        {!media && !disabled && (
          <label
            htmlFor={id ?? name}
            className={styles.InputMedias__button}
            data-error={!!error}
            data-disabled={disabled}
            ref={labelRef}
            data-dragging={dragging}
          >
            <A.Icon icon="plus-circle" />
            <span>{addFileLabel}</span>
            <input
              name={name}
              type="file"
              id={id ?? name}
              onChange={handleChange}
              accept="image/png, image/jpeg, image/jpg"
              className={styles.InputMedias__field}
              disabled={disabled ?? loading}
              ref={innerRef}
              required={required}
            />
          </label>
        )}
        {media && (
          <div className={styles.InputMedias__thumbnail}>
            <A.Icon icon="image-alt" fontSize="24" color="grey-30" />
            <img
              src={media.src}
              alt={media.filename}
              className={styles.InputMedias__media}
              onClick={() => {
                if (onZoom) onZoom(media.src);
              }}
              data-zoom={!!onZoom}
            />
            {!disabled && (
              <button
                className={styles.InputMedias__remove}
                type="button"
                aria-label="remove"
                onClick={onRemove}
              >
                <A.Icon icon="x-lg" fontSize="14" color="black" />
              </button>
            )}
          </div>
        )}
        {loading && (
          <div className={styles.InputMedias__loading}>
            <A.Loading />
          </div>
        )}

        <A.Text as="div" mt="5" fontSize="14" color="alert">
          {error}
        </A.Text>
      </div>
    </div>
  );
});

export { InputMedias };
