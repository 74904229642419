import React, { useEffect, useState } from 'react';
import { useLocation, Outlet, Link, useParams } from 'react-router-dom';
import useApiContext from 'hooks/useApiContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import useModalContext from 'hooks/useModalContext';
import { T, O, M, A } from 'TOMA';
import { InputMedias, InputText, InputTextarea } from 'form';
import { eRoles } from 'interfaces/Roles';
import s from './Event.module.scss';
import useRepo from '../../hooks/useRepo';
import routes from '../../router/routes';
import {
  LocalizationProvider,
  DatePicker,
  DesktopDatePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parse } from 'date-fns';
import { Switch, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface iForm {
  city: string;
  date: string;
  country: string;
  url: string;
  name: string;
  number_of_samples: string;
  showroom: boolean;
  show_samples_information: boolean;
  address: string;
  limit_date: string;
  deadline_date: string;
  date_of_the_end: string;
  subtitle: string;
  footer_text: string;
  contact_email_1: string;
  contact_email_2: string;
  logo: number;
}

function Event(): JSX.Element {
  const { setModalContent } = useModalContext();
  const { eventRepository, userRepository, mediaRepository } = useApiContext();
  const { pathname } = useLocation();
  const user = userRepository.getUser();
  const [loadingForm, setLoadingForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [errorForm, setErrorForm] = useState<string>();
  const [file, setFile] = useState('');
  const { t } = useTranslation();

  const [value, setValuedate] = useState<any>(null);
  const isAdmin =
    user?.role === eRoles.ADMIN || user?.role === eRoles.MODERATOR;
  const { eventId } = useParams();
  console.log(eventId);
  const schema = yup.object({
    date: yup.string().max(255).required('This field is required').nullable(),
    country: yup
      .string()
      .max(255)
      .required('This field is required')
      .nullable(),
    name: yup.string().max(255).required('This field is required').nullable(),
  });
  const form = useForm<iForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const { response, loading } = useRepo(
    async () =>
      eventId !== undefined
        ? await eventRepository.getEventById(eventId).then((e) => e)
        : '',
    [pathname]
  );
  useEffect(() => {
    console.log(loading, 'loading', eventId, 'eventId', response, 'response');
    if (eventId && !loading && response) {
      console.log(response, 'response');
      // Update form values when response is available
      // @ts-expect-error
      reset(response);
    }
  }, [loading, response, form.reset]);
  const { handleSubmit, watch, control, reset, setValue, getValues } = form;
  const [showSamples, setShowSamples] = useState(false);
  const showSamplesValue = watch('show_samples_information');
  useEffect(() => {
    console.log(showSamplesValue);
    setShowSamples(!showSamplesValue);
  }, [showSamplesValue]);
  function onSubmit(data: iForm): void {
    setLoadingForm(true);
    if (eventId) {
      eventRepository
        .updateFormInformation(data, eventId)
        .then(() => {
          setSuccessForm(true);
          setErrorForm('');
        })
        .catch((err: Error) => {
          setErrorForm(err.message);
        })
        .finally(() => {
          setLoadingForm(false);
        });
    } else {
      eventRepository
        .createEvent(data)
        .then(() => {
          setSuccessForm(true);
          setErrorForm('');
        })
        .catch((err: Error) => {
          setErrorForm(err.message);
        })
        .finally(() => {
          setLoadingForm(false);
        });
    }
  }
  const createdFromRef = React.useRef(null);
  const createdFromRef1 = React.useRef(null);
  const createdFromRef2 = React.useRef(null);
  const createdFromRef3 = React.useRef(null);
  const [openCreatedFrom, setOpenCreatedFrom] = useState(false);
  const [openCreatedFrom1, setOpenCreatedFrom1] = useState(false);
  const [openCreatedFrom2, setOpenCreatedFrom2] = useState(false);
  const [openCreatedFrom3, setOpenCreatedFrom3] = useState(false);
  const mediaList: Array<
    'media_1' | 'media_2' | 'media_3' | 'media_4' | 'media_5'
  > = ['media_1', 'media_2', 'media_3', 'media_4', 'media_5'];
  return (
    <div>
      <div className={pathname !== '/' && !isAdmin ? s.blur : undefined}>
        <M.Loading loading={false} error={null} fullWiewport>
          <T.Block
            w={pathname.includes('new-event') ? '100' : '75'}
            mx={pathname.includes('new-event') ? '0' : 'auto'}
            bg="white"
            borderRadius="20"
          >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <T.Block>
                <T.Block p="20">
                  <A.Title
                    as="h2"
                    textAlign="center"
                    fontSize="24"
                    mb="20"
                    fw="black"
                  >
                    {t('event:event_informations')}
                  </A.Title>
                  <A.Line mb="20" />
                  <T.Block
                    ml={!eventId ? '50' : '0'}
                    w={!eventId ? '75' : '100'}
                  >
                    <InputText
                      label={t('event:name_of_the_event')}
                      name="name"
                      type="text"
                      control={control}
                      disabled={user?.role === 'MODERATOR'}
                      required
                    />
                    <InputText
                      label={t('event:subtitle_of_the_event')}
                      name="subtitle"
                      type="text"
                      control={control}
                      disabled={user?.role === 'MODERATOR'}
                      required
                    />
                    <Controller
                      name="date"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <label>{t('event:start_event')}</label>
                          <DesktopDatePicker
                            format="dd/MM/yyyy"
                            views={['year', 'month', 'day']}
                            sx={{
                              width: '100%',
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                            value={
                              field.value
                                ? parse(field.value, 'yyyy-MM-dd', new Date())
                                : null
                            }
                            open={openCreatedFrom}
                            onClose={() => {
                              setOpenCreatedFrom(false);
                            }}
                            onChange={(newValue) => {
                              field.onChange(
                                newValue ? format(newValue, 'yyyy-MM-dd') : ''
                              );
                            }}
                            disabled={user?.role === 'MODERATOR'}
                            slotProps={{
                              textField: {
                                inputRef: createdFromRef,
                                onClick: () => {
                                  setOpenCreatedFrom((prev) => !prev);
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <Controller
                      name="date_of_the_end"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <label>{t('event:end_event')}</label>
                          <DesktopDatePicker
                            format="dd/MM/yyyy"
                            views={['year', 'month', 'day']}
                            sx={{
                              width: '100%',
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                            value={
                              field.value
                                ? parse(field.value, 'yyyy-MM-dd', new Date())
                                : null
                            }
                            open={openCreatedFrom3}
                            disabled={user?.role === 'MODERATOR'}
                            onClose={() => {
                              setOpenCreatedFrom3(false);
                            }}
                            onChange={(newValue) => {
                              field.onChange(
                                newValue ? format(newValue, 'yyyy-MM-dd') : ''
                              );
                            }}
                            slotProps={{
                              textField: {
                                inputRef: createdFromRef3,
                                onClick: () => {
                                  setOpenCreatedFrom3((prev) => !prev);
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <InputText
                      label={t('common:email_1')}
                      name="contact_email_1"
                      type="text"
                      control={control}
                      disabled={user?.role === 'MODERATOR'}
                      required
                    />
                    <InputText
                      label={t('common:email_2')}
                      name="contact_email_2"
                      type="text"
                      control={control}
                      disabled={user?.role === 'MODERATOR'}
                      required
                    />
                    <T.Block>
                      <InputMedias
                        label={t('event:logo')}
                        information={t('event:logo')}
                        name="logo"
                        control={control}
                        onInit={async (mediaId) => {
                          return await mediaRepository
                            .get(mediaId)
                            .then(({ id, w640, filename }) => ({
                              id,
                              src: w640,
                              filename,
                            }));
                        }}
                        onChange={async (file) => {
                          const formdata = new FormData();
                          formdata.append('media', file);
                          const result = await mediaRepository.upload(formdata);
                          if (result) {
                            setFile(result.id);
                            return result;
                          }
                          return null;
                        }}
                        onRemove={async (mediaId) => {
                          return await mediaRepository
                            .remove('logo', mediaId)
                            .then((e) => {
                              setFile('');
                            });
                        }}
                        onZoom={(src) => {
                          setModalContent(
                            <img src={src} style={{ maxWidth: '100%' }} />
                          );
                        }}
                        disabled={user?.role === 'MODERATOR'}
                        required
                      />
                    </T.Block>
                    <InputText
                      label={t('event:city_of_event')}
                      name="city"
                      type="text"
                      control={control}
                      disabled={user?.role === 'MODERATOR'}
                      required
                    />

                    <InputText
                      label={t('event:country_of_event')}
                      name="country"
                      type="text"
                      control={control}
                      disabled={user?.role === 'MODERATOR'}
                      required
                    />
                    <Controller
                      name="deadline_date"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <label>{t('event:registration_closing_date')}</label>
                          <DesktopDatePicker
                            format="dd/MM/yyyy"
                            disabled={user?.role === 'MODERATOR'}
                            views={['year', 'month', 'day']}
                            sx={{
                              width: '100%',
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                            value={
                              field.value
                                ? parse(field.value, 'yyyy-MM-dd', new Date())
                                : null
                            }
                            open={openCreatedFrom1}
                            onClose={() => {
                              setOpenCreatedFrom1(false);
                            }}
                            onChange={(newValue) => {
                              field.onChange(
                                newValue ? format(newValue, 'yyyy-MM-dd') : ''
                              );
                            }}
                            slotProps={{
                              textField: {
                                inputRef: createdFromRef1,
                                onClick: () => {
                                  setOpenCreatedFrom1((prev) => !prev);
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <InputText
                      label={t('event:e_catalogue_url')}
                      name="url"
                      type="text"
                      control={control}
                      disabled={user?.role === 'MODERATOR'}
                    />
                    <Controller
                      name="showroom"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                  color: '#28AB6E',
                                  '& + .MuiSwitch-track': {
                                    backgroundColor: 'rgb(0, 163, 3)',
                                  },
                                },
                              }}
                              {...field}
                              disabled={user?.role === 'MODERATOR'}
                              checked={!!field.value} // Convert to boolean
                            />
                          }
                          label={t('event:showroom_on_event')}
                          labelPlacement="end" // This places the label on the right and switch on the left
                        />
                      )}
                    />

                    <Controller
                      name="show_samples_information"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                  color: '#28AB6E',
                                  '& + .MuiSwitch-track': {
                                    backgroundColor: 'rgb(0, 163, 3)',
                                  },
                                },
                              }}
                              {...field}
                              checked={!!field.value} // Convert to boolean
                              disabled={user?.role === 'MODERATOR'}
                              onChange={(e) => {
                                field.onChange(e.target.checked); // Update the field value
                                setShowSamples(e.target.checked); // Update the local state
                              }}
                            />
                          }
                          label={t('event:sample_informations')}
                          labelPlacement="end" // Places the label on the right and switch on the left
                        />
                      )}
                    />
                  </T.Block>
                  {!showSamples ? (
                    <>
                      <A.Title
                        as="h2"
                        fontSize="24"
                        textAlign="center"
                        mt="20"
                        mb="20"
                        fw="black"
                      >
                        {t('event:sample_informations')}
                      </A.Title>
                      <A.Line mb="20" />
                      <T.Block
                        ml={!eventId ? '50' : '0'}
                        w={!eventId ? '75' : '100'}
                      >
                        <InputText
                          label={t('event:numbers_of_sample_send')}
                          name="number_of_samples"
                          type="text"
                          control={control}
                          disabled={user?.role === 'MODERATOR'}
                          required
                        />
                        <InputText
                          label={t('event:address_to_send_semple')}
                          name="address"
                          type="text"
                          control={control}
                          disabled={user?.role === 'MODERATOR'}
                          required
                        />
                        <Controller
                          name="limit_date"
                          control={control}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <label>
                                {t('event:limit_date_to_receive_sample')}
                              </label>
                              <DesktopDatePicker
                                format="yyyy-MM-dd"
                                views={['year', 'month', 'day']}
                                sx={{
                                  width: '100%',
                                  marginTop: '10px',
                                  marginBottom: '10px',
                                }}
                                value={
                                  field.value
                                    ? parse(
                                        field.value,
                                        'yyyy-MM-dd',
                                        new Date()
                                      )
                                    : null
                                }
                                open={openCreatedFrom2}
                                onClose={() => {
                                  setOpenCreatedFrom2(false);
                                }}
                                disabled={user?.role === 'MODERATOR'}
                                onChange={(newValue) => {
                                  field.onChange(
                                    newValue
                                      ? format(newValue, 'yyyy-MM-dd')
                                      : ''
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    inputRef: createdFromRef2,
                                    onClick: () => {
                                      setOpenCreatedFrom2((prev) => !prev);
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </T.Block>
                    </>
                  ) : (
                    ''
                  )}
                  <A.Alert alert="success" side="🎉" mb="15">
                    {successForm && 'Created a new event successfully!'}
                  </A.Alert>
                  <A.Alert alert="error" side="⚠️" mb="15">
                    {errorForm && <A.InnerHTML html={errorForm} />}
                  </A.Alert>
                </T.Block>
                {user?.role !== 'MODERATOR' ? (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <A.Button
                        type="button"
                        bg="success"
                        loading={loadingForm}
                        onClick={async () => {
                          handleSubmit(() => onSubmit(getValues()))();
                        }}
                        className={s.submit_button}
                        disabled={false}
                      >
                        {t('home:formInformation.save')}
                      </A.Button>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </T.Block>
            </form>
          </T.Block>
        </M.Loading>
      </div>
      {pathname !== '/' && !isAdmin && (
        <div className={s.Exhibitor__outlet}>
          <Link className={s.Exhibitor__outlet__close} to="/">
            <div>
              <A.Icon icon="x-lg" />
            </div>
          </Link>
          <div className={s.Exhibitor__outlet__content}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
}

export default Event;
