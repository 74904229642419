import React from 'react';
import './404.css';
function NotFound(): JSX.Element {
  return (
    <div style={{ overflowY: 'hidden' }}>
      <section className="error-container">
        <span>4</span>
        <span>
          <span className="screen-reader-text">0</span>
        </span>
        <span>4</span>
      </section>
    </div>
  );
}

export default NotFound;
