// import { Product } from 'pages/Product';
import Client from '../Client.provider';
import {
  iExhibitorListRest,
  iRest,
  iFormExhibitor,
  iExhibitorRest,
  iExhibitorListExhibitors,
} from './Exhibitor.type';
import { iProductExtractRest } from 'services/Product/Product.type';

export interface Ext {
  exhibitor: iExhibitorRest & { logo_url: string };
  products: Array<iProductExtractRest | null>;
}
export interface iExhibitorRepository {
  getExhibitors: (eventId: string) => Promise<iExhibitorListRest[]>;
  getExhibitorsWithoutEvent: () => Promise<iExhibitorListRest[]>;
  getExhibitorsList: (
    eventId: string | undefined,
    filter: any
  ) => Promise<iExhibitorListExhibitors[]>;
  getUserExhibitor: (idExhibitor?: string) => Promise<iRest>;
  extractUserExhibitor: (idExhibitor?: string) => Promise<Ext>;
  updateUserExhibitor: (
    form: iFormExhibitor,
    exhibitorId: number
  ) => Promise<iRest>;
  mergeUserExhibitor: (
    type: any,
    value: any,
    exhibitorId: number,
    eventId: string | undefined
  ) => Promise<boolean>;
  lockAllExhibitors: (locked: boolean) => Promise<boolean>;
  visibleAllExhibitors: (visible: boolean) => Promise<boolean>;
  uploadCSV: (form: FormData) => Promise<any>;
  uploadExhiCSV: (form: FormData) => Promise<any>;
  downloadCSV: (eventId: string) => Promise<any>;
  removeInvite: (
    exhibitorId: string | number | undefined,
    eventId: string | number | undefined
  ) => Promise<any>;
}

export class ExhibitorRepository
  extends Client
  implements iExhibitorRepository
{
  public async getExhibitors(eventId: string): Promise<iExhibitorListRest[]> {
    return await this.restricted()
      .get<iExhibitorListRest[]>(`/user/exhibitors/${eventId}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getExhibitorsWithoutEvent(): Promise<iExhibitorListRest[]> {
    return await this.restricted()
      .get<iExhibitorListRest[]>(`/user/exhibitors_list`)
      .then((e) => e.data);
  }

  public async getExhibitorsList(
    eventId: string | undefined,
    filter: any
  ): Promise<iExhibitorListExhibitors[]> {
    const query: string[] = [];
    query.push(`reload=${filter.reload}`);
    if (filter.couleur) {
      query.push(`color_wine=${filter.couleur}`);
    }
    if (filter.type_cepage) {
      query.push(`grape_veriety=${filter.type_cepage}`);
    }
    if (filter.type_culture) {
      query.push(`type_of_wine_growing=${filter.type_culture}`);
    }
    if (filter.type_product) {
      query.push(`type_of_product=${filter.type_product}`);
    }
    if (filter.search) {
      query.push(`search=${filter.search}`);
    }
    const resultQuery: string = '?' + query.join('&');
    return await this.restricted()
      .get<iExhibitorListExhibitors[]>(
        `/user/exhibitors_list/${
          eventId !== undefined ? eventId : 0
        }${resultQuery}`
      )
      .then((e) => e.data);
  }

  public async getUserExhibitor(idExhibitor: string = ''): Promise<iRest> {
    return await this.restricted()
      .get<iRest>(`/user/exhibitor${idExhibitor ? `/${idExhibitor}` : ''}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async extractUserExhibitor(idExhibitor: string = ''): Promise<Ext> {
    return await this.restricted()
      .get<{
        exhibitor: iExhibitorRest & { logo_url: string };
        products: Array<iProductExtractRest | null>;
      }>(`/user/exhibitor/extract${idExhibitor ? `/${idExhibitor}` : ''}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async updateUserExhibitor(
    form: iFormExhibitor,
    exhibitorId: number
  ): Promise<iRest> {
    return await this.restricted()
      .put<iRest>(`/user/exhibitor/${exhibitorId}`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async mergeUserExhibitor(
    type: string,
    value: any,
    exhibitorId: number,
    eventId: string | undefined
  ): Promise<boolean> {
    return await this.restricted()
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      .put<iRest>(`/user/exhibitor/merge/${exhibitorId}/${eventId}`, {
        [type]: value,
      })
      .then((e) => !e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async lockAllExhibitors(locked: boolean): Promise<boolean> {
    return await this.restricted()
      .put<iRest>(`/user/exhibitors/lock`, {
        locked,
      })
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async visibleAllExhibitors(visibility: boolean): Promise<boolean> {
    return await this.restricted()
      .put<iRest>(`/user/exhibitors/visibility`, {
        visibility,
      })
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async uploadCSV(form: FormData): Promise<any> {
    return await this.restricted()
      .post<any>(`/exhibitor/update/import`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async uploadExhiCSV(form: FormData): Promise<any> {
    return await this.restricted()
      .post<any>(`/user/import`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async downloadCSV(eventId: string): Promise<any> {
    return await this.restricted()
      .get<any>(`get-csv-file/${eventId}`)
      .then((e) => e)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async removeInvite(
    exhibitorId: string | number | undefined,
    eventId: string | number | undefined
  ): Promise<any> {
    return await this.restricted()
      .delete<any>(
        `remove-invite/${exhibitorId !== undefined ? exhibitorId : 0}/${
          eventId !== undefined ? eventId : 0
        }`
      )
      .then((e) => e)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
