/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { A } from 'TOMA';
import { iStyle, iColors, iSpacing, iText, iBorder } from 'TOMA/_HOC/withStyle';
import s from './InputStand.module.scss';

import { ReactComponent as Svg } from './loading.svg';

interface iInputStand extends iStyle, iColors, iSpacing, iBorder, iText {
  label: string;
  defaultValue: string;
  onChange: (e: string) => Promise<any>;
  disable?: boolean;
}

export function InputStand({
  label,
  defaultValue,
  onChange,
  disable = false,
}: iInputStand): JSX.Element {
  const [value, setValue] = React.useState<string>(defaultValue || '');
  const stoRef = React.useRef<any>(null);
  const firstEffect = React.useRef(true);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (firstEffect.current) {
      firstEffect.current = false;
      return;
    }
    clearTimeout(stoRef.current);
    stoRef.current = setTimeout(() => {
      setLoading(true);
      onChange(value).finally(() => setLoading(false));
    }, 500);
  }, [value]);

  return (
    <label className={s.InputStand}>
      {label}
      <div className={s.InputStand__group}>
        <input
          disabled={disable}
          className={s.InputStand__field}
          value={value}
          onChange={({ target }) => {
            setValue(target.value.toUpperCase());
          }}
        />
        {loading && (
          <div className={s.InputStand__loading}>
            <Svg />
          </div>
        )}
      </div>
    </label>
  );
}

InputStand.defaultProps = {
  iconBefore: undefined,
  iconAfter: undefined,
  disabled: undefined,
};
