import React, { useEffect, useState } from 'react';
import {
  useLocation,
  Outlet,
  Link,
  useParams,
  useNavigate,
} from 'react-router-dom';
import useApiContext from 'hooks/useApiContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useModalContext from 'hooks/useModalContext';
import { T, O, M, A } from 'TOMA';
import { InputText } from 'form';
import { eRoles } from 'interfaces/Roles';
import s from './FormInformation.module.scss';
import useRepo from '../../hooks/useRepo';
import routes from '../../router/routes';
import FormInformationQuestion from '../../components/FormInformation/FormInformationQuestion';
import { useTranslation } from 'react-i18next';
export interface iForm {
  id: number;
  answers: string[];
  question: string;
  required: boolean;
}

function FormInformation(): JSX.Element {
  const { eventRepository, userRepository } = useApiContext();
  const { pathname } = useLocation();
  const { eventId } = useParams();
  const { t } = useTranslation();
  const user = userRepository.getUser();
  const [data, setData] = useState<any>();
  const isAdmin =
    user?.role === eRoles.ADMIN || user?.role === eRoles.MODERATOR;

  const { response, loading } = useRepo(
    async () =>
      await eventRepository.getFormInformation(eventId).then((e) => e),
    [pathname]
  );
  useEffect(() => {
    if (eventId && !loading && response) {
      setData(response);
    }
  }, [loading, response]);
  const navigate = useNavigate();
  const newAnswer = {
    id: 0,
    answers: ['', '', ''],
    question: '',
    required: false,
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const createQuestion = () => {
    console.log(response);
    setData([...data, newAnswer]);
  };

  const mediaList: Array<
    'media_1' | 'media_2' | 'media_3' | 'media_4' | 'media_5'
  > = ['media_1', 'media_2', 'media_3', 'media_4', 'media_5'];
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onDelete = (id: number) => {
    console.log(id);
    const newData = data.filter((question: iForm) => question.id !== id);
    setData(newData);
  };
  return (
    <T.Container>
      <div className={pathname !== '/' && !isAdmin ? s.blur : undefined}>
        <M.Loading loading={loading} error={null} fullWiewport>
          <T.Block w="75" mx="auto" bg="white" borderRadius="20">
            {Array.isArray(data) ? (
              data.map((question: iForm) => (
                <FormInformationQuestion
                  key={question?.id}
                  id={question?.id}
                  question={question?.question}
                  answers={question?.answers}
                  required={question?.required}
                  eventId={eventId}
                  onDelete={onDelete}
                />
              ))
            ) : (
              <p>No questions available</p>
            )}
            <div className={s.new_question}>
              <button
                onClick={createQuestion}
                disabled={user?.role !== 'ADMIN'}
                className={s.new_question_button}
              >
                <A.Icon icon="plus-circle"></A.Icon>
                {t('home:formInformation.add_new_question')}
              </button>
            </div>
          </T.Block>
        </M.Loading>
      </div>
    </T.Container>
  );
}

export default FormInformation;
