import React, { useState, useEffect } from 'react';
import { T, O, M, A } from 'TOMA';
import s from './ExhibitorListHeader.module.scss';
import { Outlet, redirect, useLocation, useParams } from 'react-router-dom';
import routes from '../../router/routes';
import { CustomLink } from 'components/CustomLink';
import { useTranslation } from 'react-i18next';
import useApiContext from '../../hooks/useApiContext';
import useRepo from '../../hooks/useRepo';

export function ExhibitorListHeader(): JSX.Element {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState('event');
  const { eventId } = useParams();
  const [reload, setReload] = useState<number>(0);
  const { t } = useTranslation();
  const { codificationRepository, eventRepository, userRepository } =
    useApiContext();
  const { response, loading } = useRepo(
    async () =>
      eventId !== undefined
        ? await eventRepository.getEventById(eventId).then((e) => e)
        : '',
    [pathname]
  );
  const [name, setName] = useState<string>();
  const [date, setDate] = useState<string>();
  useEffect(() => {
    console.log(loading, 'loading', eventId, 'eventId', response, 'response');
    if (eventId && !loading && response) {
      setName(response.name);
      const dateStr = response.date;
      const [year, month, day] = dateStr.split('-');
      const formattedDate = `${day}/${month}/${year}`;
      setDate(formattedDate);
    }
  }, [loading, response]);
  return (
    <>
      <M.Loading loading={loading} fullWiewport>
        <T.Container py="20" mt="15">
          <div className={s.justifyBetwen}>
            <A.Text>{name}</A.Text>
            <A.Text>{date}</A.Text>
          </div>
        </T.Container>
        <T.Container py="50" bg="grey-10" mt="15">
          <T.BlockResponsive bg="grey-05" w="50" p="15">
            <A.Text textAlign="center">
              <button
                className={
                  pathname.includes('edit')
                    ? s.buttonExhibitorListHeader__active
                    : s.buttonExhibitorListHeader
                }
              >
                <CustomLink
                  color="brand-primary-dark"
                  to={routes.home2.path + `events/${eventId ?? ''}/edit`}
                >
                  <div className={s.iconExhibitorListHeader}>
                    <A.Icon fontSize="16" icon="calendar2-date" />
                  </div>
                  <A.Text>{t('eventList:events')}</A.Text>
                </CustomLink>
              </button>
              <button
                className={
                  pathname.includes('form')
                    ? s.buttonExhibitorListHeader__active
                    : s.buttonExhibitorListHeader
                }
              >
                <CustomLink
                  color="brand-primary-dark"
                  to={routes.home2.path + `events/${eventId ?? ''}/form`}
                >
                  <div className={s.iconExhibitorListHeader}>
                    <A.Icon fontSize="16" icon="folder" />
                  </div>
                  <A.Text>{t('eventList:question')}</A.Text>
                </CustomLink>
              </button>
              <button
                className={
                  !pathname.includes('form') && !pathname.includes('edit')
                    ? s.buttonExhibitorListHeader__active
                    : s.buttonExhibitorListHeader
                }
              >
                <CustomLink
                  color="brand-primary-dark"
                  to={routes.home2.path + `events/${eventId ?? ''}`}
                >
                  <div className={s.iconExhibitorListHeader}>
                    <A.Icon fontSize="16" icon="list-ul" />
                  </div>
                  <A.Text>{t('eventList:exhibitor')}</A.Text>
                </CustomLink>
              </button>
            </A.Text>
          </T.BlockResponsive>
        </T.Container>
        <T.Container py="50">
          <Outlet />
        </T.Container>
      </M.Loading>
    </>
  );
}
