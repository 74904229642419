import Client from '../Client.provider';
import { iCodificationRest } from './Codification.type';
import i18n from '../../i18n';

export interface iCodificationRepository {
  get: (codeId: string) => Promise<iCodificationRest[]>;
}

export class CodificationRepository
  extends Client
  implements iCodificationRepository
{
  public async get(codeId: string): Promise<iCodificationRest[]> {
    // Get the current active language
    const currentLanguage = i18n.language;
    return await this.restricted()
      .get<iCodificationRest[]>(`/codification/${codeId}`, {
        params: {
          locale: currentLanguage,
        },
      })
      .then((e) => {
        return e.data;
      })
      .then((e) =>
        e.sort((a, b) => {
          if (!a.label) return 1; // If a.label is null or undefined, move it down
          if (!b.label) return -1; // If b.label is null or undefined, move it down
          return a.label.localeCompare(b.label); // Otherwise, compare normally
        })
      )
      .catch((error) => {
        throw new Error(
          error.response?.data?.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
