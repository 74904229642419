export default {
  login: {
    title: 'Importer registration for Loire Wines Trade Tasting',
    signIn: 'Sign in',
    signUp: 'Sign up',
    forgotPassword: 'Forgot password ?',
    email: 'Email',
    password: 'Password',
  },
};
