import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useApiContext from 'hooks/useApiContext';
import Form, { InputPhone, InputText } from 'form';
import { T, O, M, A } from 'TOMA';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import useRepo from '../../hooks/useRepo';

export interface iFormSignUp {
  firstname: string;
  lastname: string;
  phone: string;
  raison_sociale: string;
  siret: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  event: any;
  collectif: any;
  individual: string;
  company: string;
}
function SignUp(): JSX.Element {
  const { userRepository, codificationRepository, companyRepository } =
    useApiContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<string | undefined>();
  const { t } = useTranslation();
  const { response: codifCollectif } = useRepo(
    async () => await codificationRepository.get('collectif')
  );
  const { response: Events } = useRepo(
    async () => await userRepository.getDataForRegistrationPage()
  );
  const schema = yup.object({
    firstname: yup.string().required(t('common:form.error.required')),
    lastname: yup.string().required(t('common:form.error.required')),
    raison_sociale: yup.string().required(t('common:form.error.required')),
    siret: yup.string().required(t('common:form.error.required')),
    event: yup
      .object({
        value: yup.string().required(t('common:form.error.required')),
        label: yup.string().required(t('common:form.error.required')),
      })
      .required(t('common:form.error.required')),
    password: yup
      .string()
      .pipe(
        yup
          .string()
          .min(
            8,
            `<b>${t('common:form.error.minCharacters', { length: 8 })}</b>`
          )
          .minLowercase(1, `<b>${t('common:form.error.oneLowerCase')}</b>`)
          .minUppercase(1, `<b>${t('common:form.error.oneUpperCase')}</b>`)
          .minNumbers(1, `<b>${t('common:form.error.oneNumber')}</b>`),
        t('common:form.error.passwordMustContain'),
        t('common:form.error.and')
      )
      .required(t('common:form.error.required')),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('common:form.error.passwordMustMatch')
      )
      .required(t('common:form.error.required')),
  });
  const [events, setEvents] = useState<any>([]);
  const collectif2 = codifCollectif?.map((e: any) => {
    return {
      value: e.code,
      label: e.label,
    };
  });
  const { register, setValue } = useForm<iFormSignUp>({
    resolver: yupResolver(schema),
  });
  function handleSubmit({
    firstname,
    lastname,
    phone,
    raison_sociale,
    siret,
    email,
    password,
    event,
    collectif,
  }: iFormSignUp): void {
    setLoading(true);
    let collectifNew = '';
    if (collectif) {
      collectifNew = collectif.value;
    } else {
      collectifNew = '';
    }
    userRepository
      .register(
        firstname,
        lastname,
        phone,
        raison_sociale.trim(),
        siret,
        email,
        password,
        event.value,
        collectifNew
      )
      .then((e) => {
        setSuccess(t('signup:form.success'));
      })
      .catch((err: Error) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  }
  const { response } = useRepo(
    // eslint-disable-next-line
      async () => await companyRepository.getCompanyRest(),
    []
  );
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const handleCheckboxChange = (value: any): any => {
    if (value === 'individual') {
      setValue('collectif', '');
    }
    setSelectedOption(value);
  };
  return (
    <T.Container py="50">
      <T.Block pb="50">
        <A.Title as="h1" fontSize="48" textAlign="center" fw="black">
          {response?.title}
        </A.Title>
      </T.Block>
      <T.Block pb="50">
        <A.Title as="h1" fontSize="32" textAlign="center" fw="black">
          {t('signup:subtitle')}
        </A.Title>
      </T.Block>
      <T.Block bg="grey-10" borderRadius="5" mw="medium" mx="auto" p="80">
        {!success && (
          <>
            <Form<iFormSignUp>
              onSubmit={async (data) => handleSubmit(data)}
              resolver={yupResolver(schema)}
            >
              {({ control }) => (
                <div>
                  <T.Block mb="40">
                    <Controller
                      name="event"
                      control={control}
                      render={({ field }) => (
                        <A.InputAutocomplete
                          {...field}
                          required={true}
                          label={t('signup:my_comp_exhibiting')}
                          placeholder={t('signup:selecting_event')}
                          name="event"
                          options={Events}
                          multiple={false}
                        />
                      )}
                    />
                    <Controller
                      name="individual"
                      control={control}
                      render={({ field }) => (
                        <A.InputCheckbox
                          label={t('signup:sur_un_stand_individual')}
                          {...field}
                          // @ts-expect-error
                          value="individual"
                          checked={selectedOption === 'individual'}
                          onChange={() => handleCheckboxChange('individual')}
                          name="individual"
                        />
                      )}
                    />
                    <Controller
                      name="company"
                      control={control}
                      render={({ field }) => (
                        <A.InputCheckbox
                          label={t('signup:sur_un_stand_collectif')}
                          {...field}
                          // @ts-expect-error
                          value="company"
                          checked={selectedOption === 'company'}
                          onChange={() => handleCheckboxChange('company')}
                          name="company"
                        />
                      )}
                    />
                    {selectedOption === 'company' ? (
                      <Controller
                        name="collectif"
                        control={control}
                        render={({ field }) => (
                          <A.InputAutocomplete
                            {...field}
                            label={t('admin:collectif')}
                            name="collectif"
                            placeholder={t('signup:selecting_collectif')}
                            options={collectif2}
                            multiple={false}
                          />
                        )}
                      />
                    ) : (
                      ''
                    )}
                    <A.Line mb="20" mt="20" />
                    <T.Block pb="30">
                      <A.Text fontSize="16">
                        <b>{t('signup:form.text.bold')}</b>
                        {t('signup:form.text.normal')}
                      </A.Text>
                    </T.Block>
                    <InputText
                      label={t('signup:form.labels.firstname')}
                      name="firstname"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label={t('signup:form.labels.lastname')}
                      name="lastname"
                      type="text"
                      control={control}
                      required
                    />
                    <InputPhone
                      label={t('signup:form.labels.phone')}
                      name="phone"
                      control={control}
                      placeholder="+33 1 23 45 67 88"
                    />
                    <InputText
                      label={t('signup:form.labels.companyName')}
                      name="raison_sociale"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label={t('signup:form.labels.siret')}
                      name="siret"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label={t('signup:form.labels.email')}
                      name="email"
                      type="text"
                      control={control}
                      required
                    />
                    <InputText
                      label={t('signup:form.labels.password')}
                      name="password"
                      type="password"
                      control={control}
                      required
                      autocomplete="new-password"
                    />
                    <InputText
                      label={t('signup:form.labels.passwordConfirmation')}
                      name="passwordConfirmation"
                      type="password"
                      control={control}
                      required
                      autocomplete="new-password"
                    />
                  </T.Block>

                  <A.Alert alert="error" side="⚠️" mb="30">
                    {error && <A.InnerHTML html={error} />}
                  </A.Alert>

                  <A.Button type="submit" loading={loading} center>
                    {t('signup:form.validate')}
                  </A.Button>
                </div>
              )}
            </Form>
            <A.Text fontSize="14">* {t('signup:form.asterix.required')}</A.Text>
            <A.Text fontSize="14">** {t('signup:form.asterix.info')}</A.Text>
          </>
        )}

        {success && (
          <>
            <A.Text fontSize="48" textAlign="center" mb="30">
              🎉
            </A.Text>
            <A.Text fontSize="18" textAlign="center" fw="bold">
              {success}
            </A.Text>
          </>
        )}
      </T.Block>
    </T.Container>
  );
}

export default SignUp;
