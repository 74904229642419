export default {
  users: {
    list_of_users: 'Liste des utilisateurs',
    role: 'Rôle',
    number_of_authorized_exhibitors: "Nombre d'exposants autorisés",
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    email: 'E-mail',
    create_a_new_moderator: 'Créer un nouveau modérateur',
    exhibitors: 'Exposants',
    collective: 'Collectif',
    events: 'Evènements',
    password: 'Mot de passe',
    phone: 'Téléphone',
  },
};
