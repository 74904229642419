// import { Product } from 'pages/Product';
import Client from '../Client.provider';
import {
  iExhibitorEventDetail,
  iExhibitorEventExt,
  iExhibitorEventProduct,
} from './ExhibitorsEvent.type';

export interface iExhibitorsEventRepository {
  getExhibitorEvents: () => Promise<iExhibitorEventExt[]>;
  getExhibitorEventProduct: (id: number) => Promise<iExhibitorEventProduct[]>;
  getExhibitorEventShowroomProduct: (
    id: number
  ) => Promise<iExhibitorEventProduct[]>;
  getExhibitorEvent: (id: number) => Promise<iExhibitorEventDetail>;
  getExhibitorEventAdmin: (id: number) => Promise<iExhibitorEventDetail>;
  mergeExhibitorEvent: (id: number, data: any) => Promise<boolean>;
  addExhibitorProductEvent: (
    id: number,
    productId: number,
    data?: any
  ) => Promise<boolean>;
  addExhibitorShowroomProductEvent: (
    id: number,
    productId: number,
    data?: any
  ) => Promise<boolean>;
  deleteExhibitorProductEvent: (
    id: number,
    productId: number
  ) => Promise<boolean>;
  deleteExhibitorShowroomProductEvent: (
    id: number,
    productId: number
  ) => Promise<boolean>;
}
const BASE_PATH = 'businessfrance'; // You can dynamically set this
export class ExhibitorEventsRepository
  extends Client
  implements iExhibitorsEventRepository
{
  public async getExhibitorEvents(): Promise<iExhibitorEventExt[]> {
    return await this.restricted()
      .get<iExhibitorEventExt[]>(`/user/event`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getExhibitorEvent(id: number): Promise<iExhibitorEventDetail> {
    return await this.restricted()
      .get<iExhibitorEventDetail>(`/exhibitorevent/${id}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getExhibitorEventAdmin(
    id: number
  ): Promise<iExhibitorEventDetail> {
    return await this.restricted()
      .get<iExhibitorEventDetail>(`/exhibitorevent-admin/${id}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getExhibitorEventProduct(
    id: number
  ): Promise<iExhibitorEventProduct[]> {
    return await this.restricted()
      .get<iExhibitorEventProduct[]>(`/exhibitorevent/product/${id}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getExhibitorEventShowroomProduct(
    id: number
  ): Promise<iExhibitorEventProduct[]> {
    return await this.restricted()
      .get<iExhibitorEventProduct[]>(`/exhibitorevent/showroom-product/${id}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async mergeExhibitorEvent(id: number, data: any): Promise<boolean> {
    return await this.restricted()
      .put<boolean>(`/${BASE_PATH}/exhibitorevent/merge/${id}`, data)
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async addExhibitorProductEvent(
    id: number,
    productId: number,
    data?: any
  ): Promise<boolean> {
    return await this.restricted()
      .put<boolean>(`/exhibitorevent/product/${id}/add/${productId}`, data)
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async addExhibitorShowroomProductEvent(
    id: number,
    productId: number,
    data?: any
  ): Promise<boolean> {
    return await this.restricted()
      .put<boolean>(
        `/exhibitorevent/showroom-product/${id}/add/${productId}`,
        data
      )
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async deleteExhibitorProductEvent(
    id: number,
    productId: number
  ): Promise<boolean> {
    return await this.restricted()
      .delete<boolean>(`/exhibitorevent/product/${id}/delete/${productId}`)
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async deleteExhibitorShowroomProductEvent(
    id: number,
    productId: number
  ): Promise<boolean> {
    return await this.restricted()
      .delete<boolean>(
        `/exhibitorevent/showroom-product/${id}/delete/${productId}`
      )
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
