// import { Product } from 'pages/Product';
import Client from '../Client.provider';
import { iEventRest, iFormAnswers, iFormQuestion, iInvite } from './Event.type';

export interface iEventRepository {
  getEvents: () => Promise<iEventRest[]>;
  getEventById: (eventId: string) => Promise<iEventRest>;
  // getEvent: (idEvent: string) => Promise<iEventRest>;
  mergeEvent: (type: any, value: any, eventId: number) => Promise<boolean>;
  createEvent: (data: any) => Promise<boolean>;
  acceptInvite: (data: any) => Promise<boolean>;
  updateFormInformation: (data: any, eventId: string) => Promise<boolean>;
  getFormInformation: (eventId: string | undefined) => Promise<iFormQuestion[]>;
  remove: (eventId: string) => Promise<boolean>;
  updateFormAnswers: (
    questionId: number,
    data: iFormAnswers
  ) => Promise<iFormQuestion>;
  inviteEvent: (eventId: string | undefined, form: iInvite) => Promise<any>;
  removeQuestion: (questionId: number) => Promise<boolean>;
  inviteExhibitor: (eventId: string | undefined, data: any) => Promise<any>;
}
const BASE_PATH = 'businessfrance';
export class EventRepository extends Client implements iEventRepository {
  public async getEvents(): Promise<iEventRest[]> {
    return await this.restricted()
      .get<iEventRest[]>(`/event`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getFormInformation(
    eventId: string | undefined
  ): Promise<iFormQuestion[]> {
    return await this.restricted()
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      .get<iFormQuestion[]>(`/event/iform/${eventId}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async acceptInvite(code: string): Promise<boolean> {
    return await this.restricted()
      .get(`/accept/${code}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async inviteEvent(
    eventId: string | undefined,
    form: iInvite
  ): Promise<any> {
    return await this.restricted()
      .post(`/${BASE_PATH}/invite/${eventId !== undefined ? eventId : 0}`, form)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async inviteExhibitor(
    eventId: string | undefined,
    data: any
  ): Promise<any> {
    return await this.restricted()
      .post(
        `/${BASE_PATH}/invite-exhibitor/${eventId !== undefined ? eventId : 0}`,
        data
      )
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getEventById(eventId: string): Promise<iEventRest> {
    return await this.restricted()
      .get<iEventRest>(`/event/iform-information/${eventId}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async mergeEvent(
    type: string,
    value: any,
    eventId: number
  ): Promise<boolean> {
    return await this.restricted()
      .put<iEventRest>(`/${BASE_PATH}/event/merge/${eventId}`, {
        [type]: value,
      })
      .then((e) => !e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async createEvent(data: any): Promise<boolean> {
    return await this.restricted()
      .put<iEventRest>(`/event`, data)
      .then((e) => !e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async updateFormInformation(
    data: any,
    eventId: string
  ): Promise<boolean> {
    return await this.restricted()
      .put<iFormQuestion>(`/event/iform/${eventId}`, data)
      .then((e) => !e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async updateFormAnswers(
    questionId: number,
    data: iFormAnswers
  ): Promise<iFormQuestion> {
    return await this.restricted()
      .put<iFormQuestion>(`/event/iform-update-questions/${questionId}`, data)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async remove(eventId: string): Promise<boolean> {
    return await this.restricted()
      .delete(`/event/${eventId}`)
      .then((e) => !e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async removeQuestion(questionId: number): Promise<boolean> {
    return await this.restricted()
      .delete(`/event/iform-update-questions/${questionId}`)
      .then((e) => !e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
