import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useApiContext from 'hooks/useApiContext';
import useModalContext from 'hooks/useModalContext';
import useRepo from 'hooks/useRepo';
import { T, O, M, A } from 'TOMA';
import {
  InputText,
  InputSelect,
  InputTextarea,
  InputMedias,
  InputAutocomplete,
  InputFile,
} from 'form';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  eClassification,
  eTypeProduit,
} from 'services/Codification/Codification.type';
import routes from 'router/routes';
import { CustomLink } from 'components/CustomLink';
import { eRoles } from 'interfaces/Roles';
import { useTranslation } from 'react-i18next';

interface iProductForm {
  removed: number;
  producer: string;
  name: string;
  description_fr: string;
  description_en: string;
  photo_1: string;

  type_produit: string;
  type_vin: string;
  couleur: string;
  region: string;
  classification: string;
  appellation: string;
  denomination_igp: string;
  // denomination: string;
  type_culture: string;
  millesime: string;
  cepage: string[];
  // format: string;
  price: string;
  alcool: string;

  type_vendange: string;
  allergene: string;
  arome: string;

  technical_file: string;
}

function Exhibitor(): JSX.Element {
  const { t } = useTranslation();
  const { setModalContent, setModalVisible } = useModalContext();
  const navigate = useNavigate();
  const { exhibitorId, productId } = useParams();
  const {
    userRepository,
    exhibitorRepository,
    mediaRepository,
    productRepository,
    codificationRepository,
  } = useApiContext();
  const user = userRepository.getUser();
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [errorForm, setErrorForm] = useState<string>();

  const {
    response,
    loading: loadingExhib,
    error: errorExhib,
  } = useRepo(
    async () => await exhibitorRepository.getUserExhibitor(exhibitorId),
    [exhibitorId, productId]
  );

  const {
    response: { product } = { product: null },
    loading: loadingProduct,
    error: errorProduct,
  } = useRepo(
    async () => await productRepository.getProduct(productId),
    [productId]
  );

  const isAdmin = user?.role === eRoles.ADMIN;

  const { response: codifTypeProduit } = useRepo(
    async () => await codificationRepository.get('type_produit')
  );
  const { response: codifTypeVin } = useRepo(
    async () => await codificationRepository.get('type_vin')
  );
  const { response: codifCouleur } = useRepo(
    async () => await codificationRepository.get('couleur')
  );
  const { response: codifTypeCulture } = useRepo(
    async () => await codificationRepository.get('type_culture')
  );
  const { response: codifRegion } = useRepo(
    async () => await codificationRepository.get('region')
  );
  const { response: codifClassification } = useRepo(
    async () => await codificationRepository.get('classification')
  );
  const { response: codifAppellation } = useRepo(
    async () => await codificationRepository.get('appellation')
  );
  const { response: codifDenominationIgp } = useRepo(
    async () => await codificationRepository.get('denomination_igp')
  );
  const { response: codifMillesime } = useRepo(
    async () => await codificationRepository.get('millesime')
  );
  const { response: codifCepage } = useRepo(
    async () => await codificationRepository.get('cepage')
  );

  async function onSubmit(form: iProductForm): Promise<void> {
    setLoadingForm(true);

    if (productId) {
      return await productRepository
        .updateProduct(
          { ...form, name: form.name.trim(), cepage: form.cepage.join(';') },
          response?.exhibitor.id,
          productId
        )
        .then((e) => {
          setSuccessForm(true);
          setErrorForm('');
        })
        .catch((err: Error) => {
          console.log(err);
          setErrorForm(err.message);
        })
        .finally(() => setLoadingForm(false));
    } else {
      productRepository
        .newProduct(
          { ...form, name: form.name.trim(), cepage: form.cepage.join(';') },
          response?.exhibitor.id
        )
        .then((e) => {
          if (e?.product?.id) {
            setSuccessForm(true);
            setErrorForm('');
            if (isAdmin) {
              navigate(
                routes.productIdAdmin.to({
                  exhibitorId: response?.exhibitor.id,
                  productId: e?.product?.id,
                }),
                {
                  replace: true,
                }
              );
            } else {
              navigate(routes.productId.to({ productId: e?.product?.id }), {
                replace: true,
              });
            }
          } else {
            throw new Error('Unknown error');
          }
        })
        .catch((err: Error) => {
          console.log(err);
          setErrorForm(err.message);
        })
        .finally(() => setLoadingForm(false));
    }
  }

  const schema = yup.object({
    producer: yup.string().max(255).required(t('common:form.error.required')),
    name: yup.string().max(255).required(t('common:form.error.required')),
    description_en: yup
      .string()
      .max(500)
      .required(t('common:form.error.required')),
    photo_1: yup.string().required(t('common:form.error.required')),

    type_produit: yup.string().required(t('common:form.error.required')),
    type_vin: yup.string().when('type_produit', {
      is: eTypeProduit.WINE,
      then: yup.string().required(t('common:form.error.required')),
      otherwise: yup.string().nullable(),
    }),
    couleur: yup.string().when('type_produit', {
      is: (e: eTypeProduit) => [eTypeProduit.WINE].includes(e),
      then: yup.string().required(t('common:form.error.required')),
      otherwise: yup.string().nullable(),
    }),
    region: yup.string().required(t('common:form.error.required')),
    classification: yup.string().required(t('common:form.error.required')),
    appellation: yup.string().when(['type_produit', 'classification'], {
      is: (tp: eTypeProduit, c: eClassification) =>
        [eTypeProduit.WINE].includes(tp) && eClassification.AOC_AOP_PDO === c,
      then: yup.string().required(t('common:form.error.required')).nullable(),
      otherwise: yup.string().nullable(),
    }),
    denomination_igp: yup.string().when(['type_produit', 'classification'], {
      is: (tp: eTypeProduit, c: eClassification) =>
        [eTypeProduit.WINE].includes(tp) && eClassification.IGP_PGI === c,
      then: yup.string().required(t('common:form.error.required')).nullable(),
      otherwise: yup.string().nullable(),
    }),
    type_culture: yup.string().when('type_produit', {
      is: (tp: eTypeProduit) => [eTypeProduit.WINE].includes(tp),
      then: yup.string().required(t('common:form.error.required')),
      otherwise: yup.string().nullable(),
    }),
    millesime: yup.string().when('type_produit', {
      is: (tp: eTypeProduit) => [eTypeProduit.WINE].includes(tp),
      then: yup.string().required(t('common:form.error.required')),
      otherwise: yup.string().nullable(),
    }),
    cepage: yup
      .array()
      .of(yup.string())
      .when('type_produit', {
        is: (tp: eTypeProduit) => [eTypeProduit.WINE].includes(tp),
        then: yup
          .array()
          .required()
          .min(1, t('common:form.error.required'))
          .required(t('common:form.error.required')),
        otherwise: yup.array().nullable(),
      }),
    price: yup.string().required(t('common:form.error.required')),

    type_vendange: yup.string().max(255).nullable(),
    allergene: yup.string().max(255).nullable(),
    arome: yup.string().max(255).nullable(),
    // technical_file: yup
    //   .string()
    //   .required(t('common:form.error.required'))
    //   .nullable(),
  });

  const disabled = !isAdmin && !!response?.exhibitor.locked;

  const form = useForm<iProductForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    getValues,
    formState,
  } = form;
  useEffect(() => {
    if (!product)
      reset({
        name: '',
        region: 'region:VALLEE_DE_LA_LOIRE',
        cepage: [],
      });
    else reset({ ...product, cepage: product?.cepage?.split(';') || [] });
  }, [product]);

  const typeProduit = watch('type_produit');
  const classification = watch('classification');

  useEffect(() => {
    if (!product) return;
    if (typeProduit === eTypeProduit.WINE) {
      const appellation = getValues('appellation');
      if (
        appellation &&
        codifAppellation &&
        !codifAppellation?.some(({ topicCode }) => topicCode === appellation)
      ) {
        setValue('appellation', '');
      }
    }
  }, [typeProduit]);

  useEffect(() => {
    if (!product) return;
    if (classification === eClassification.AOC_AOP_PDO) {
      setValue('denomination_igp', '');
    }
    if (classification === eClassification.IGP_PGI) {
      setValue('appellation', '');
    }
  }, [classification]);

  const indexProduct = response?.products?.findIndex(
    (e) => e.id === product?.id
  );

  function resetForm(): void {
    reset({ ...product, cepage: product?.cepage?.split(';') ?? [] });
  }

  async function downloadPdf(): Promise<void> {
    setLoadingPdf(true);
    try {
      const blob: Blob = await productRepository.downloadPdf(productId);
      const url: string = window.URL.createObjectURL(new Blob([blob]));
      const link: HTMLAnchorElement = document.createElement('a');
      const fileName: string = `product-${String(productId)}.pdf`;
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error('Failed to download PDF', error);
    }
    setLoadingPdf(false);
  }

  return (
    <T.Container py="20">
      <M.Loading
        loading={loadingExhib || loadingProduct || loadingPdf}
        error={errorExhib ?? errorProduct}
        fullWiewport
      >
        <M.Breadcrumb
          homeUrl={routes.home.path}
          breadcrumb={[
            {
              label: response?.exhibitor.raison_sociale,
              path: isAdmin
                ? routes.exhibitor.to({
                    exhibitorId: response?.exhibitor.id ?? '',
                  })
                : routes.home.path,
            },
            {
              label:
                indexProduct !== undefined && indexProduct >= 0
                  ? `${t('product:product')} ${indexProduct + 1}`
                  : t('product:newProduct'),
              path: '/undefined',
            },
          ]}
        />
        <T.Block pb="50" mw="large" m="auto">
          <A.Title as="h1" fontSize="48" mb="30" fw="black">
            {t('product:title')}{' '}
            {indexProduct !== undefined && indexProduct >= 0
              ? `N° ${indexProduct + 1}`
              : null}
          </A.Title>

          {productId && (
            <T.Flex
              mw="medium"
              m="auto"
              bg="grey-05"
              p="20"
              borderRadius="7"
              mb="20"
              valign="center"
              halign={'space-between'}
              gap="10"
            >
              <T.Flex
                flexGrow="1"
                flexBasis="auto"
                valign="center"
                halign={'stretch'}
              >
                <A.Text fw="black" mr="15">
                  Autre
                  {!!response?.products?.length &&
                    response?.products?.length > 1 &&
                    's'}{' '}
                  produit
                  {!!response?.products?.length &&
                    response?.products?.length > 1 &&
                    's'}
                </A.Text>
                <T.Block mb="-15" w="100">
                  <A.InputSelect
                    name="other_products"
                    placeholder={t('common:form.select')}
                    value={product?.id}
                    options={response?.products?.map(({ id }, index) => ({
                      label: `${t('product:product')} ${index + 1}`,
                      value: id,
                      disabled: id === product?.id || false,
                    }))}
                    onChange={({ target }) => {
                      if (!target.value) return;

                      if (isAdmin) {
                        navigate(
                          routes.productIdAdmin.to({
                            exhibitorId: response?.exhibitor.id,
                            productId: target.value,
                          }),
                          {
                            replace: true,
                          }
                        );
                      } else {
                        navigate(
                          routes.productId.to({ productId: target.value }),
                          {
                            replace: true,
                          }
                        );
                      }
                    }}
                  />
                </T.Block>
              </T.Flex>
              {productId &&
                response?.products &&
                response.products.length < 10 && (
                  <CustomLink
                    to={
                      isAdmin
                        ? routes.productAdmin.to({
                            exhibitorId: response.exhibitor.id,
                          })
                        : routes.product.to()
                    }
                    textDecoration="none"
                    color="black"
                  >
                    <A.Icon icon="plus-circle" mr="5" />
                    {t('product:addProduct')}
                  </CustomLink>
                )}
            </T.Flex>
          )}
          <T.Block
            pb="50"
            mw="medium"
            m="auto"
            bg="grey-05"
            p="20"
            borderRadius="7"
          >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <T.Block>
                <T.BlockResponsive mb="50">
                  <InputText
                    label={t('product:form.producer')}
                    name="producer"
                    type="text"
                    control={control}
                    required
                    disabled={disabled}
                  />

                  <InputText
                    label={t('product:form.productName')}
                    name="name"
                    type="text"
                    control={control}
                    required
                    disabled={disabled}
                  />

                  <InputTextarea
                    label={t('product:form.productDescription')}
                    name="description_en"
                    control={control}
                    disabled={disabled}
                    rows={5}
                    counter={500}
                    required
                  ></InputTextarea>

                  <T.Flex gap={1}>
                    <InputMedias
                      label={t('product:form.productVisual')}
                      information={t('product:form.productVisualInfo')}
                      name="photo_1"
                      control={control}
                      onInit={async (mediaId) => {
                        return await mediaRepository
                          .get(mediaId)
                          .then(({ id, w640, filename }) => ({
                            id,
                            src: w640,
                            filename,
                          }));
                      }}
                      onChange={async (file) => {
                        const formdata = new FormData();
                        formdata.append('media', file);
                        return await mediaRepository
                          .upload(formdata)
                          .then(({ id, w640, filename }) => ({
                            id,
                            src: w640,
                            filename,
                          }));
                      }}
                      onRemove={async (mediaId) => {
                        return await mediaRepository.remove('photo_1', mediaId);
                      }}
                      onZoom={(src) => {
                        setModalContent(
                          <img src={src} style={{ maxWidth: '100%' }} />
                        );
                      }}
                      disabled={disabled}
                      required
                    />
                  </T.Flex>
                </T.BlockResponsive>

                <A.Title as="h2" fontSize="24" mb="20" fw="black">
                  {t('product:form.productInfo.title')}
                  <A.Line mt="20" />
                </A.Title>

                <T.BlockResponsive mb="50">
                  <InputSelect
                    label={t('product:form.productInfo.productType')}
                    name="type_produit"
                    control={control}
                    required
                    disabled={disabled}
                    options={codifTypeProduit?.map(({ topicCode, label }) => ({
                      value: topicCode,
                      label,
                    }))}
                  />

                  <InputSelect
                    label={t('product:form.productInfo.vineType')}
                    name="type_vin"
                    control={control}
                    required
                    disabled={disabled || typeProduit !== eTypeProduit.WINE}
                    options={codifTypeVin?.map(({ topicCode, label }) => ({
                      value: topicCode,
                      label,
                    }))}
                  />

                  <InputSelect
                    label={t('product:form.productInfo.color')}
                    name="couleur"
                    control={control}
                    disabled={
                      disabled ||
                      ![eTypeProduit.WINE].includes(typeProduit as eTypeProduit)
                    }
                    required
                    options={codifCouleur
                      ?.filter(({ filter }) => {
                        if (typeProduit === eTypeProduit.WINE) {
                          return filter?.split(';').includes(eTypeProduit.WINE);
                        }
                        return true;
                      })
                      ?.map(({ topicCode, label }) => ({
                        value: topicCode,
                        label,
                      }))}
                  />

                  <InputSelect
                    label={t('product:form.productInfo.region')}
                    name="region"
                    control={control}
                    disabled={disabled}
                    required
                    options={codifRegion?.map(({ topicCode, label }) => ({
                      value: topicCode,
                      label,
                    }))}
                  />

                  <InputSelect
                    label={t('product:form.productInfo.classification')}
                    name="classification"
                    control={control}
                    disabled={disabled}
                    required
                    options={codifClassification?.map(
                      ({ topicCode, label }) => ({
                        value: topicCode,
                        label,
                      })
                    )}
                  />

                  {[eClassification.AOC_AOP_PDO].includes(
                    classification as eClassification
                  ) && (
                    <InputSelect
                      label={t('product:form.productInfo.appellation')}
                      name="appellation"
                      control={control}
                      disabled={disabled}
                      required
                      options={codifAppellation
                        ?.filter(({ filter }) => {
                          if (typeProduit === eTypeProduit.WINE && !filter) {
                            return true;
                          }
                          return filter === typeProduit;
                        })
                        ?.map(({ topicCode, label }) => ({
                          value: topicCode,
                          label,
                        }))}
                    />
                  )}

                  {[eClassification.IGP_PGI].includes(
                    classification as eClassification
                  ) && (
                    <InputSelect
                      label={t('product:form.productInfo.denominationIgp')}
                      name="denomination_igp"
                      control={control}
                      disabled={disabled}
                      required
                      options={codifDenominationIgp?.map(
                        ({ topicCode, label }) => ({
                          value: topicCode,
                          label,
                        })
                      )}
                    />
                  )}

                  <InputSelect
                    label={t('product:form.productInfo.typeCulture')}
                    name="type_culture"
                    control={control}
                    disabled={disabled || eTypeProduit.WINE !== typeProduit}
                    required
                    options={codifTypeCulture?.map(({ topicCode, label }) => ({
                      value: topicCode,
                      label,
                    }))}
                  />

                  <InputSelect
                    label={t('product:form.productInfo.millesime')}
                    name="millesime"
                    control={control}
                    disabled={disabled || eTypeProduit.WINE !== typeProduit}
                    required
                    options={codifMillesime?.map(({ topicCode, label }) => ({
                      value: topicCode,
                      label,
                    }))}
                  />

                  <InputAutocomplete
                    label={t('product:form.productInfo.cepage')}
                    name="cepage"
                    control={control}
                    disabled={disabled || eTypeProduit.WINE !== typeProduit}
                    options={codifCepage?.map(({ topicCode, label }) => ({
                      value: topicCode,
                      label,
                    }))}
                    required
                  />

                  {/* <InputSelect
                    label={t('product:form.productInfo.format')}
                    name="format"
                    control={control}
                    disabled={disabled}
                    required
                    options={codifFormat
                      ?.filter(({ filter }) => {
                        if (typeProduit === eTypeProduit.BEER) {
                          return filter?.split(';').includes(eTypeProduit.BEER);
                        }
                        if (typeProduit === eTypeProduit.CIDER) {
                          return filter
                            ?.split(';')
                            .includes(eTypeProduit.CIDER);
                        }
                        if (typeProduit === eTypeProduit.SPIRITS) {
                          return filter
                            ?.split(';')
                            .includes(eTypeProduit.SPIRITS);
                        }
                        return true;
                      })
                      ?.map(({ topicCode, label }) => ({
                        value: topicCode,
                        label,
                      }))}
                  /> */}
                  {/* <InputSelect
                    label={t('product:form.productInfo.price')}
                    name="price"
                    control={control}
                    required
                    disabled={disabled}
                    options={codifPrices?.map(({ topicCode, label }) => ({
                      value: topicCode,
                      label,
                    }))}
                  /> */}

                  <InputText
                    label={t('product:form.productInfo.price')}
                    name="price"
                    type="number"
                    control={control}
                    disabled={disabled}
                    required
                  />

                  <InputText
                    label={t('product:form.productInfo.alcool')}
                    name="alcool"
                    type="number"
                    control={control}
                    disabled={disabled}
                  />

                  <InputText
                    label={t('product:form.productInfo.typeVendange')}
                    name="type_vendange"
                    type="text"
                    control={control}
                    disabled={disabled || eTypeProduit.WINE !== typeProduit}
                  />

                  <InputText
                    label={t('product:form.productInfo.allergene')}
                    name="allergene"
                    type="text"
                    control={control}
                    disabled={disabled}
                  />

                  <InputText
                    label={t('product:form.productInfo.arome')}
                    name="arome"
                    type="text"
                    control={control}
                    disabled={disabled}
                  />

                  <InputFile
                    label={t('product:form.productInfo.technicalFile')}
                    download={true}
                    // information={t('home:form.companyInfo.HdLogoFormat')}
                    name="technical_file"
                    control={control}
                    onInit={async (mediaId) => {
                      return await mediaRepository
                        .get(mediaId)
                        .then(({ filename, file }) => ({
                          filename,
                          src: file,
                        }));
                    }}
                    onChange={async (file) => {
                      const formdata = new FormData();
                      formdata.append('file', file);
                      return await mediaRepository
                        .uploadFile(formdata)
                        .then(({ id, src }) => ({
                          mediaId: id,
                          src,
                        }));
                    }}
                    onRemove={async (mediaId) => {
                      return await mediaRepository
                        .remove('logo', mediaId)
                        .then((e) => console.log(e));
                    }}
                    disabled={disabled}
                    accept="application/pdf"
                    maxFileSize={10}
                  />
                </T.BlockResponsive>

                {/* Alert Success */}
                <A.Alert alert="success" side="🎉" mb="15">
                  {successForm && t('product:form.success')}
                </A.Alert>

                {/* Alert fail */}
                <A.Alert alert="error" side="⚠️" mb="15">
                  {errorForm && <A.InnerHTML html={errorForm} />}
                </A.Alert>

                {/* Button */}
                {!disabled && (
                  <T.Flex gap={2} wrap>
                    <div>
                      <A.Button type="submit" loading={loadingForm}>
                        {t('product:form.save')}
                      </A.Button>
                    </div>
                    {productId && (
                      <>
                        <div>
                          <A.Button type="button" onClick={resetForm}>
                            {t('product:form.cancel')}
                          </A.Button>
                        </div>
                        <div>
                          <A.Button
                            type="button"
                            onClick={() => {
                              setModalContent(
                                <div>
                                  <A.Text fontSize="22" fw="bold" mb="20">
                                    {t('product:form.confirmRemoveProduct')}
                                  </A.Text>
                                  <T.Flex gap={2} valign="center" mb="-15">
                                    <A.Button
                                      bg="alert"
                                      onClick={() => {
                                        setModalVisible(false);
                                        onSubmit({
                                          ...getValues(),
                                          removed: 1,
                                        }).then(() => {
                                          navigate(routes.home.path, {
                                            replace: true,
                                          });
                                        });
                                      }}
                                    >
                                      {t('product:form.confirm')}
                                    </A.Button>
                                    <A.Button
                                      appearance="link"
                                      color="grey-80"
                                      mb="15"
                                      textDecoration="underline"
                                      onClick={() => setModalVisible(false)}
                                    >
                                      {t('product:form.cancel')}
                                    </A.Button>
                                  </T.Flex>
                                </div>
                              );
                            }}
                            bg="alert"
                            loading={loadingForm}
                          >
                            {t('product:form.remove')}
                          </A.Button>
                        </div>
                        <div>
                          <A.Button type="button" onClick={downloadPdf}>
                            {t('Download PDF')}
                          </A.Button>
                        </div>
                      </>
                    )}
                  </T.Flex>
                )}
              </T.Block>
              {/* <div>{JSON.stringify(formState.errors)}</div> */}
            </form>
          </T.Block>
        </T.Block>
      </M.Loading>
    </T.Container>
  );
}

export default Exhibitor;
