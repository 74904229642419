import React, { useState, useMemo, useRef, useEffect } from 'react';
import useRepo from '../../hooks/useRepo';
import useApiContext from 'hooks/useApiContext';
import { useLocation, Outlet, Link, useParams } from 'react-router-dom';
import { T, O, M, A } from 'TOMA';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputAutocomplete, InputText } from '../../form';
import s from '../Event/Event.module.scss';
import { eTypeProduit } from '../../services/Codification/Codification.type';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
function UserFormPage(): JSX.Element {
  const { eventRepository, userRepository, codificationRepository } =
    useApiContext();
  const { userId } = useParams();
  const { pathname } = useLocation();
  const [loadingForm, setLoadingForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const user = userRepository.getUser();
  const [errorForm, setErrorForm] = useState<string>();
  const [optionsAvailable, setOptions] = useState<any>();
  const { t } = useTranslation();
  const { response, loading } = useRepo(async () => {
    return await userRepository
      .getUserById(userId ?? 'new_user')
      .then((e) => e);
  }, [pathname]);
  const data = useRepo(async () => {
    return await userRepository.getOptions().then((e) => e);
  }, [pathname]);
  const { response: collectif } = useRepo(
    async () => await codificationRepository.get('collectif')
  );
  console.log(collectif, 'collectif');
  useEffect(() => {
    if (!data.loading && data.response) {
      setOptions(data.response);
    }
  }, [data.loading, data.response]);
  const schema = yup.object({
    count_of_moderators: yup.number().nullable(),
    count_of_exhibitors: yup.number().nullable(),
    firstname: yup
      .string()
      .max(255)
      .required('This field is required')
      .nullable(),
    lastname: yup
      .string()
      .max(255)
      .required('This field is required')
      .nullable(),
    email: yup.string().max(255).required('This field is required').nullable(),
  });
  const form = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const [moderators, setModerators] = useState<any>([]);
  const [exhibitors, setExhibitors] = useState<any>([]);
  const [admins, setAdmins] = useState<any>([]);
  const [role, setRole] = useState<any>();
  const { handleSubmit, watch, control, reset, setValue, getValues } = form;
  watch(() => {
    setRole(getValues().role);
  }, [getValues().role]);
  const [events, setEvents] = useState<any>([]);
  useEffect(() => {
    if (userId && !loading && response) {
      setRole(response.user.role);
      reset(response.user);
    }
    if (!loading && response) {
      console.log(response);
      setExhibitors([...response.exhibitors]);
      setModerators([...response.moderators]);
      setAdmins([...response.admins]);
      setEvents([...response.events]);
      console.log(exhibitors, moderators, admins);
      if (user?.role === 'ADMIN') {
        setValue('role', 'MODERATOR');
      }
    }
  }, [loading, response, form.reset]);
  const options = (): any => {
    const available_options = [];
    if (user?.role === 'ADMIN') {
      if (optionsAvailable?.moderators > 0) {
        available_options.push({ value: 'MODERATOR', label: 'MODERATOR' });
      }
    } else {
      available_options.push(
        { value: 'ADMIN', label: 'Admin' },
        { value: 'EXHIBITOR', label: 'EXHIBITOR' },
        { value: 'MODERATOR', label: 'MODERATOR' },
        { value: 'SUPER_ADMIN', label: 'SUPER_ADMIN' }
      );
    }
    console.log(available_options, 'available_options');
    return available_options;
  };
  const { response: resEvents, loading: resLoading } = useRepo(
    async () => await userRepository.getCompany().then((e) => e),
    [pathname]
  );
  useEffect(() => {
    if (!resLoading && resEvents) {
      setEvents(resEvents.events);
    }
  }, [resLoading, resLoading]);
  function onSubmit(data: any): void {
    console.log(data);
    if (userId) {
      userRepository
        .updateUserFeature(data.id, data)
        .then(() => {
          setSuccessForm(true);
          setErrorForm('');
        })
        .catch((err: Error) => {
          setErrorForm(err.message);
        })
        .finally(() => {
          setLoadingForm(false);
        });
    } else {
      userRepository
        .createUserFeature(data)
        .then(() => {
          setSuccessForm(true);
          setErrorForm('');
        })
        .catch((err: Error) => {
          setErrorForm(err.message);
        })
        .finally(() => {
          setLoadingForm(false);
        });
    }

    console.log(data);
  }
  return (
    <M.Loading loading={false} error={null} fullWiewport>
      <T.Block p="20">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <A.InputSelect
                {...field}
                label={t('users:role')}
                options={options()}
              />
            )}
          />
          <InputText
            label={t('users:email')}
            name="email"
            type="text"
            control={control}
            disabled={false}
            required
          />
          <InputText
            label={t('users:firstname')}
            name="firstname"
            type="text"
            control={control}
            disabled={false}
            required
          />
          <InputText
            label={t('users:lastname')}
            name="lastname"
            type="text"
            control={control}
            disabled={false}
            required
          />
          <InputText
            label={t('users:phone')}
            name="phone"
            type="tel"
            control={control}
            disabled={false}
            required
          />
          {user?.role === 'SUPER_ADMIN' && role === 'EXHIBITOR' ? (
            <>
              <Controller
                name="admins"
                control={control}
                render={({ field }) => (
                  <A.InputAutocomplete
                    {...field}
                    label="Admins"
                    name="admins"
                    options={admins}
                    multiple={true}
                  />
                )}
              />
              <Controller
                name="moderators"
                control={control}
                render={({ field }) => (
                  <A.InputAutocomplete
                    {...field}
                    label="Moderators"
                    name="moderators"
                    options={moderators}
                    multiple={true}
                  />
                )}
              />
            </>
          ) : (
            ''
          )}
          {user?.role === 'ADMIN' && role !== 'EXHIBITOR' ? (
            <Controller
              name="exhibitors"
              control={control}
              render={({ field }) => (
                <A.InputAutocomplete
                  {...field}
                  label={t('users:exhibitors')}
                  name="exhibitors"
                  options={exhibitors}
                  multiple={true}
                />
              )}
            />
          ) : (
            ''
          )}
          {role === 'MODERATOR' ? (
            <Controller
              name="collectif"
              control={control}
              render={({ field }) => (
                <A.InputAutocomplete
                  {...field}
                  label={t('users:collective')}
                  name="collectif"
                  options={[
                    { value: '', label: '-' },
                    // @ts-expect-error
                    ...collectif?.map((item) => {
                      return {
                        value: item.code,
                        label: item.label,
                      };
                    }),
                  ]}
                />
              )}
            />
          ) : (
            ''
          )}
          {role === 'MODERATOR' ? (
            <Controller
              name="events"
              control={control}
              render={({ field }) => (
                <A.InputAutocomplete
                  {...field}
                  label={t('users:events')}
                  name="events"
                  options={events}
                  multiple={true}
                />
              )}
            />
          ) : (
            ''
          )}
          {role === 'MODERATOR' && user?.role !== 'ADMIN' ? (
            <div>
              <Controller
                name="exhibitors"
                control={control}
                render={({ field }) => (
                  <A.InputAutocomplete
                    {...field}
                    label={t('users:exhibitors')}
                    name="exhibitors"
                    options={exhibitors}
                    multiple={true}
                  />
                )}
              />
              <Controller
                name="admins"
                control={control}
                render={({ field }) => (
                  <A.InputAutocomplete
                    {...field}
                    label="Admins"
                    name="admins"
                    options={admins}
                    multiple={true}
                  />
                )}
              />
            </div>
          ) : (
            ''
          )}
          {role === 'ADMIN' ? (
            <div>
              <Controller
                name="moderators"
                control={control}
                render={({ field }) => (
                  <A.InputAutocomplete
                    {...field}
                    label="Moderators"
                    name="moderators"
                    options={moderators}
                    multiple={true}
                  />
                )}
              />
              <Controller
                name="exhibitors"
                control={control}
                render={({ field }) => (
                  <A.InputAutocomplete
                    {...field}
                    label={t('users:exhibitors')}
                    name="exhibitors"
                    options={exhibitors}
                    multiple={true}
                  />
                )}
              />
              <InputText
                label="Count of moderators"
                name="count_of_moderators"
                type="number"
                control={control}
                disabled={false}
              />
              <InputText
                label={t('users:number_of_authorized_exhibitors')}
                name="count_of_exhibitors"
                type="number"
                control={control}
                disabled={false}
              />
              <InputText
                label="Url"
                name="admin_url"
                type="text"
                control={control}
                disabled={false}
              />
            </div>
          ) : (
            ''
          )}
          {role === 'MODERATOR' ? (
            <div>
              <InputText
                label={t('users:number_of_authorized_exhibitors')}
                name="count_of_exhibitors"
                type="number"
                control={control}
                disabled={false}
                required
              />
            </div>
          ) : (
            ''
          )}
          <div>
            <InputText
              label={t('users:password')}
              name="password"
              type="password"
              control={control}
              disabled={false}
              required
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <A.Button
              type="button"
              bg="success"
              loading={loadingForm}
              onClick={async () => {
                await handleSubmit(() => onSubmit(getValues()))();
              }}
              className={s.submit_button}
              disabled={false}
            >
              {t('home:formInformation.save')}
            </A.Button>
          </div>
          <T.Block mt="20">
            <A.Alert alert="success" side="🎉" mb="15">
              {successForm && 'Successfully!'}
            </A.Alert>
            <A.Alert alert="error" side="⚠️" mb="15">
              {errorForm && <A.InnerHTML html={errorForm} />}
            </A.Alert>
          </T.Block>
        </form>
      </T.Block>
    </M.Loading>
  );
}

export default UserFormPage;
