export default {
  common: {
    select: 'Select',
    form: {
      addFile: 'Drag and drop or Add file',
      chooseFile: 'Choose file',
      noFileSelected: 'No file selected',
      select: 'Select',
      choose: 'Choose...',
      downloadFile: 'Download',
      error: {
        email: 'Please enter a valid email address',
        required: 'This field is required',
        minCharacters: '{{length}} characters',
        oneLowerCase: 'one lowercase',
        oneUpperCase: 'one uppercase',
        oneNumber: 'one number',
        passwordMustContain: `The password must contain at least \${items}.`,
        passwordMustMatch: 'Passwords must match',
        and: 'and',
        fileTooLarge: 'The file is too large : maximum weight {{size}}Mo',
      },
    },
    header: {
      nav: {
        home: 'Home',
        signin: 'Sign in',
        signup: 'Sign up',
        signout: 'Sign out',
      },
    },
    subheader: {
      date: 'September 24, 2024',
      location: 'Sydney, Australia',
    },
    footer: 'Contacts',
    congratulation: 'Congratulation',
    footer_text: 'Footer text (Bold text)',
    email_1: 'Contact email 1 (linked)',
    email_2: 'Contact email 2 (linked)',
  },
};
