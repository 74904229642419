import Client from '../Client.provider';
import { iUser } from '../User/User.repository';
import { iEventRest } from '../Event/Event.type';

export interface iCompanyRepository {
  updateCompany: (data: any) => Promise<any>;
  getCompanyRest: () => Promise<any>;
  getCompany: () => iCompany | null;
}

export interface iCompany {
  name: string;
  slug: string;
  email: string;
  phone: string;
  address: string;
  logo: number;
  main_logo: number;
  right_logo: number;
  logo_path: string;
  main_logo_path: string;
  right_logo_path: string;
  color: string;
  title: string;
  footer: string;
  event: iEventRest;
  contact: string;
}

interface iCompanyRest {
  name: string;
  slug: string;
  email: string;
  phone: string;
  address: string;
  logo: number;
  main_logo: number;
  right_logo: number;
  color: string;
  title: string;
  footer: string;
  event: iEventRest;
  contact: string;
  logo_path: string;
  main_logo_path: string;
  right_logo_path: string;
}

export class CompanyRepository extends Client implements iCompanyRepository {
  private _company: iCompany | null = null;
  public async updateCompany(data: any): Promise<iCompany> {
    console.log(data, 'updateCompany');
    return await this.public()
      .post<iCompany>(`/company-edit`, data)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public getCompany(): iCompany | null {
    if (!this.getCompanyRest()) return null;

    return this._company;
  }

  public async getCompanyRest(): Promise<iCompanyRest> {
    // const path = window.location.pathname.split('/')[1];
    const path = 'businessfrance';
    return await this.public()
      .get<iCompany>(`/check-company/${path}`)
      .then((e) => e.data)
      .then((companyRest) => this.setCompany(companyRest))
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  private setCompany(company: iCompanyRest): iCompany {
    this._company = {
      name: company.name,
      slug: company.slug,
      email: company.email,
      phone: company.phone,
      address: company.address,
      logo: company.logo,
      right_logo: company.right_logo,
      main_logo: company.main_logo,
      color: company.color,
      title: company.title,
      footer: company.footer,
      event: company.event,
      contact: company.contact,
      logo_path: company.logo_path,
      main_logo_path: company.main_logo_path,
      right_logo_path: company.right_logo_path,
    };
    return this._company;
  }
}
