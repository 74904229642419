export default {
  header: {
    products: 'Products',
    events: 'Events',
    exhibitor: 'Companies',
    societe: 'Company',
    users: 'Users',
    company_settings: 'Company Settings',
    sign_out: 'Sign Out',
  },
  eventList: {
    question: 'Quizz',
    exhibitor: 'Exhibitors',
    events: 'Event information',
  },
};
