import Client from '../Client.provider';
import { iEventRest } from '../Event/Event.type';

export enum eRoles {
  ADMIN = 'ADMIN',
  EXHIBITOR = 'EXHIBITOR',
  MODERATOR = 'MODERATOR',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export interface iUser {
  email: string;
  role: eRoles;
  firstname: string;
  lastname: string;
  phone: string;
  phone_port?: string;
  count_of_moderators: number;
  count_of_exhibitors: number;
}

export interface iUsers {
  users: iUser[];
  available_count: {
    moderators: number;
    exhibitors: number;
  };
}

export interface iUserRest {
  id: number;
  email: string;
  role: eRoles;
  firstname: string;
  lastname: string;
  phone: string;
  phone_port: string;
  token: string;
  token_type: string;
  expires_in: number;
  expires_at: number;
  count_of_moderators: number;
  count_of_exhibitors: number;
}

export interface iUserRepository {
  login: (
    email: string,
    password: string,
    code: string | null
  ) => Promise<iUser>;
  register: (
    firstname: string,
    lastname: string,
    phone: string,
    raison_sociale: string,
    siret: string,
    email: string,
    password: string,
    event: string,
    collectif: string
  ) => Promise<iUser>;
  reconnectUser: () => Promise<void>;
  getUser: () => iUser | null;
  forgotPassword: (email: string) => Promise<string>;
  updatePassword: (
    password: string,
    token: string | undefined
  ) => Promise<string>;
  logout: () => Promise<string>;
  updateUser: (
    param: {
      email: string;
      firstname: string;
      lastname: string;
      phone: string;
      phone_port: string;
      password?: string;
    },
    userId: number
  ) => Promise<iUser | null>;
  updateUserActive: (status: boolean, userId: number) => Promise<boolean>;
  remove: (userId: number) => Promise<boolean>;
  hardRemove: (userId: number) => Promise<boolean>;
  exhiRemove: (exhId: number) => Promise<boolean>;
  getUsers: () => Promise<iUsers>;
  getUserById: (userId: number | string) => Promise<any>;
  updateUserFeature: (userId: number | string, data: any) => Promise<boolean>;
  createUserFeature: (data: any) => Promise<iUser>;
  checkPath: (path: string) => Promise<any>;
  getOptions: () => Promise<any>;
  updateCompany: (data: any) => Promise<any>;
  getCompany: () => Promise<any>;
  getEventsModerator: () => Promise<any>;
  getDataForRegistrationPage: () => Promise<any>;
}
const BASE_PATH = 'businessfrance'; // You can dynamically set this

export class UserRepository extends Client implements iUserRepository {
  private _user: iUser | null = null;

  public async checkPath(path: string): Promise<any> {
    return await this.public()
      .get(`/check-path/${path}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async login(
    email: string,
    password: string,
    code: string | null
  ): Promise<iUser> {
    return await this.public()
      .post<iUserRest>(`/user/login/${BASE_PATH}`, {
        email,
        password,
        code,
      })
      .then((e) => e.data)
      .then((userRest) => this.setUser(userRest))
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getDataForRegistrationPage(): Promise<any> {
    return await this.public()
      .get<iUserRest>(`/${BASE_PATH}/get-events`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async register(
    firstname: string,
    lastname: string,
    phone: string,
    raison_sociale: string,
    siret: string,
    email: string,
    password: string,
    event: string,
    collectif: string
  ): Promise<iUser> {
    return await this.public()
      .post<iUserRest>(`/${BASE_PATH}/user/register`, {
        firstname,
        lastname,
        phone,
        raison_sociale,
        siret,
        email,
        password,
        event,
        collectif,
      })
      .then((e) => e.data)
      .then((userRest) => this.setUser(userRest))
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async forgotPassword(email: string): Promise<string> {
    return await this.restricted()
      .post<{ message: string }>(`/${BASE_PATH}/user/forgot-password`, {
        email,
      })
      .then((e) => {
        return e.data.message;
      });
  }

  public async updatePassword(
    password: string,
    token: string | undefined
  ): Promise<string> {
    return await this.public()
      .post<{ message: string }>(`/user/update-password`, { password, token })
      .then((e) => {
        return e.data.message;
      });
  }

  public async logout(): Promise<string> {
    return await this.restricted()
      .get<{ message: string }>(`/user/logout`)
      .then((e) => {
        this._user = null;
        this.auth = null;
        return e.data.message;
      });
  }

  public async reconnectUser(): Promise<void> {
    if (this.isTokenValid()) {
      if (this._user) return await Promise.resolve();

      return await this.restricted()
        .get<iUserRest>(`/user/refreshToken`)
        .then((e) => e.data)
        .then((userRest) => {
          this.setUser(userRest);
        })
        .catch((error) => {
          throw new Error(
            error.response.data.message ||
              `${error.response.status} | Unknow error...`
          );
        });
    }

    this._user = null;

    return undefined;
  }

  public getUser(): iUser | null {
    if (!this.isTokenValid()) return null;

    return this._user;
  }

  private setUser(user: iUserRest): iUser {
    this.auth = {
      token: user.token,
      token_type: user.token_type,
      expires_in: user.expires_in,
      expires_at: user.expires_at,
    };

    this._user = {
      email: user.email,
      role: user.role,
      firstname: user.firstname,
      lastname: user.lastname,
      phone: user.phone,
      phone_port: user.phone_port,
      count_of_moderators: user.count_of_moderators,
      count_of_exhibitors: user.count_of_exhibitors,
    };

    return this._user;
  }

  public async updateUser(
    {
      email,
      firstname,
      lastname,
      phone,
      phone_port,
      password,
    }: {
      email: string;
      firstname: string;
      lastname: string;
      phone: string;
      phone_port: string;
      password?: string;
    },
    userId: number
  ): Promise<iUser | null> {
    return await this.restricted()
      .put<iUserRest>(`/user/${userId}/update`, {
        email,
        firstname,
        lastname,
        phone,
        phone_port,
        ...(password ? { password } : {}),
      })
      .then((e) => e.data)
      .then((userRest) => {
        if (!this._user) {
          this.auth = null;
          throw new Error(`Unknow error...`);
        }
        this._user = {
          email: userRest.email,
          role: this._user?.role,
          firstname: userRest.firstname,
          lastname: userRest.lastname,
          phone: userRest.phone,
          phone_port: userRest.phone_port,
          count_of_moderators: userRest.count_of_moderators,
          count_of_exhibitors: userRest.count_of_exhibitors,
        };
        return this._user;
      })
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async updateUserActive(
    status: boolean,
    userId: number
  ): Promise<boolean> {
    return await this.restricted()
      .put<iUserRest>(`/user/${userId}/update/active`, { status })
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async remove(userId: number): Promise<boolean> {
    return await this.restricted()
      .delete<boolean>(`/user/${userId}`)
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async hardRemove(userId: number): Promise<boolean> {
    return await this.restricted()
      .delete<boolean>(`/user/${userId}`, {
        params: { hard: true },
      })
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async exhiRemove(exhId: number): Promise<boolean> {
    return await this.restricted()
      .delete<boolean>(`/user/exhibitor/${exhId}`)
      .then((e) => !!e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getUsers(): Promise<any> {
    return await this.restricted()
      .get<iUsers[]>(`/users`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async updateCompany(data: any): Promise<any> {
    console.log(data, 'updateCompany');
    return await this.restricted()
      .post<any>(`/company-edit`, data)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getCompany(): Promise<any> {
    return await this.restricted()
      .get<iUsers[]>(`/company`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getOptions(): Promise<any> {
    return await this.restricted()
      .get<any>(`/options`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getUserById(userId: number | string): Promise<any> {
    return await this.restricted()
      .get<any>(`/s_user/${userId}`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async getEventsModerator(): Promise<any> {
    return await this.restricted()
      .get<any>(`/event-for-moderator`)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async updateUserFeature(
    userId: number | string,
    data: any
  ): Promise<any> {
    return await this.restricted()
      .put<iUserRest>(`s_user/${userId}/feature`, data)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            `${error.response.status} | Unknow error...`
        );
      });
  }

  public async createUserFeature(data: any): Promise<iUser> {
    return await this.restricted()
      .post<any>(`s_user/create-user`, data)
      .then((e) => e.data)
      .catch((error) => {
        throw new Error(
          error.response.data.message ||
            JSON.stringify(error.response.data)
              .replace(/[{}[\]"]/g, '')
              .replace(/,/g, '<br/>') ||
            `${error.response.status} | Unknow error...`
        );
      });
  }
}
