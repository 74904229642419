import React, { useContext } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { A } from 'TOMA';
import { FormContext } from '../Form';

type iInputText<T extends FieldValues> = {
  name: string;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  type: 'text' | 'password' | 'email' | 'number' | 'tel' | 'url';
  control: Control<T>;
  label?: string;
  placeholder?: string;
  required?: string | boolean;
  disabled?: boolean;
  loading?: boolean;
  valid?: boolean;
  icon?: any;
  format?: (value: string) => string;
  information?: React.ReactNode;
  autocomplete?: string;
  after?: () => React.ReactNode;
} & UseControllerProps<T>;

export function InputText<T extends FieldValues>({
  name,
  type,
  inputMode,
  control,
  label,
  placeholder,
  required,
  disabled,
  loading,
  valid,
  icon,
  information,
  format,
  autocomplete,
  after,
}: iInputText<T>): JSX.Element {
  const translation = useContext(FormContext);
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isTouched },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
    rules: {
      required: required === true ? translation.required : required,
    },
  });

  const path = name.split('.');
  let key;
  let error: any = errors;
  // eslint-disable-next-line no-cond-assign
  while ((key = path.shift())) {
    if (!Number.isNaN(parseInt(key, 10))) {
      key = parseInt(key, 10);
    }
    if (!error[key]) {
      error = undefined;
      break;
    }
    error = error[key];
  }

  const errorMessage = (isTouched || isSubmitted) && error && error.message;

  return (
    <A.InputText
      type={type}
      inputMode={inputMode}
      name={name}
      label={label}
      placeholder={placeholder}
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      ref={ref}
      required={!!required}
      error={errorMessage}
      labelHidePwd={translation.showPassword}
      labelShowPwd={translation.hidePassword}
      disabled={disabled}
      loading={loading}
      valid={valid}
      icon={icon}
      information={information}
      format={format}
      autocomplete={autocomplete}
      after={after}
    />
  );
}
InputText.defaultProps = {
  inputMode: undefined,
  label: undefined,
  // min: undefined,
  // max: undefined,
  // maxLength: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
  // onChange: undefined,
  // onBlur: undefined,
  // error: undefined,
  loading: undefined,
  valid: undefined,
  icon: undefined,
  information: undefined,
};
