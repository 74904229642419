type tPath = `/${string}`;

function createRoute<T>(
  basePath: string,
  path: tPath
): {
  path: tPath;
  to: (args?: T) => tPath;
} {
  const fullPath = `${basePath}${path}` as tPath;
  return {
    path: fullPath,
    to: (args) => {
      if (!args) return fullPath;
      return Object.entries(args).reduce(
        (acc, [key, value]) => acc.replace(`:${key}`, value as string) as tPath,
        fullPath
      );
    },
  };
}

// const BASE_PATH = '/' + window.location.pathname.split('/')[1]; // You can dynamically set this
const BASE_PATH = '/businessfrance'; // You can dynamically set this

const routes = {
  newEvent: createRoute(BASE_PATH, '/events/new-event'),
  formInformation: createRoute<{ eventId: string | number }>(
    BASE_PATH,
    '/events/:eventId/form'
  ),
  inviteEvent: createRoute<{ eventId: string | number }>(
    BASE_PATH,
    '/events/:eventId/invite'
  ),
  editEvent: createRoute<{ eventId: string | number }>(
    BASE_PATH,
    '/events/:eventId/edit'
  ),
  admEventList: createRoute(BASE_PATH, '/events'),
  company: createRoute(BASE_PATH, '/company-setting'),
  eventlist: createRoute(BASE_PATH, '/eventlist'),
  home: createRoute(BASE_PATH, '/exhibitors'),
  home2: createRoute(BASE_PATH, '/'),
  login: createRoute(BASE_PATH, '/login'),
  signup: createRoute(BASE_PATH, '/signup'),
  forgotPassword: createRoute(BASE_PATH, '/forgot-password'),
  mainExhibitor: createRoute(BASE_PATH, '/exhibitor'),
  exhibitorID: createRoute(BASE_PATH, '/exhibitor/:exhibitorId'),
  event: createRoute<{ eventId: string | number }>(
    BASE_PATH,
    '/events/:eventId'
  ),
  eventExhibitors: createRoute<{ eventId: string | number }>(
    BASE_PATH,
    '/events/:eventId/exhibitor-list'
  ),
  eventExhibitor: createRoute<{ eventId: string | number }>(
    BASE_PATH,
    '/events/:eventId/exhibitor/:exhibitorId'
  ),
  exhibitor: createRoute<{ exhibitorId: string | number }>(
    '',
    '/exhibitor/:exhibitorId'
  ),
  users: createRoute(BASE_PATH, '/users'),
  newUser: createRoute(BASE_PATH, '/users/new-user'),
  userEdit: createRoute<{ userId: string | number }>(
    BASE_PATH,
    '/users/:userId'
  ),
  profile: createRoute(BASE_PATH, '/users'),
  newProfile: createRoute(BASE_PATH, '/users/new-user'),
  profileEdit: createRoute<{ userId: string | number }>(
    BASE_PATH,
    '/users/:userId'
  ),
  product: createRoute(BASE_PATH, '/product'),
  products: createRoute(BASE_PATH, '/products'),
  qrProductId: createRoute<{ productId: string | number }>(
    BASE_PATH,
    '/products/:productId'
  ),
  productId: createRoute<{ productId: string | number }>(
    BASE_PATH,
    '/product/:productId'
  ),
  productAdmin: createRoute<{ exhibitorId?: string | number }>(
    '',
    '/exhibitor/:exhibitorId/product'
  ),
  productIdAdmin: createRoute<{
    exhibitorId?: string | number;
    productId: string | number;
  }>(BASE_PATH, '/exhibitor/:exhibitorId/product/:productId'),
  codif: createRoute(BASE_PATH, '/codif'),
  confirmation: createRoute<{ confirmationId: string }>(
    BASE_PATH,
    '/confirmation/:confirmationId'
  ),
  updatePassword: createRoute<{ updatePasswordId: string }>(
    BASE_PATH,
    '/update-password/:updatePasswordId'
  ),
  acceptInvite: createRoute(BASE_PATH, '/accept/:code'),
  eventRegistration: createRoute<{ eventId: string | number }>(
    BASE_PATH,
    '/eventlist/event-registration/:exhibitorEventId'
  ),
  editProductEventRegistrationPage: createRoute(
    BASE_PATH,
    '/eventlist/event-registration/:exhibitorEventId/product/:productId'
  ),
  notFound: createRoute(BASE_PATH, '/not-found'),
};

export default routes;
