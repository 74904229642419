import React, { useEffect, useState } from 'react';
import { T, O, M, A } from 'TOMA';
import { CopyField } from '@eisberg-labs/mui-copy-field';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputText } from '../../form';
import useApiContext from '../../hooks/useApiContext';
import { useLocation, useParams } from 'react-router-dom';
import { iInvite } from '../../services/Event/Event.type';
import routes from '../../router/routes';
import useRepo from '../../hooks/useRepo';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';

function FormInformation(): JSX.Element {
  const { t } = useTranslation();
  const schema = yup.object({
    email: yup.string().email(t('common:form.error.email')),
  });
  const form = useForm<iInvite>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const { pathname } = useLocation();
  const { eventId } = useParams();

  const { handleSubmit, watch, control, reset, setValue, getValues } = form;
  const { eventRepository, userRepository } = useApiContext();
  const [successForm, setSuccessForm] = useState(false);
  const [errorForm, setErrorForm] = useState<string>();
  const [doneForm, setDoneForm] = useState<string>();
  const [loadingForm, setLoadingForm] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const { response, loading } = useRepo(
    async () =>
      eventId !== undefined
        ? await eventRepository.getEventById(eventId).then((e) => e)
        : '',
    [pathname]
  );
  const { response: companyResponse, loading: companyLoading } = useRepo(
    async () => await userRepository.getCompany().then((e) => e),
    [pathname]
  );
  useEffect(() => {
    if (!companyLoading) {
      console.log(companyResponse, 'companyResponse');
    }
  }, [companyResponse, companyLoading]);
  useEffect(() => {
    if (!eventId || loading || !response || companyLoading || !companyResponse)
      return;
    const invitePath = `${window.location.origin}/${
      companyResponse.company?.slug || ''
    }/login?code=${response.invitation_code}`;
    setInviteLink(invitePath);
  }, [eventId, loading, response, form.reset, companyLoading, companyResponse]);
  function onSubmit(data: iInvite): void {
    setLoadingForm(true);
    eventRepository
      .inviteEvent(eventId, data)
      .then((res) => {
        setSuccessForm(true);
        setDoneForm(res.message);
        console.log(res);
      })
      .catch((err: Error) => {
        setErrorForm(err.message);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const success = () => {
    toast(t('event:copy_the_link_success'), { type: 'success' });
  };
  return (
    <T.Container>
      <T.Block w="75" m="auto" h="25vh" p="25">
        <A.Text textAlign="center" fw="bold" mb="10">
          {t('event:copy_the_link')}
        </A.Text>
        <A.Line mb="20" />
        <CopyField
          label={t('event:copy_the_link')}
          value={inviteLink}
          onCopySuccess={success}
          onCopyError={console.log}
          copyTooltip={''}
          variant="outlined"
          disabled={true}
          fullWidth
        />
      </T.Block>
    </T.Container>
  );
}

export default FormInformation;
