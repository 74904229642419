import React, { useState, useContext, useEffect } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { A } from 'TOMA';
import { useTranslation } from 'react-i18next';

const MAX_SIZE = 5;

type iInputMedias<T extends FieldValues> = {
  control: Control<T>;
  addFileLabel?: string;
  id?: string;
  label?: React.ReactNode;
  information?: React.ReactNode;
  name: string;
  disabled?: boolean;
  onInit: (e: string) => Promise<{ id: string; src: string; filename: string }>;
  onChange: (e: File) => Promise<{ id: string; src: string; filename: string }>;
  onRemove: (e: string) => Promise<any>;
  onZoom?: (src: string) => void;
  required?: boolean;
} & UseControllerProps<T>;

export function InputMedias<T extends FieldValues>({
  label,
  addFileLabel,
  information,
  name,
  id,
  control,
  disabled,
  onInit,
  onChange: onChangeProp,
  onRemove,
  onZoom,
  required,
}: iInputMedias<T>): JSX.Element {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState<
    { filename: string; src: string } | undefined
  >(undefined);
  const {
    field: { onChange, value, ref },
    fieldState: { isTouched },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  const path = name.split('.');
  let key;
  let error: any = errors;
  while ((key = path.shift())) {
    if (!Number.isNaN(parseInt(key, 10))) {
      key = parseInt(key, 10);
    }
    if (!error[key]) {
      error = undefined;
      break;
    }
    error = error[key];
  }
  const errorMessage = (isTouched || isSubmitted) && error && error.message;
  const [err, setErr] = useState(errorMessage);

  useEffect(() => {
    if (value) {
      setLoading(true);
      onInit(value)
        .then((f) => setMedia({ filename: f.filename, src: f.src }))
        .finally(() => setLoading(false));
    } else {
      setMedia(undefined);
    }
  }, [value]);

  function onInputChange(files: FileList | null | undefined): void {
    if (files) {
      const file = files[0];
      setLoading(true);
      if (file.size > MAX_SIZE * 1_048_576) {
        setLoading(false);
        setErr(t('common:form.error.fileTooLarge', { size: MAX_SIZE }));
        return;
      }
      onChangeProp(file)
        .then((f) => {
          setErr(undefined);
          setMedia({ filename: f.filename, src: f.src });
          onChange(f.id);
        })
        .catch((err) => {
          setErr(err.message);
          onChange(undefined);
        })
        .finally(() => setLoading(false));
    }
  }

  async function onInputRemove(): Promise<void> {
    setLoading(true);
    await onRemove(value);
    onChange(undefined);
    setMedia(undefined);
    setLoading(false);
  }

  return (
    <A.InputMedias
      label={label}
      addFileLabel={addFileLabel ?? t('common:form.addFile')}
      information={information}
      name={name}
      id={id}
      loading={loading}
      // onChange={onInputChange}
      onFileChange={onInputChange}
      onRemove={onInputRemove}
      onZoom={onZoom}
      disabled={disabled}
      media={media}
      error={err || errorMessage}
      ref={ref}
      required={required}
    />
  );
}
InputMedias.defaultProps = {
  id: undefined,
  disabled: undefined,
};
