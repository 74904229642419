import React, { useState, useMemo } from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { CSVLink } from 'react-csv';
import useApiContext from 'hooks/useApiContext';
import useModalContext from 'hooks/useModalContext';
import { useLocation, Outlet, Link, useNavigate } from 'react-router-dom';
import { format, isAfter } from 'date-fns';
import useRepo from 'hooks/useRepo';
import { T, O, M, A } from 'TOMA';
import { useTranslation } from 'react-i18next';
import s from './UserEventList.module.scss';

import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  Visibility,
} from '@tanstack/react-table';
import { Switch } from 'components/Switch';
import { CustomLink } from 'components/CustomLink';

const columnHelper = createColumnHelper<any>();

function UserEventList(): JSX.Element {
  const { pathname } = useLocation();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const { codificationRepository, exhibitorEventRepository } = useApiContext();
  const [filterCollectif, setFilterCollectif] = useState<any>();
  const [filterName, setFilterName] = useState<any>();
  const [reload, setReload] = useState<number>(0);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    response = [],
    loading,
    error,
  } = useRepo(
    async () => await exhibitorEventRepository.getExhibitorEvents(),
    [reload]
  );

  const { response: codifCollectif } = useRepo(
    async () => await codificationRepository.get('collectif')
  );

  const columns = useMemo(
    () => [
      // columnHelper.accessor("status", {
      //   header: "Status",
      //   cell: (info) => {
      //     const val = info.getValue();
      //     return (
      //       <div className={s.ExhibitorList__status} data-key={val}>
      //         {val}
      //       </div>
      //     );
      //   },
      // }),
      // columnHelper.accessor("visibility", {
      //   header: "Visibility",
      //   enableSorting: false,
      //   cell: ({ getValue }) => {
      //     const { value, eventId } = getValue();
      //     return (
      //       <Switch
      //         key={eventId}
      //         checked={value}
      //         onChange={async (e) =>
      //           await eventRepository.mergeEvent("visibility", e, eventId)
      //         }
      //       />
      //     );
      //   },
      // }),
      // columnHelper.accessor('visibility', {
      //   header: t('event:participer'),
      //   enableSorting: false,
      //   cell: ({ getValue }) => {
      //     const { value, exhibitorEventId } = getValue();
      //     return (
      //       <Switch
      //         checked={value}
      //         onChange={async (e) => {
      //           try {
      //             console.log(e);
      //             const data = {
      //               visibility: e,
      //             };
      //             console.log(data);
      //             await exhibitorEventRepository.mergeExhibitorEvent(
      //               exhibitorEventId,
      //               data
      //             );
      //             return true;
      //           } catch (error) {
      //             console.error(error);
      //             return false;
      //           }
      //         }}
      //       />
      //     );
      //   },
      // }),
      columnHelper.accessor('name', {
        header: t('event:name_of_the_event'),
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, eventId } = getValue();

          return (
            <div className={s.ExhibitorList__status} data-key={eventId}>
              {value}
            </div>
          );
        },
      }),
      columnHelper.accessor('contact', {
        header: t('event:contact'),
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, eventId, collectif, collectif_isset } = getValue();
          console.log(value, 'value');
          console.log(eventId);
          console.log(collectif);
          console.log(collectif_isset);
          return (
            <div className={s.ExhibitorList__status} data-key={eventId}>
              {collectif_isset ? collectif : value}
            </div>
          );
        },
      }),
      columnHelper.accessor('date', {
        header: t('event:date'),
        enableSorting: false,
        cell: (info) => {
          const val = info.getValue();
          return (
            <div className={s.ExhibitorList__status} data-key={val}>
              {val ? new Date(val).toLocaleDateString('en-GB') : ''}
            </div>
          );
        },
      }),
      columnHelper.accessor('country', {
        header: t('event:country'),
        enableSorting: false,
        cell: (info) => {
          const val = info.getValue();
          return (
            <div className={s.ExhibitorList__status} data-key={val}>
              {val}
            </div>
          );
        },
      }),
      columnHelper.accessor('Dossier', {
        header: t('event:dossier'),
        enableSorting: false,
        cell: ({ getValue }) => {
          const { exhibitorEventId } = getValue();
          // const [visible, setvisible] = useState(false);
          return (
            <div>
              <CustomLink
                to={`${pathname}/event-registration/${exhibitorEventId}`}
                color="info"
              >
                {t('event:edit')}
              </CustomLink>
              {''}
            </div>
          );
        },
      }),
    ],
    []
  );

  const data = useMemo(
    () =>
      response.map((e) => {
        return {
          // status: e.status,
          visibility: {
            value: !!e.visibility,
            exhibitorEventId: e.id,
          },
          date: e.event_date,
          country: e.event_country,
          name: {
            value: e.event_name,
            eventId: e.event_id,
          },
          contact: {
            value: [e?.event?.contact_email_1, e?.event?.contact_email_2]
              .filter(Boolean) // This filters out any null or undefined values
              .join(', '),
            eventId: e.event_id,
            collectif: e.collectif_email,
            collectif_isset: !!e.collectif,
          },
          url: {
            eventId: e.event_id,
          },
          showroom: {
            value: e.showroom,
            exhibitorEventId: e.id,
          },
          Dossier: {
            exhibitorEventId: e.id,
          },
        };
      }),
    [response, codifCollectif, filterCollectif, filterName]
  );

  const table = useReactTable({
    // data: data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    data,
    columns,
    state: {
      sorting,
      // pagination,
    },
    // pageCount: Math.ceil(data.length / pageSize) || 1,
    manualPagination: true,
    // onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div>
      <div>
        <T.Container py="50">
          <M.Loading loading={loading} error={error} fullWiewport>
            <A.Title
              as="h1"
              fontSize="48"
              mb="30"
              fw="black"
              textAlign="center"
            >
              {t('event:list_of_events')}
            </A.Title>

            <T.Flex valign="center" bg="grey-10" p="30" pb="10" mb="40" gap={2}>
              <T.Flex valign="center" gap="2">
                <A.InputText
                  name="search"
                  type="text"
                  placeholder={t('event:events')}
                  onChange={(e) => {
                    // setPagination((state) => ({
                    //   ...state,
                    //   pageIndex: 0,
                    // }));
                    setFilterName(e);
                  }}
                />
              </T.Flex>
            </T.Flex>
            <T.Block mb="20">
              <table className={s.Table}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          <A.Button
                            appearance="link"
                            color="grey-80"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                            {{
                              asc: <A.Icon icon="chevron-down" ml="5" />,
                              desc: <A.Icon icon="chevron-up" ml="5" />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </A.Button>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className={s.ExhibitorList__row}
                        data-removed={row.getIsSelected() ? 'true' : undefined}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            <div className={s.ExhibitorList__row__div}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </T.Block>
          </M.Loading>
        </T.Container>
      </div>
    </div>
  );
}

export default UserEventList;
