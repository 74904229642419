import React, { useState, useMemo, useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import useApiContext from 'hooks/useApiContext';
import useModalContext from 'hooks/useModalContext';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  TextField,
  InputAdornment,
  Button,
  Alert,
} from '@mui/material';
import {
  useLocation,
  Outlet,
  Link,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { format, isAfter } from 'date-fns';
import useRepo from 'hooks/useRepo';
import { T, O, M, A } from 'TOMA';
import s from './ExhibitorList.module.scss';
import routes from 'router/routes';
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Switch } from 'components/Switch';
import { CustomLink } from 'components/CustomLink';
import { InputStand } from 'components/InputStand';
import { eRoles } from '../../interfaces/Roles';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<any>();

function Exhibitors(): JSX.Element {
  const [csvError, setCsvError] = useState<string | undefined>(undefined);
  const [csvSuccess, setCsvSuccess] = useState<boolean>(false);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const { setModalContent, setModalVisible } = useModalContext();
  const { pathname } = useLocation();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const {
    userRepository,
    exhibitorRepository,
    exhibitorEventRepository,
    codificationRepository,
    preferenceRepository,
  } = useApiContext();
  const user = userRepository.getUser();
  const isModerator = user?.role === eRoles.MODERATOR;
  const isAdmin = user?.role === eRoles.ADMIN;
  const [filterCollectif, setFilterCollectif] = useState<any>();
  const [filterName, setFilterName] = useState<any>();
  const [reload, setReload] = useState<number>(0);
  const { eventId } = useParams();
  const { t } = useTranslation();
  const {
    response = [],
    loading,
    error,
  } = useRepo(async () => {
    if (routes.home.path.includes(pathname)) {
      return await exhibitorRepository.getExhibitorsWithoutEvent();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return await exhibitorRepository.getExhibitors(eventId!);
    }
  }, [reload]);
  const outletRef = useRef<HTMLDivElement>(null);
  // const [loadCSV, setLoadCSV] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const downloadCSV = async () => {
    try {
      // setLoadCSV(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await exhibitorRepository
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .downloadCSV(eventId!)
        .then((e) => {
          console.log(e.data.data);
          setCsvData(e.data.data);
          // setLoadCSV(true);
        });
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const { response: dateLimit } = useRepo(
    async () => await preferenceRepository.find('date_limit'),
    [reload]
  );

  const { response: codifCollectif } = useRepo(
    async () => await codificationRepository.get('collectif')
  );

  // const [{ pageIndex, pageSize }, setPagination] =
  //   React.useState<PaginationState>({
  //     pageIndex: 0,
  //     pageSize: 10,
  //   });

  // const pagination = React.useMemo(
  //   () => ({
  //     pageIndex,
  //     pageSize,
  //   }),
  //   [pageIndex, pageSize]
  // );
  const columns = useMemo(
    () => [
      columnHelper.accessor('remove', {
        header: '',
        enableSorting: false,
        cell: (info) => {
          const val = info.getValue();
          return (
            <button
              key={val.exhibId}
              type="button"
              className={s.ExhibitorList__remove}
              onClick={() => {
                setModalContent(
                  <div>
                    <A.Text fontSize="22" fw="bold" mb="20">
                      Supprimer l&apos;exposant &quot;{val.exhibName}&quot; ?
                    </A.Text>
                    <T.Flex gap={2} valign="center" mb="-15">
                      <A.Button
                        bg="alert"
                        onClick={() => {
                          userRepository.exhiRemove(val.exhibId).then(() => {
                            setModalVisible(false);
                            setReload((e) => e + 1);
                          });
                        }}
                      >
                        Confirmer
                      </A.Button>
                      <A.Button
                        appearance="link"
                        color="grey-80"
                        mb="15"
                        textDecoration="underline"
                        onClick={() => setModalVisible(false)}
                      >
                        Annuler
                      </A.Button>
                    </T.Flex>
                  </div>
                );
              }}
            >
              {val.removed ? (
                <A.Icon icon="trash3-fill" color="alert" fontSize="22" />
              ) : (
                <A.Icon icon="trash2" color="alert" fontSize="22" />
              )}
            </button>
          );
        },
      }),
      columnHelper.accessor('number', {
        header: 'ID',
      }),
      columnHelper.accessor('id_ITL', {
        header: 'ID structure',
      }),
      columnHelper.accessor('active', {
        header: t('event:active'),
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, userId, exhibId } = getValue();
          return (
            <Switch
              key={exhibId}
              defaultChecked={value}
              onChange={async (e) =>
                await userRepository.updateUserActive(e, userId)
              }
            />
          );
        },
      }),
      columnHelper.accessor('exhibName', {
        header: t('exhibitors:name_of_the_company'),
        cell: (info) => {
          const val = info.getValue();
          return (
            <CustomLink to={`${pathname}/exhibitor/${val.exhibId}`}>
              <b>{val.exhibName}</b>
            </CustomLink>
          );
        },
      }),
    ],
    []
  );
  const [csvData, setCsvData] = useState<any[]>([]);
  const [selectedAction, setSelectedAction] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleActionChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const action = event.target.value as string;
    setSelectedAction(action);

    switch (action) {
      case 'addExhibitors':
        // navigate to add exhibitors page or handle the action
        break;
      case 'exportSimple':
        // Handle the export CSV logic
        break;
      case 'uploadCSV':
        // Trigger CSV upload functionality
        break;
      case 'extractComplete':
        // Handle the complete extraction logic
        break;
      case 'exportExhibitors':
        // Handle exporting exhibitors
        break;
      default:
        break;
    }
  };
  const data = useMemo(
    () =>
      response
        .filter((e) => {
          if (filterCollectif === 'individuel') {
            return e.exhibitor_stand_type === filterCollectif;
          }
          if (filterCollectif) {
            return e.exhibitor_collectif_type === filterCollectif;
          }
          return true;
        })
        .filter((e) => {
          const regex = new RegExp(filterName, 'gi');
          return filterName
            ? regex.test(e.exhibitor_raison_sociale ?? '')
            : true;
        })
        .map((e) => {
          let collectif = '';
          if (e.exhibitor_stand_type === 'individuel') {
            collectif = 'Individuel';
          } else {
            collectif = e.exhibitor_collectif_type
              ? codifCollectif?.find(
                  (ee) => ee.topicCode === e.exhibitor_collectif_type
                )?.label ?? ''
              : collectif;
          }
          return {
            remove: {
              userId: e.user_id,
              exhibId: e.exhibitor_id,
              exhibName: e.exhibitor_raison_sociale,
              removed: e.removed,
            },
            new: {
              isNew: isAfter(new Date(e.user_created_at), new Date(2023, 8, 1)),
            },
            status: e.exhibitor_locked
              ? 'Transmis'
              : e.exhibitor_raison_sociale
              ? 'Enregistré'
              : e.user_email_verified_at
              ? 'En cours'
              : 'En attente',
            active: {
              value: !!e.user_email_verified_at,
              userId: e.user_id,
              exhibId: e.exhibitor_id,
            },
            visibility: {
              value: !!e.exhibitor_visibility,
              exhibId: e.exhibitor_id,
            },
            locked: {
              value: !!e.exhibitor_locked,
              exhibId: e.exhibitor_id,
            },
            id: e.exhibitor_id,
            userEmail: e.user_email,
            number: `ID ${String(e.exhibitor_id).padStart(4, '0')}`,
            id_ITL: e.exhibitor_id_ITL
              ? `ID ${String(e.exhibitor_id_ITL).padStart(4, '0')}`
              : '-',
            showroom: {
              value: !!e.exhibitor_showroom,
              exhibId: e.exhibitor_id,
              exhibEventId: e.exhibitor_event_id ?? 0,
            },
            raisonSocial: e.exhibitor_raison_sociale,
            exhibName: {
              exhibId: e.exhibitor_id,
              exhibName: e.exhibitor_raison_sociale,
            },
            nomCommercial: e.exhibitor_nom_societe,
            collectif,
            year: { year: e.year, exhibId: e.exhibitor_id },
            numeroHall: {
              stand: e.exhibitor_stand,
              hall: e.exhibitor_hall,
              exhibId: e.exhibitor_id,
            },
            edit: {
              exhibitorId: e.exhibitor_id,
            },
          };
        }),
    [response, codifCollectif, filterCollectif, filterName]
  );

  const table = useReactTable({
    // data: data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    data,
    columns,
    state: {
      sorting,
      // pagination,
    },
    // pageCount: Math.ceil(data.length / pageSize) || 1,
    manualPagination: true,
    // onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
  });
  const navigate = useNavigate();
  const classActive =
    pathname === routes.home.path
      ? false
      : !pathname.match(/^\/events\/[^/]+$/);

  const handleClickOutside = (event: any): void => {
    if (outletRef.current && !outletRef.current.contains(event.target)) {
      // Determine the redirection path
      const redirectTo = pathname.includes('events')
        ? `/events/${eventId ?? ''}`
        : routes.home.path.replace(/\/$/, '');

      // Trigger navigation (redirect)
      navigate(redirectTo);
      setReload((e) => e + 1);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div>
      <div className={pathname !== routes.home.path ? s.blur : undefined}>
        <T.Container>
          <M.Loading loading={loading || csvLoading} error={error} fullWiewport>
            {!pathname.includes('events') ? (
              <A.Title
                as="h1"
                fontSize="48"
                mb="30"
                mt="50"
                fw="black"
                textAlign="center"
              >
                {t('exhibitors:list_of_exhibitors')}
              </A.Title>
            ) : (
              ''
            )}
            <T.Flex
              valign="center"
              p="30"
              mb="40"
              gap={2}
              className={s.justifycenter}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div>
                  <T.Flex valign="center" gap="2">
                    <div>
                      {eventId ? (
                        <A.Button
                          onClick={() => {
                            navigate(`${pathname}/exhibitor-list`);
                          }}
                        >
                          {t('event:add_exhibitors')}
                        </A.Button>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className={s.inputfilter}>
                      <TextField
                        variant="outlined"
                        placeholder={t('event:find_an_exhibitors')}
                        onChange={(e) => {
                          // setPagination((state) => ({
                          //   ...state,
                          //   pageIndex: 0,
                          // }));
                          setFilterName(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <Button
                      disabled={user?.role === 'MODERATOR'}
                      sx={{
                        height: '55px',
                      }}
                      variant="outlined"
                      onClick={() => {
                        const fileInput = document.createElement('input');
                        fileInput.type = 'file';
                        fileInput.accept = '.csv, .xls, .xlsx';
                        fileInput.onchange = async ({ target }: any) => {
                          setCsvLoading(true);
                          setCsvSuccess(false);
                          setCsvError('');
                          if (!target.files) return;
                          const file = target.files[0];
                          const formdata = new FormData();
                          formdata.append('file', file);
                          try {
                            await exhibitorRepository.uploadExhiCSV(formdata);
                            setCsvSuccess(true);
                            setReload((e) => e + 1);
                          } catch (err: any) {
                            console.log('err', err.message);
                            setCsvError(`${err.message}`);
                          } finally {
                            setCsvLoading(false);
                          }
                        };
                        fileInput.click();
                      }}
                    >
                      {t('exhibitors:import_exhibitor_database')}
                    </Button>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ width: 200 }}
                    >
                      <InputLabel id="actions-select-label">Actions</InputLabel>
                      <Select
                        labelId="actions-select-label"
                        value={selectedAction}
                        // @ts-expect-error
                        onChange={handleActionChange}
                        label={t('exhibitors:action')}
                      >
                        <MenuItem value="exportSimple">
                          <CSVLink
                            style={{ textDecoration: 'none' }}
                            filename={`export${
                              filterCollectif ? `-${filterCollectif}` : ''
                            }-${format(new Date(), 'dd-MM-yyyy-HH-mm-ss')}.csv`}
                            separator={';'}
                            enclosingCharacter={'"'}
                            data={[
                              ['ID', 'ID structure', 'Raison social'],
                              ...data.map((e) => [
                                e.id,
                                e.id_ITL,
                                e.raisonSocial?.replace(/"/g, '""'),
                              ]),
                            ]}
                          >
                            {t('exhibitors:simple_extraction')}
                          </CSVLink>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </T.Flex>
                </div>
              </div>
            </T.Flex>
            <T.Block mb="20">
              <table className={s.Table}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          <A.Button
                            appearance="link"
                            color="grey-80"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                            {{
                              asc: <A.Icon icon="chevron-down" ml="5" />,
                              desc: <A.Icon icon="chevron-up" ml="5" />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </A.Button>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row, index) => {
                    return (
                      <tr
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? 'white' : '#F8F8F8',
                        }}
                        key={row.id}
                        className={s.ExhibitorList__row}
                        data-removed={row.getIsSelected() ? 'true' : undefined}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            <div className={s.ExhibitorList__row__div}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </T.Block>

            {/* <T.Flex gap="1" valign={'center'}>
              <A.Button
                appearance="small"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                {'<<'}
              </A.Button>
              <A.Button
                appearance="small"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                {'<'}
              </A.Button>
              <A.Button
                appearance="small"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {'>'}
              </A.Button>
              <A.Button
                appearance="small"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              >
                {'>>'}
              </A.Button>
              <div>
                <span>Page </span>
                <strong>
                  {table.getState().pagination.pageIndex + 1} of{' '}
                  {table.getPageCount()}
                </strong>
              </div>
            </T.Flex> */}
          </M.Loading>
        </T.Container>
      </div>
      {pathname !== routes.home.path && (
        <div className={s.ExhibitorList__outlet}>
          <Link
            className={s.ExhibitorList__outlet__close}
            to={pathname.includes('events') ? `/events/${eventId ?? ''}` : ''}
            onClick={() => setReload((e) => e + 1)}
          >
            <div>
              <A.Icon icon="x-lg" />
            </div>
          </Link>
          <div className={s.ExhibitorList__outlet__content} ref={outletRef}>
            <Outlet />
          </div>
        </div>
      )}
      {csvError !== '' && csvError !== undefined && (
        <Snackbar
          open={csvError !== ''}
          autoHideDuration={6000}
          onClose={() => setCsvError('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setCsvError('')}
            severity="error"
            sx={{ width: '100%' }}
          >
            {csvError}
          </Alert>
        </Snackbar>
      )}
      {csvSuccess && (
        <Snackbar
          open={csvSuccess}
          autoHideDuration={6000}
          onClose={() => setCsvSuccess(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setCsvSuccess(false)}
            severity="success"
            sx={{ width: '100%' }}
          >
            {'File imported successfully'}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
export default Exhibitors;
