import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, Outlet, Link } from 'react-router-dom';
import useApiContext from 'hooks/useApiContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { T, O, M, A } from 'TOMA';
import { InputText, InputFile, InputMedias } from 'form';
import { eRoles } from 'interfaces/Roles';
import s from './Event.module.scss';
import {
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  Button,
  IconButton,
} from '@mui/material';
import useRepo from '../../hooks/useRepo';
import useModalContext from '../../hooks/useModalContext';
import { iCompany } from '../../services/Company/Company.repository';
import { useTranslation } from 'react-i18next';
export interface iForm {
  name: string;
  color: string;
  address: string;
  phone: string;
  title: string;
  footer: string;
  slug: string;
  contact: string;
  event_id: number | string;
  logo: string;
  right_logo: string;
  main_logo: string;
}

function Company(): JSX.Element {
  const { setModalContent } = useModalContext();
  const {
    companyRepository,
    userRepository,
    mediaRepository,
    codificationRepository,
  } = useApiContext();
  const { pathname } = useLocation();
  const user = userRepository.getUser();
  const [loadingForm, setLoadingForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [errorForm, setErrorForm] = useState<string>();
  const isAdmin =
    user?.role === eRoles.ADMIN || user?.role === eRoles.MODERATOR;
  const form = useForm<iForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit, watch, control, reset, setValue, getValues, register } =
    form;
  const { response, loading } = useRepo(
    async () => await userRepository.getCompany().then((e) => e),
    [pathname]
  );
  const [file, setFile] = useState('');
  const [fileRight, setFileRight] = useState('');
  const [fileMain, setFileMain] = useState('');
  useEffect(() => {
    if (!loading && response) {
      setEvent(response.company.event_id);
      setEvents(response.events);
      setFile(response.logo);
      setFileRight(response.right_logo);
      setFileMain(response.main_logo);
      reset(response.company);
    }
  }, [loading, response, form.reset]);

  function onSubmit(data: iForm): void {
    setValue('slug', 'businessfrance');
    setValue('logo', file);
    setValue('right_logo', fileRight);
    setValue('main_logo', fileMain);
    userRepository
      .updateCompany({
        ...data,
        logo: file,
        right_logo: fileRight,
        main_logo: fileMain,
      })
      .then(() => {
        setSuccessForm(true);
        setErrorForm('');
      })
      .catch((err: Error) => {
        setErrorForm(err.message);
      });
  }
  const { t } = useTranslation();
  const [reload, setReload] = useState<number>(0);
  const [event, setEvent] = useState<number | string>();
  const [events, setEvents] = useState<[]>([]);
  return (
    <T.Block mt="25">
      <div className={pathname !== '/' && !isAdmin ? s.blur : undefined}>
        <M.Loading loading={false} error={null} fullWiewport>
          <T.Block
            w={pathname.includes('new-event') ? '100' : '75'}
            mx={pathname.includes('new-event') ? '0' : 'auto'}
            bg="white"
            borderRadius="20"
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
              noValidate
            >
              <T.Block>
                <T.Block p="20">
                  <A.Title
                    as="h1"
                    fontSize="48"
                    mb="30"
                    fw="black"
                    textAlign="center"
                  >
                    {t('company:company_informations')}
                  </A.Title>
                  <A.Line mb="20" />
                  <T.Block>
                    <InputText
                      label={t('company:name')}
                      name="name"
                      type="text"
                      control={control}
                      disabled={false}
                    />
                  </T.Block>
                  <T.Block>
                    <Controller
                      name="event_id"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          sx={{ marginBottom: '20px' }}
                        >
                          <InputLabel>{t('company:main_event')}</InputLabel>
                          <Select
                            {...field}
                            label={t('company:main_event')}
                            value={field.value || ''}
                            onChange={(e) =>
                              field.onChange(
                                e.target.value !== '' ? e.target.value : null
                              )
                            }
                          >
                            {events?.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                            <MenuItem key={'select-event'} value={''}>
                              Select Empty
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </T.Block>
                  <T.Block>
                    <A.Text>{t('company:color')}</A.Text>
                    <Controller
                      name="color"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          sx={{ marginBottom: '20px' }}
                        >
                          <input type="color" {...field} />
                        </FormControl>
                      )}
                    />
                  </T.Block>
                  <T.Block>
                    <InputMedias
                      label={t('company:logo')}
                      information={t('company:logo')}
                      name="logo"
                      defaultValue={file}
                      control={control}
                      onInit={async (mediaId) => {
                        return await mediaRepository
                          .get(mediaId)
                          .then(({ id, w640, filename }) => ({
                            id,
                            src: w640,
                            filename,
                          }));
                      }}
                      onChange={async (file) => {
                        const formdata = new FormData();
                        formdata.append('media', file);
                        const result = await mediaRepository.upload(formdata);
                        if (result) {
                          setFile(result.id);
                          return result;
                        }
                        return null;
                      }}
                      onRemove={async (mediaId) => {
                        return await mediaRepository
                          .remove('logo', mediaId)
                          .then((e) => {
                            setFile('');
                          });
                      }}
                      onZoom={(src) => {
                        setModalContent(
                          <img src={src} style={{ maxWidth: '100%' }} />
                        );
                      }}
                      required
                    />
                  </T.Block>
                  <T.Block>
                    <InputMedias
                      label={t('company:right_header_logo')}
                      information={t('company:right_header_logo')}
                      name="right_logo"
                      defaultValue={fileRight}
                      control={control}
                      onInit={async (mediaId) => {
                        return await mediaRepository
                          .get(mediaId)
                          .then(({ id, w640, filename }) => ({
                            id,
                            src: w640,
                            filename,
                          }));
                      }}
                      onChange={async (file) => {
                        const formdata = new FormData();
                        formdata.append('media', file);
                        const result = await mediaRepository.upload(formdata);
                        if (result) {
                          setFileRight(result.id);
                          return result;
                        }
                        return null;
                      }}
                      onRemove={async (mediaId) => {
                        return await mediaRepository
                          .remove('right_logo', mediaId)
                          .then((e) => {
                            setFileRight('');
                            setValue('right_logo', '');
                          });
                      }}
                      onZoom={(src) => {
                        setModalContent(
                          <img src={src} style={{ maxWidth: '100%' }} />
                        );
                      }}
                      required
                    />
                  </T.Block>
                  <T.Block mt="5">
                    <InputText
                      label={t('company:title_of_the_plateform')}
                      name="title"
                      type="text"
                      control={control}
                      disabled={false}
                    />
                  </T.Block>
                  <T.Block>
                    <InputText
                      label={t('common:footer_text')}
                      name="footer"
                      type="text"
                      control={control}
                      disabled={false}
                    />
                  </T.Block>
                  <T.Block>
                    <InputText
                      label={t('company:address')}
                      name="address"
                      type="text"
                      control={control}
                      disabled={false}
                    />
                  </T.Block>
                  <T.Block mt="5">
                    <InputText
                      label={t('company:contact')}
                      name="contact"
                      type="text"
                      control={control}
                      disabled={false}
                    />
                  </T.Block>
                  <T.Block>
                    <InputText
                      label={t('company:phone')}
                      name="phone"
                      type="text"
                      control={control}
                      disabled={false}
                    />
                  </T.Block>
                  <A.Alert alert="success" side="🎉" mb="15">
                    {successForm && 'Company updated successfully!'}
                  </A.Alert>
                  <A.Alert alert="error" side="⚠️" mb="15">
                    {errorForm && <A.InnerHTML html={errorForm} />}
                  </A.Alert>
                </T.Block>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <A.Button
                    type="button"
                    bg="success"
                    loading={loadingForm}
                    onClick={async () => {
                      handleSubmit(() => onSubmit(getValues()))();
                    }}
                    className={s.submit_button}
                    disabled={false}
                  >
                    {t('company:save')}
                  </A.Button>
                </div>
              </T.Block>
            </form>
          </T.Block>
        </M.Loading>
      </div>
      {pathname !== '/' && !isAdmin && (
        <div className={s.Exhibitor__outlet}>
          <Link className={s.Exhibitor__outlet__close} to="/">
            <div>
              <A.Icon icon="x-lg" />
            </div>
          </Link>
          <div className={s.Exhibitor__outlet__content}>
            <Outlet />
          </div>
        </div>
      )}
    </T.Block>
  );
}

export default Company;
