import { A, T } from '../../TOMA';
import {
  InputCheckbox,
  InputFile,
  InputMedias,
  InputPhone,
  InputSelect,
  InputText,
  InputTextarea,
} from '../../form';
import { CustomLink } from '../../components/CustomLink';
import s from './Exhibitor.module.scss';
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { iForm } from './Exhibitor.page';
import { useTranslation } from 'react-i18next';
import useModalContext from '../../hooks/useModalContext';
import { useLocation, useParams } from 'react-router-dom';
import useApiContext from '../../hooks/useApiContext';
import { eRoles } from '../../interfaces/Roles';
import useRepo from '../../hooks/useRepo';
import { format } from 'date-fns';
import dateFormat from '../../helpers/formatDate';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import routes from '../../router/routes';
import QRCode from 'qrcode';
const getQrCode = async function (text: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    QRCode.toDataURL(
      text,
      function (error: Error | null | undefined, url: string) {
        if (error != null) reject(error);
        else resolve(url);
      }
    );
  });
};

const ExhibitorForEvent = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { exhibitorEventId, exhibitorId, eventId } = useParams();
  const { setModalContent } = useModalContext();
  const {
    exhibitorRepository,
    userRepository,
    mediaRepository,
    codificationRepository,
    preferenceRepository,
    exhibitorEventRepository,
  } = useApiContext();
  const { pathname } = useLocation();
  const user = userRepository.getUser();
  const [loadingForm, setLoadingForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [errorForm, setErrorForm] = useState<string>();
  const [externalURL, setExternalURL] = useState<string>();
  const [qrcodeImg, setQrcodeImg] = useState<string>();

  const isAdmin = user?.role === eRoles.ADMIN;
  const isModerator = user?.role === eRoles.MODERATOR;

  const { response, loading, error, setResponse } = useRepo(
    async () => await exhibitorRepository.getUserExhibitor(exhibitorId),
    []
  );

  const { response: products, loading: loadingProduct } = useRepo(
    async () =>
      await exhibitorRepository
        .getUserExhibitor(exhibitorId)
        .then((e) => e.products),
    [pathname]
  );
  const { response: codifTypeEntreprise } = useRepo(
    async () => await codificationRepository.get('type_entreprise')
  );
  const { response: dateLimit, loading: loadingDatelimit } = useRepo(
    async () => await preferenceRepository.find('date_limit')
  );
  const dtLimit = dateLimit ? new Date(dateLimit) : null;
  const dtLimitFrench = dtLimit
    ? format(new Date(dtLimit), dateFormat())
    : null;

  const schema = isAdmin
    ? yup.object({})
    : yup.object({
        firstname: yup
          .string()
          .max(255)
          .required(t('common:form.error.required'))
          .nullable(),
        lastname: yup
          .string()
          .max(255)
          .required(t('common:form.error.required'))
          .nullable(),
        email: yup
          .string()
          .max(255)
          .email(t('common:form.error.email'))
          .required(t('common:form.error.required'))
          .nullable(),

        adresse_postale: yup
          .string()
          .max(255)
          .required(t('common:form.error.required'))
          .nullable(),
        ville: yup
          .string()
          .max(63)
          .required(t('common:form.error.required'))
          .nullable(),
        code_postal: yup
          .string()
          .max(15)
          .required(t('common:form.error.required'))
          .nullable(),
        pays: yup
          .string()
          .max(63)
          .required(t('common:form.error.required'))
          .nullable(),
        site_web: yup
          .string()
          .max(255)
          .required(t('common:form.error.required'))
          .nullable(),
        siret: yup
          .string()
          .max(127)
          .required(t('common:form.error.required'))
          .nullable(),

        description_en: yup.string().max(500).nullable(),

        logo: yup.string().required(t('common:form.error.required')).nullable(),

        twitter: yup.string().max(255).nullable(),
        linkedin: yup.string().max(255).nullable(),
        instagram: yup.string().max(255).nullable(),
        facebook: yup.string().max(255).nullable(),
        youtube_video: yup.string().max(255).nullable(),

        email_societe: yup
          .string()
          .max(127)
          .email(t('common:form.error.email'))
          .nullable(),
        contact_revendeur_email: yup
          .string()
          .max(124)
          .email(t('common:form.error.email'))
          .nullable(),
        // stand_type: yup
        //   .string()
        //   .required(t('common:form.error.required'))
        //   .nullable(),

        collectif_type: yup.string().when('stand_type', {
          is: 'collectif',
          then: yup
            .string()
            .required(t('common:form.error.required'))
            .nullable(),
          otherwise: yup.string().nullable(),
        }),
        raison_sociale: yup
          .string()
          .max(127)
          .required(t('common:form.error.required'))
          .nullable(),
        // nom_societe: yup
        //   .string()
        //   .max(127)
        //   .required(t('common:form.error.required'))
        //   .nullable(),
        type_entreprise: yup
          .string()
          .required(t('common:form.error.required'))
          .nullable(),
        rgpd: yup
          .boolean()
          .oneOf([true], t('common:form.error.required'))
          .nullable(),
      });

  const form = useForm<iForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      ...response?.exhibitor,
      ...response?.user,
      pays_export: response?.exhibitor?.pays_export?.split(';') ?? [],
      pays_import: response?.exhibitor?.pays_import?.split(';') ?? [],
      rgpd: response?.exhibitor?.rgpd === 1,
    },
  });
  const {
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    getValues,
    formState,
  } = form;

  if (Object.keys(formState.errors).length > 0) {
    console.error('errorForm', formState.errors);
  }
  const { response: responseEvent } = useRepo(async () => {
    if (!exhibitorId) {
      return await exhibitorEventRepository.getExhibitorEvent(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(exhibitorEventId!)
      );
    } else {
      return await exhibitorEventRepository.getExhibitorEventAdmin(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parseInt(exhibitorId)
      );
    }
  });
  const [disabled, setDisabled] = useState(
    responseEvent?.exhibitorEvent.locked
  );
  useEffect(() => {
    setDisabled(responseEvent?.exhibitorEvent.locked);
  }, [responseEvent]);
  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(onSubmit),
  }));
  useEffect(() => {
    if (response?.exhibitor.id && process.env.REACT_APP_EXTERNAL_URL) {
      const u = `${process.env.REACT_APP_EXTERNAL_URL}/${response?.exhibitor.id}`;
      setExternalURL(u);
      getQrCode(u).then((url) => setQrcodeImg(url));
    }
  }, [response]);

  useEffect(() => {
    if (response) {
      reset({
        ...response?.exhibitor,
        ...response?.user,
        pays_export: response?.exhibitor?.pays_export?.split(';') ?? [],
        pays_import: response?.exhibitor?.pays_import?.split(';') ?? [],
        rgpd: response?.exhibitor?.rgpd === 1,
      });
    }
  }, [response]);
  function onSubmit(data: iForm): void {
    console.log(1123123);
    setLoadingForm(true);
    if (!response) {
      setErrorForm('Unknown error...');
      setLoadingForm(false);
      return;
    }
    Promise.all([
      userRepository
        .updateUser(data, response.user.id)
        .finally(() => setLoadingForm(false)),
      exhibitorRepository
        .updateUserExhibitor(
          {
            ...data,
            raison_sociale: data.raison_sociale.trim(),
            nom_societe: data.nom_societe?.trim(),
            pays_export: data.pays_export?.join(';'),
            pays_import: data.pays_import?.join(';'),
            rgpd: data.rgpd ? 1 : 0,
          },
          response.exhibitor.id
        )
        .then((data) => {
          setResponse((state) =>
            state
              ? {
                  exhibitor: { ...state.exhibitor, ...data.exhibitor },
                  products: state.products,
                  user: state.user,
                }
              : state
          );
        }),
    ])
      .then(() => {
        setSuccessForm(true);
        setErrorForm('');
      })
      .catch((err: Error) => {
        setErrorForm(err.message);
      })
      .finally(() => setLoadingForm(false));
  }

  const mediaList: Array<
    'media_1' | 'media_2' | 'media_3' | 'media_4' | 'media_5'
  > = ['media_1', 'media_2', 'media_3', 'media_4', 'media_5'];
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <T.Block>
        <A.Title as="h2" fontSize="24" mb="20" fw="black">
          {t('home:form.folderContact.title')}
          <A.Text as="div" fontSize="14" fw="normal" color="grey-50">
            {t('home:form.folderContact.subtitle')}
          </A.Text>
        </A.Title>
        <A.Line mb="20" />
        <T.BlockResponsive>
          <InputText
            label={t('home:form.folderContact.firstname')}
            name="firstname"
            type="text"
            control={control}
            disabled={disabled}
            required
          />
          <InputText
            label={t('home:form.folderContact.lastname')}
            name="lastname"
            type="text"
            control={control}
            disabled={disabled}
            required
          />

          <InputPhone
            label={t('home:form.folderContact.phone')}
            name="phone"
            control={control}
            placeholder="+33 1 23 45 67 88"
            disabled={disabled}
          />
          <InputPhone
            label={t('home:form.folderContact.mobile')}
            name="phone_port"
            control={control}
            placeholder="+33 6 23 45 67 88"
            disabled={disabled}
          />
          <InputText
            label={t('home:form.folderContact.email')}
            name="email"
            type="email"
            inputMode="email"
            control={control}
            disabled={disabled}
            required
          />

          {isAdmin && (
            <InputText
              label={t('home:form.folderContact.password')}
              name="password"
              type="password"
              placeholder="******"
              control={control}
              disabled={disabled}
              required
            />
          )}
        </T.BlockResponsive>
        {/* <A.Title as="h2" fontSize="24" mb="20" mt="50" fw="black">
                      Mon entreprise expose sur le Pavillon France *
                    </A.Title>
                    <A.Line mb="20" />
                    <T.BlockResponsive>
                      <InputRadio
                        name="stand_type"
                        control={control}
                        disabled={disabled}
                        required
                        radios={[
                          {
                            value: 'individuel',
                            label:
                              'Sur un stand individuel ou partagé (avec Business France)',
                          },
                          {
                            value: 'collectif',
                            label:
                              'Sur un stand collectif régional ou interprofessionnel',
                          },
                        ]}
                      />

                      <InputSelect
                        name="collectif_type"
                        control={control}
                        options={codifCollectif?.map(
                          ({ topicCode, label }) => ({
                            value: topicCode,
                            label,
                          })
                        )}
                        disabled={disabled || stand_type !== 'collectif'}
                        required
                      />
                    </T.BlockResponsive> */}

        {externalURL && (
          <>
            <T.Flex valign="center" mt="50">
              <A.Title as="h2" fontSize="24" mr="10" fw="black">
                {t('home:form.qrcode.title')}
              </A.Title>
              <A.Icon icon="eye" color="success" fontSize="14" mr="5" />
              <CustomLink
                to={externalURL}
                color="success"
                fontSize="14"
                fw="normal"
              >
                {t('home:form.qrcode.text')}
              </CustomLink>
            </T.Flex>
            <A.Line mt="20" mb="20" />
            <T.BlockResponsive>
              {qrcodeImg && (
                <T.Flex valign="center">
                  <A.Icon icon="download" fontSize="14" mr="5" />
                  <CustomLink
                    to={qrcodeImg}
                    download="qrcode.png"
                    color="grey-70"
                    fontSize="14"
                    fw="normal"
                  >
                    <span>{t('home:form.qrcode.download')}</span>
                  </CustomLink>
                  <A.Image
                    src={qrcodeImg}
                    width={100}
                    className={s.Exhibitor__qrcode}
                    ml="20"
                  />
                </T.Flex>
              )}
            </T.BlockResponsive>
          </>
        )}

        <A.Title as="h2" fontSize="24" mb="20" mt="50" fw="black">
          {t('home:form.companyInfo.title')}
          <A.Line mt="20" />
        </A.Title>
        <T.BlockResponsive>
          <InputText
            label={t('home:form.companyInfo.company')}
            name="raison_sociale"
            type="text"
            control={control}
            required
            disabled={disabled}
          />
          <InputText
            label={t('home:form.companyInfo.commercialName')}
            name="nom_societe"
            information={t('home:form.companyInfo.commercialNameInfo')}
            type="text"
            control={control}
            required
            disabled={disabled}
          />

          <InputText
            label={t('home:form.companyInfo.address')}
            name="adresse_postale"
            type="text"
            control={control}
            disabled={disabled}
            required
          />
          <InputText
            label={t('home:form.companyInfo.zipcode')}
            name="code_postal"
            type="text"
            inputMode="numeric"
            control={control}
            disabled={disabled}
            required
          />
          <InputText
            label={t('home:form.companyInfo.city')}
            name="ville"
            type="text"
            control={control}
            disabled={disabled}
            required
          />
          <InputText
            label={t('home:form.companyInfo.country')}
            name="pays"
            type="text"
            control={control}
            disabled={disabled}
            placeholder="Australia"
            required
          />
          <InputPhone
            label={t('home:form.companyInfo.phone')}
            name="tel_societe"
            control={control}
            placeholder="+33 6 23 45 67 88"
            disabled={disabled}
          />
          <InputText
            label={t('home:form.companyInfo.email')}
            name="email_societe"
            type="email"
            inputMode="email"
            control={control}
            disabled={disabled}
          />
          <InputText
            label={t('home:form.companyInfo.website')}
            name="site_web"
            type="text"
            control={control}
            disabled={disabled}
            placeholder="https://"
            required
          />
          <InputSelect
            label={t('home:form.companyInfo.companyType')}
            name="type_entreprise"
            control={control}
            options={codifTypeEntreprise?.map(({ topicCode, label }) => ({
              value: topicCode,
              label,
            }))}
            disabled={disabled}
            required
          />
          <InputText
            label={t('home:form.companyInfo.siret')}
            name="siret"
            type="text"
            control={control}
            disabled={disabled}
            required
          />

          {/* <InputNumber
                        label={t('home:form.companyInfo.domainSize')}
                        name="taille_domaine"
                        control={control}
                        disabled={disabled}
                        required
                        min={0}
                        max={100_000}
                        information={t(
                          'home:form.companyInfo.domainSizeDetail'
                        )}
                      /> */}

          {/* <InputNumber
                        label={t('home:form.companyInfo.yearProduct')}
                        name="production_annuelle"
                        control={control}
                        disabled={disabled}
                        required
                        min={0}
                        max={10_000_000}
                        information={t(
                          'home:form.companyInfo.yearProductDetail'
                        )}
                      /> */}

          {/* <InputSelect
                        label={t('home:form.companyInfo.exportPercent')}
                        name="pourcentage_export"
                        control={control}
                        options={codifPourcentage?.map(
                          ({ topicCode, label }) => ({
                            value: topicCode,
                            label,
                          })
                        )}
                        disabled={disabled}
                      /> */}

          {/* <InputAutocomplete
                        label={t('home:form.companyInfo.exportMarket')}
                        name="pays_export"
                        control={control}
                        disabled={disabled}
                        options={codifPays?.map(({ topicCode, label }) => ({
                          value: topicCode,
                          label,
                        }))}
                      />
                      <InputAutocomplete
                        label={t('home:form.companyInfo.exportMarketWish')}
                        name="pays_import"
                        control={control}
                        disabled={disabled}
                        options={codifPays?.map(({ topicCode, label }) => ({
                          value: topicCode,
                          label,
                        }))}
                      /> */}
          {/* <InputTextarea
                        label="Présentation de la société en français (500 caractères maximum)"
                        name="description_fr"
                        control={control}
                        disabled={disabled}
                        rows={5}
                        counter={500}
                        required
                      /> */}
          <InputTextarea
            label={t('home:form.companyInfo.compagnyPresentation')}
            name="description_en"
            control={control}
            disabled={disabled}
            rows={5}
            counter={500}
          />

          <InputMedias
            label={t('home:form.companyInfo.webLogo')}
            information={t('home:form.companyInfo.webLogoFormat')}
            name="logo"
            control={control}
            onInit={async (mediaId) => {
              return await mediaRepository
                .get(mediaId)
                .then(({ id, w640, filename }) => ({
                  id,
                  src: w640,
                  filename,
                }));
            }}
            onChange={async (file) => {
              const formdata = new FormData();
              formdata.append('media', file);
              return await mediaRepository
                .upload(formdata)
                .then(({ id, w640, filename }) => ({
                  id,
                  src: w640,
                  filename,
                }));
            }}
            onRemove={async (mediaId) => {
              return await mediaRepository
                .remove('logo', mediaId)
                .then((e) => console.log(e));
            }}
            onZoom={(src) => {
              setModalContent(<img src={src} style={{ maxWidth: '100%' }} />);
            }}
            disabled={disabled}
            required
          />

          <InputFile
            label={t('home:form.companyInfo.HdLogo')}
            information={t('home:form.companyInfo.HdLogoFormat')}
            name="logo_hd"
            control={control}
            onInit={async (mediaId) => {
              return await mediaRepository
                .get(mediaId)
                .then(({ filename }) => ({ filename }));
            }}
            onChange={async (file) => {
              const formdata = new FormData();
              formdata.append('file', file);
              return await mediaRepository
                .uploadFile(formdata)
                .then(({ id }) => ({ mediaId: id }));
            }}
            onRemove={async (mediaId) => {
              return await mediaRepository
                .remove('logo', mediaId)
                .then((e) => console.log(e));
            }}
            disabled={disabled}
            accept="application/pdf, image/*"
            maxFileSize={10}
          />
          <InputText
            label="Twitter"
            name="twitter"
            type="text"
            control={control}
            disabled={disabled}
          />
          <InputText
            label="Linkedin"
            name="linkedin"
            type="text"
            control={control}
            disabled={disabled}
          />
          <InputText
            label="Instagram"
            name="instagram"
            type="text"
            control={control}
            disabled={disabled}
          />
          <InputText
            label="Facebook"
            name="facebook"
            type="text"
            control={control}
            disabled={disabled}
          />
        </T.BlockResponsive>
        <A.Title as="h2" fontSize="24" mb="20" mt="50" fw="black">
          {t('home:form.galery.title')}
          <A.Line mt="20" />
        </A.Title>
        <T.BlockResponsive>
          <T.Block mb="20">
            {t('home:form.galery.photos')}
            <A.Text color="grey-50">
              {t('home:form.galery.photosDetail')}
            </A.Text>
          </T.Block>
          <T.Flex gap={1} wrap>
            {mediaList.map((name) => (
              <InputMedias
                key={name}
                name={name}
                control={control}
                onInit={async (mediaId) => {
                  return await mediaRepository
                    .get(mediaId)
                    .then(({ id, w640, filename }) => ({
                      id,
                      src: w640,
                      filename,
                    }));
                }}
                onChange={async (file) => {
                  const formdata = new FormData();
                  formdata.append('media', file);
                  return await mediaRepository
                    .upload(formdata)
                    .then(({ id, w640, filename }) => ({
                      id,
                      src: w640,
                      filename,
                    }));
                }}
                onRemove={async (mediaId) => {
                  return await mediaRepository
                    .remove(name, mediaId)
                    .then((e) => console.log(e));
                }}
                onZoom={(src) => {
                  setModalContent(
                    <img src={src} style={{ maxWidth: '100%' }} />
                  );
                }}
                disabled={disabled}
              />
            ))}
          </T.Flex>
          <InputText
            label={t('home:form.galery.videoLink')}
            name="youtube_video"
            type="text"
            control={control}
            disabled={disabled}
          />
        </T.BlockResponsive>
        {/* <A.Title as="h2" fontSize="24" mb="20" mt="50" fw="black"> */}
        {/*  {t('home:form.contact.title')}{' '} */}
        {/*  <A.Text as="span" fontSize="14" fw="normal"> */}
        {/*    {t('home:form.contact.titleDetail')} */}
        {/*  </A.Text> */}
        {/* </A.Title> */}
        {/* <A.Line mb="20" /> */}
        {/* <T.BlockResponsive> */}
        {/*  <InputText */}
        {/*    label={t('home:form.contact.firstname')} */}
        {/*    name="contact_revendeur_firstname" */}
        {/*    type="text" */}
        {/*    control={control} */}
        {/*    disabled={disabled} */}
        {/*  /> */}
        {/*  <InputText */}
        {/*    label={t('home:form.contact.lastname')} */}
        {/*    name="contact_revendeur_lastname" */}
        {/*    type="text" */}
        {/*    control={control} */}
        {/*    disabled={disabled} */}
        {/*  /> */}

        {/*  <InputPhone */}
        {/*    label={t('home:form.contact.mobile')} */}
        {/*    name="contact_revendeur_port" */}
        {/*    control={control} */}
        {/*    placeholder="+33 6 23 45 67 88" */}
        {/*    disabled={disabled} */}
        {/*  /> */}

        {/*  <InputPhone */}
        {/*    label={t('home:form.contact.phone')} */}
        {/*    name="contact_revendeur_tel" */}
        {/*    control={control} */}
        {/*    placeholder="+33 1 23 45 67 88" */}
        {/*    disabled={disabled} */}
        {/*  /> */}

        {/*  <InputText */}
        {/*    label={t('home:form.contact.email')} */}
        {/*    name="contact_revendeur_email" */}
        {/*    type="email" */}
        {/*    inputMode="email" */}
        {/*    control={control} */}
        {/*    disabled={disabled} */}
        {/*  /> */}
        {/* </T.BlockResponsive> */}
        {/* <A.Title as="h2" fontSize="24" mb="20" mt="50" fw="black"> */}
        {/*  {t('home:form.products.title')} */}
        {/*  <A.Line mt="20" /> */}
        {/* </A.Title> */}
        {/* <T.BlockResponsive pb="50"> */}
        {/*  <T.Block mb="20"> */}
        {/*    <A.Text color="grey-50"> */}
        {/*      {t('home:form.products.info')} */}
        {/*    </A.Text> */}
        {/*  </T.Block> */}

        {/*  <T.Flex gap={1} wrap> */}
        {/*    {products?.map(({ id, photo_1 }, index: number) => ( */}
        {/*      <CustomLink */}
        {/*        key={id} */}
        {/*        to={ */}
        {/*          isAdmin */}
        {/*            ? routes.productIdAdmin.to({ */}
        {/*                exhibitorId: response?.exhibitor.id ?? 0, */}
        {/*                productId: id, */}
        {/*              }) */}
        {/*            : routes.productId.to({ productId: id }) */}
        {/*        } */}
        {/*        textDecoration="none" */}
        {/*        color="grey-70" */}
        {/*        fontSize="14" */}
        {/*        mb="10" */}
        {/*      > */}
        {/*        <T.Flex */}
        {/*          valign={'center'} */}
        {/*          halign="center" */}
        {/*          borderColor="grey-30" */}
        {/*          borderWidth="1" */}
        {/*          borderRadius="10" */}
        {/*          borderStyle="solid" */}
        {/*          p="10" */}
        {/*          w="button" */}
        {/*          h="button" */}
        {/*          gap=".5" */}
        {/*          overflow="hidden" */}
        {/*        > */}
        {/*          {photo_1 ? ( */}
        {/*            <A.Image */}
        {/*              src={photo_1} */}
        {/*              position="absolute" */}
        {/*              width="100" */}
        {/*              height="100" */}
        {/*              objectFit="cover" */}
        {/*            /> */}
        {/*          ) : ( */}
        {/*            <A.Image */}
        {/*              src="/bottle.png" */}
        {/*              position="absolute" */}
        {/*              width="100" */}
        {/*              height="100" */}
        {/*              objectFit="contain" */}
        {/*            /> */}
        {/*          )} */}
        {/*        </T.Flex> */}
        {/*        <T.Flex halign={'space-between'}> */}
        {/*          {t('home:form.products.product')} {index + 1} */}
        {/*          <A.Text textDecoration="underline"> */}
        {/*            {t('home:form.products.edit')} */}
        {/*          </A.Text> */}
        {/*        </T.Flex> */}
        {/*      </CustomLink> */}
        {/*    ))} */}
        {/*    {!disabled && products && products?.length < 30 && ( */}
        {/*      <CustomLink */}
        {/*        to={ */}
        {/*          isAdmin */}
        {/*            ? routes.productAdmin.to({ */}
        {/*                exhibitorId: response?.exhibitor.id ?? 0, */}
        {/*              }) */}
        {/*            : routes.product.path */}
        {/*        } */}
        {/*        textDecoration="none" */}
        {/*        color="grey-70" */}
        {/*        fontSize="14" */}
        {/*      > */}
        {/*        <T.Flex */}
        {/*          valign={'center'} */}
        {/*          halign="center" */}
        {/*          borderColor="grey-30" */}
        {/*          borderWidth="1" */}
        {/*          borderRadius="10" */}
        {/*          borderStyle="solid" */}
        {/*          p="10" */}
        {/*          w="button" */}
        {/*          h="button" */}
        {/*          gap=".5" */}
        {/*        > */}
        {/*          <A.Icon icon="plus-circle" /> */}
        {/*          <span>{t('home:form.products.addProduct')}</span> */}
        {/*        </T.Flex> */}
        {/*      </CustomLink> */}
        {/*    )} */}
        {/*  </T.Flex> */}
        {/* </T.BlockResponsive> */}
        {isModerator ? (
          ''
        ) : (
          <T.Flex gap={1} wrap>
            {(!response?.exhibitor.locked || isAdmin) && (
              <div style={{ display: 'none' }}>
                <A.Button type="submit" loading={loadingForm}>
                  {t('home:form.buttons.save')}*
                </A.Button>
              </div>
            )}
            {!isAdmin && !isModerator ? (
              ''
            ) : (
              <div style={{ display: 'none' }}>
                <A.Button
                  color="black"
                  type="button"
                  bg="grey-light"
                  iconBefore="lock-fill"
                  loading={loadingForm}
                  onClick={async () => {
                    setValue('locked', 1);
                    handleSubmit(() => onSubmit(getValues()))();
                  }}
                  disabled={!!response?.exhibitor.locked}
                >
                  {response?.exhibitor.locked
                    ? t('home:form.buttons.locked')
                    : t('home:form.buttons.submit') + '**'}
                </A.Button>
              </div>
            )}
          </T.Flex>
        )}
      </T.Block>
    </form>
  );
});
ExhibitorForEvent.displayName = 'ExhibitorForEvent';
export default ExhibitorForEvent;
