import React, { useState, useMemo } from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { CSVLink } from 'react-csv';
import useApiContext from 'hooks/useApiContext';
import useModalContext from 'hooks/useModalContext';
import { useLocation, Outlet, Link, useNavigate } from 'react-router-dom';
import { format, isAfter } from 'date-fns';
import useRepo from 'hooks/useRepo';
import { T, O, M, A } from 'TOMA';
import s from './EventList.module.scss';

import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Switch } from 'components/Switch';
import { CustomLink } from 'components/CustomLink';
import { InputStand } from 'components/InputStand';
import Extraction from 'components/Extraction/Extraction';
import { EventRepository } from 'services/Event/Event.repository';
import routes from '../../router/routes';
import { eRoles } from '../../interfaces/Roles';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<any>();

function EventList(): JSX.Element {
  const { pathname } = useLocation();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const { codificationRepository, eventRepository, userRepository } =
    useApiContext();
  const [filterCollectif] = useState<any>();
  const [filterName, setFilterName] = useState<any>();
  const [reload, setReload] = useState<number>(0);
  const { setModalContent, setModalVisible } = useModalContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const copyToClipboardFallback = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    try {
      document.execCommand('copy');
      console.log('Fallback: Text copied to clipboard');
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textarea);
  };
  const user = userRepository.getUser();
  const isModerator = user?.role === eRoles.MODERATOR;
  const {
    response = [],
    loading,
    error,
  } = useRepo(async () => await eventRepository.getEvents(), [reload]);

  const { response: codifCollectif } = useRepo(
    async () => await codificationRepository.get('collectif')
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('remove', {
        header: '',
        enableSorting: false,
        cell: (info) => {
          const val = info.getValue();

          return val.role !== eRoles.ADMIN ? (
            <button
              key={val.eventId}
              type="button"
              className={s.ExhibitorList__remove}
              onClick={() => {
                setModalContent(
                  <div>
                    <A.Text fontSize="22" fw="bold" mb="20">
                      Êtes vous sur de vouloir supprimer cet évènement ?
                    </A.Text>
                    <T.Flex gap={2} valign="center" mb="-15">
                      <A.Button
                        bg="alert"
                        onClick={() => {
                          eventRepository.remove(val.eventId).then(() => {
                            setModalVisible(false);
                            info.row.toggleSelected();
                          });
                        }}
                      >
                        Confirmer
                      </A.Button>
                      <A.Button
                        appearance="link"
                        color="grey-80"
                        mb="15"
                        textDecoration="underline"
                        onClick={() => setModalVisible(false)}
                      >
                        Annuler
                      </A.Button>
                    </T.Flex>
                  </div>
                );
              }}
            >
              <A.Icon icon="trash" color="alert" fontSize="22" />
            </button>
          ) : null;
        },
      }),
      columnHelper.accessor('visibility', {
        header: 'Online',
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, eventId } = getValue();
          return (
            <Switch
              key={eventId}
              checked={value}
              disable={user?.role !== eRoles.ADMIN}
              onChange={async (e) =>
                await eventRepository.mergeEvent('visibility', e, eventId)
              }
            />
          );
        },
      }),
      columnHelper.accessor('name', {
        header: t('event:name_of_the_event'),
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, eventId } = getValue();

          return (
            <CustomLink
              to={routes.admEventList.path + `/${eventId}`}
              color="info"
            >
              {value}
            </CustomLink>
          );
        },
      }),
      columnHelper.accessor('date', {
        header: t('event:date'),
        enableSorting: false,
        cell: (info) => {
          const val = info.getValue();
          return (
            <div className={s.ExhibitorList__status} data-key={val}>
              {format(new Date(val), 'dd/MM/yyyy')}
            </div>
          );
        },
      }),
      columnHelper.accessor('country', {
        header: t('event:country'),
        enableSorting: false,
        cell: (info) => {
          const val = info.getValue();
          return (
            <div className={s.ExhibitorList__status} data-key={val}>
              {val}
            </div>
          );
        },
      }),
      columnHelper.accessor('creator', {
        header: t('event:creator'),
        enableSorting: false,
        enableHiding: true,
        cell: ({ getValue }) => {
          const { creator } = getValue();
          return (
            <div className={s.ExhibitorList__status}>
              {creator ? creator.firstname : 'admin'}
            </div>
          );
        },
      }),
      columnHelper.accessor('url', {
        header: '',
        enableSorting: false,
        cell: ({ getValue }) => {
          const { value, eventId } = getValue();

          return (
            <CustomLink
              to={routes.home2.path + `events/${eventId}/invite`}
              color="info"
            >
              Invite
            </CustomLink>
          );
        },
      }),
    ],
    []
  );

  const data = useMemo(
    () =>
      response
        .filter((e) => {
          const regex = new RegExp(filterName, 'gi');
          return filterName
            ? regex.test(e.name ?? '') || regex.test(e.name ?? '')
            : true;
        })
        .map((e) => {
          const collectif = '';
          return {
            remove: {
              eventId: e.id,
              role:
                e.creator && (e.creator as { role?: string }).role
                  ? (e.creator as { role: string }).role
                  : undefined,
            },
            visibility: {
              value: !!e.visibility,
              eventId: e.id,
            },
            date: e.date,
            country: e.country,
            name: {
              value: e.name,
              eventId: e.id,
            },
            url: {
              eventId: e.id,
            },
            form: {
              eventId: e.id,
            },
            creator: {
              creator: e.creator,
            },
            exhibitors: {
              eventId: e.id,
              exhibitors_count: e.exhibitors_count,
            },
          };
        }),
    [response, codifCollectif, filterCollectif, filterName]
  );

  const table = useReactTable({
    // data: data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
    data,
    columns,
    state: {
      sorting,
    },
    // pageCount: Math.ceil(data.length / pageSize) || 1,
    manualPagination: true,
    // onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
  });
  console.log(pathname, 'PATHNAME');
  const closeModal = (): any => {
    console.log('closeMdoal');
  };
  return (
    <div>
      <div
        className={pathname !== routes.admEventList.path ? s.blur : undefined}
      >
        <T.Container py="50">
          <M.Loading loading={loading} error={error} fullWiewport>
            <A.Title
              as="h1"
              fontSize="48"
              mb="30"
              fw="black"
              textAlign="center"
            >
              {t('event:list_of_events')}
            </A.Title>

            <T.Flex valign="center" bg="grey-10" p="30" pb="10" mb="40" gap={2}>
              <T.Flex valign="center" gap="2">
                <A.InputText
                  name="search"
                  type="text"
                  placeholder={t('event:events')}
                  onChange={(e) => {
                    // setPagination((state) => ({
                    //   ...state,
                    //   pageIndex: 0,
                    // }));
                    setFilterName(e);
                  }}
                />
              </T.Flex>
              <A.Button
                bg="success"
                onClick={() => {
                  navigate(routes.newEvent.path);
                }}
              >
                <A.Icon icon="plus" color="white" fontSize="22" mr="1" />
                {t('event:new_event')}
              </A.Button>
            </T.Flex>
            <T.Block mb="20">
              <table className={s.Table}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          <A.Button
                            appearance="link"
                            color="grey-80"
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                            {{
                              asc: <A.Icon icon="chevron-down" ml="5" />,
                              desc: <A.Icon icon="chevron-up" ml="5" />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </A.Button>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className={s.ExhibitorList__row}
                        data-removed={row.getIsSelected() ? 'true' : undefined}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            <div className={s.ExhibitorList__row__div}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </T.Block>
          </M.Loading>
        </T.Container>
      </div>
      {pathname !== routes.admEventList.path && (
        <>
          <div className={s.ExhibitorList__outlet}>
            <div>
              <Link
                className={s.background}
                to={routes.admEventList.path}
                onClick={() => setReload((e) => e + 1)}
              />
            </div>
            <div className={s.ExhibitorList__outlet__content}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Link
                  className={s.ExhibitorList__outlet__close}
                  to={routes.admEventList.path}
                  onClick={() => setReload((e) => e + 1)}
                >
                  <div>
                    <A.Icon icon="x-lg" fontSize="24" />
                  </div>
                </Link>
              </div>
              <Outlet />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default EventList;
