export default {
  exhibitors: {
    list_of_exhibitors: 'Liste des entreprises',
    find_an_exhibitor: 'Trouver un exposant',
    import_exhibitor_database: 'Importer base de données exposants',
    action: 'Action',
    simple_extraction: 'Extraction simple',
    active: 'Activé',
    name_of_the_company: 'Raison sociale',
    retirer: 'Retirer',
    annuler: 'Annuler',
    confirm: 'Confirmer',
    edit: 'Éditer',
    numero_de_stand: 'Numéro de stand + Hall',
    participation: 'Participation',
    nom_commercial: 'Nom commercial',
    my_company_is: 'Mon entreprise expose sur le Pavillon France',
    individual: 'Sur un stand individuel ou partagé (avec Business France)',
    sur_as_stand: 'Sur un stand collectif régional ou interprofessionnel',
  },
};
