import React from 'react';
import styles from './Breadcrumb.module.scss';
import { CustomLink } from 'components/CustomLink';
import { T, A } from '../..';

export function Breadcrumb({
  homeUrl = '/',
  breadcrumb = [],
}: {
  homeUrl: string;
  breadcrumb: Array<{ label?: string; path: string }>;
}): JSX.Element {
  return (
    <div className={styles.Breadcrumb}>
      <T.Flex valign="center" pb="50" mw="medium" m="auto">
        <CustomLink to={homeUrl} textDecoration="none" color="black">
          <A.Icon icon="house" />
        </CustomLink>
        {breadcrumb.map(({ label, path }, index) => {
          if (index === breadcrumb.length - 1) {
            return (
              <React.Fragment key={path}>
                <A.Icon icon="chevron-right" fontSize="14" ml="15" />
                <A.Text ml="15">{label}</A.Text>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={path}>
                <A.Icon icon="chevron-right" fontSize="14" ml="15" />
                <CustomLink to={path} textDecoration="none" color="black">
                  <A.Text ml="15">{label}</A.Text>
                </CustomLink>
              </React.Fragment>
            );
          }
        })}
      </T.Flex>
    </div>
  );
}
