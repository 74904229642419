import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';
import useApiContext from 'hooks/useApiContext';
import useRepo from 'hooks/useRepo';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import QRCode from 'qrcode';
import { CustomLink } from '../../components/CustomLink';
import { useTranslation } from 'react-i18next';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const getQrCode = async function (text: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    QRCode.toDataURL(
      text,
      function (error: Error | null | undefined, url: string) {
        if (error != null) reject(error);
        else resolve(url);
      }
    );
  });
};

function EventProductCard({
  product,
  exhibitorEventId,
  fireFetchProduct,
  setFireFetchProduct,
  event,
  locked,
  role,
}: any): JSX.Element {
  const { t } = useTranslation();
  const { mediaRepository, exhibitorEventRepository } = useApiContext();
  const [qrImage, setQrImage] = useState('');
  const [isPresent, setIsPresent] = useState(product.is_present);
  const [importateur, setImportateur] = useState(product.importateur);

  const { response } = useRepo(async () => {
    if (product.product_photo !== null) {
      return await mediaRepository.get(product.product_photo);
    }
    return null;
  }, [product.product_photo]);

  useEffect(() => {
    if (event?.url && product) {
      const u =
        String(event?.url) + `product/${String(product.product_id ?? '')}`;
      getQrCode(u).then((url) => setQrImage(url));
    }
  }, [event, product]);

  const removeProduct = async (): Promise<void> => {
    await exhibitorEventRepository.deleteExhibitorProductEvent(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parseInt(exhibitorEventId),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parseInt(product.product_id!)
    );
    setFireFetchProduct(parseInt(fireFetchProduct) + 1);
  };

  const productEventUrl =
    String(event?.url) + `product/${String(product.product_id ?? '')}`;

  const downaloadQr = (): void => {
    getQrCode(productEventUrl).then((url) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = `qrcode-${product.product_id}.png`; // File name for the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const onImportateur = async (value: any): Promise<void> => {
    await exhibitorEventRepository.addExhibitorProductEvent(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parseInt(exhibitorEventId),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parseInt(product.product_id),
      { importateur: value }
    );
  };

  const onIsPresent = async (value: any): Promise<void> => {
    setIsPresent(value);
    await exhibitorEventRepository.addExhibitorProductEvent(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parseInt(exhibitorEventId),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parseInt(product.product_id),
      { is_present: value ? 1 : 0 }
    );
  };
  return (
    <>
      <Paper
        sx={{
          padding: '1em',
          backgroundColor: '#EEEEEE',
          position: 'relative',
        }}
      >
        <IconButton
          aria-label="close"
          disabled={locked}
          onClick={removeProduct}
          sx={{
            width: '30px',
            height: '30px',
            position: 'absolute',
            top: -10,
            right: -10,
            background: 'white',
            color: '#666666',
            border: '1px solid #666666', // Border color set to #666666
            borderRadius: '50%',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ flex: '1 0 auto' }}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  alignItems: 'center',
                }}
              >
                <Paper
                  sx={{
                    backgroundColor: '#fff',
                    width: '100px',
                    height: '100px',
                    textAlign: 'center',
                    marign: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  elevation={3}
                >
                  {response?.w640 ? (
                    <img width={40} height={100} src={response?.w640} />
                  ) : (
                    <img src={'/O_Bottle.png'} />
                  )}
                </Paper>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: '#666666',
                  }}
                >
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                  >
                    <Typography
                      component="div"
                      sx={{ fontSize: '20px', fontWeight: '500' }}
                    >
                      {product.product_name}
                    </Typography>
                    <Typography sx={{ textAlign: 'center' }} component="div">
                      {product.product_millesime?.replace('millesime:', '')}
                    </Typography>
                    {locked || role === 'EXHIBITOR' ? (
                      ''
                    ) : (
                      <CustomLink
                        fontSize="16"
                        textDecoration="none"
                        to={`product/${product.product_id}`}
                      >
                        {t('event:edit')}
                      </CustomLink>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Switch
                      {...label}
                      checked={isPresent}
                      onChange={async (event) =>
                        await onIsPresent(event.target.checked)
                      }
                      color="default"
                    />
                    <Typography sx={{ textAlign: 'center' }} component="div">
                      The product is present in the country ...
                    </Typography>
                  </Box>
                  <TextField
                    onChange={(e) => setImportateur(e.target.value)}
                    sx={{
                      backgroundColor: 'white',
                      width: '400px',
                      marginTop: '10px',
                    }}
                    value={importateur}
                    onBlur={async (e) => await onImportateur(e.target.value)}
                    fullWidth
                    size="small"
                    label="Who is your importateur in this country ... ?"
                    id="fullWidth"
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                <Paper
                  variant="outlined"
                  sx={{
                    width: '100px',
                    height: '100px',
                    textAlign: 'center',
                    marign: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: 'black',
                  }}
                  elevation={3}
                >
                  <img
                    width={100}
                    height={100}
                    src={qrImage ?? '/qr_demo.png'}
                  />
                </Paper>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                    justifyContent: 'start',
                    textAlign: 'start',
                    color: '#666666',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.2rem',
                      cursor: 'pointer',
                    }}
                    onClick={downaloadQr}
                  >
                    <DownloadIcon />
                    <Typography sx={{ textAlign: 'center' }} component="div">
                      Download the QRcode of this wine
                    </Typography>
                  </Box>
                  <TextField
                    sx={{
                      backgroundColor: 'white',
                      width: '400px',
                      marginTop: '10px',
                    }}
                    value={productEventUrl}
                    disabled
                    fullWidth
                    size="small"
                    label="Link of the product page for this event"
                    id="fullWidth"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
}

export default EventProductCard;
