import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import routes from 'router/routes';
import { eRoles } from 'interfaces/Roles';
import Layout from 'components/Layout';
import { ExhibitorListHeader } from '../components/ExhibitorListHeader';
import Exhibitor from 'pages/Exhibitor';
import Event from 'pages/Event';
import ExhibitorList from 'pages/ExhibitorList';
import Exhibitors from 'pages/ExhibitorList/Exhibitors.page';
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import useApiContext from 'hooks/useApiContext';
import Product from 'pages/Product';
import ProductForm from 'pages/ProductForm';
import ProductList from 'pages/ProductList';
import Codif from 'pages/Codif';
import Confirmation from 'pages/Confirmation';
import ForgotPassword from 'pages/ForgotPassword';
import UpdatePassword from 'pages/UpdatePassword';
import EventList from 'pages/EventList';
import Accept from 'pages/AcceptInvite';
import UserEventList from 'pages/UserEventList';
import EventRegistration from 'pages/EventRegistration';
import FormInformation from '../pages/FormInformation';
import FormInvite from '../pages/FormInvite';
import AddExhibitor from '../pages/AddExhibitoPage';
import Users from '../pages/Users';
import UserFormPage from '../pages/Users/UserForm.page';
import NotFound from '../pages/404';
import Company from '../pages/Company';
export default function AppRoutes(): JSX.Element {
  useLocation();
  const { userRepository } = useApiContext();
  const user = userRepository.getUser();

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={routes.login.path} element={<Login />} />
        <Route path={routes.signup.path} element={<SignUp />} />
        <Route path={routes.forgotPassword.path} element={<ForgotPassword />} />
        <Route path={routes.confirmation.path} element={<Confirmation />} />
        <Route path={routes.updatePassword.path} element={<UpdatePassword />} />
        <Route path={routes.notFound.path} element={<NotFound />} />
        <Route path={routes.codif.path} element={<Codif />} />

        {user?.role === eRoles.EXHIBITOR && (
          <Route element={<Exhibitor />}>
            <Route path={routes.home.path} />
            <Route path={routes.product.path} element={<Product />} />
            <Route path={routes.productId.path} element={<Product />} />
          </Route>
        )}
        <Route path={routes.acceptInvite.path} element={<Accept />} />
        {user?.role === eRoles.EXHIBITOR && (
          <Route>
            <Route path={routes.eventlist.path} element={<UserEventList />} />
            <Route path={routes.product.path} element={<Product />} />
            <Route path={routes.products.path} element={<ProductList />} />
          </Route>
        )}
        {user?.role === eRoles.EXHIBITOR && (
          <Route
            path={routes.eventRegistration.path}
            element={<EventRegistration />}
          >
            <Route path="product/:productId" element={<ProductForm />} />
          </Route>
        )}
        {user?.role === eRoles.EXHIBITOR && (
          <Route path={routes.qrProductId.path} element={<ProductForm />} />
        )}
        {user?.role &&
          [eRoles.ADMIN, eRoles.MODERATOR].includes(user?.role) && (
            <Route path={routes.home.path} element={<Exhibitors />}>
              <Route path="exhibitor/:exhibitorId" element={<Exhibitor />} />
              <Route
                path="exhibitor/:exhibitorId/product"
                element={<Product />}
              />
              <Route
                path="exhibitor/:exhibitorId/product/:productId"
                element={<Product />}
              />
            </Route>
          )}
        {user?.role &&
          [eRoles.SUPER_ADMIN, eRoles.ADMIN].includes(user?.role) && (
            <Route path={routes.users.path} element={<Users />}>
              <Route path={routes.userEdit.path} element={<UserFormPage />} />
              <Route path={routes.newUser.path} element={<UserFormPage />} />
            </Route>
          )}
        {user?.role && [eRoles.ADMIN].includes(user?.role) && (
          <Route path={routes.profile.path} element={<Users />}>
            <Route path={routes.profileEdit.path} element={<UserFormPage />} />
            <Route path={routes.newProfile.path} element={<UserFormPage />} />
          </Route>
        )}
        {user?.role && [eRoles.ADMIN].includes(user?.role) && (
          <Route path={routes.company.path} element={<Company />} />
        )}
        {user?.role &&
          [eRoles.ADMIN, eRoles.MODERATOR].includes(user?.role) && (
            <Route element={<ExhibitorListHeader />}>
              <Route path={routes.event.path} element={<ExhibitorList />}>
                <Route
                  path="exhibitor/:exhibitorId"
                  element={<EventRegistration />}
                >
                  <Route path="product/:productId" element={<ProductForm />} />
                </Route>
                <Route
                  path="exhibitor/:exhibitorId/product"
                  element={<Product />}
                />
                <Route
                  path="exhibitor/:exhibitorId/product/:productId"
                  element={<Product />}
                />
                <Route
                  path={routes.eventExhibitors.path}
                  element={<AddExhibitor />}
                />
                <Route
                  path={routes.eventExhibitor.path}
                  element={<Exhibitor />}
                />
              </Route>

              <Route
                path={routes.formInformation.path}
                element={<FormInformation />}
              />
              <Route path={routes.editEvent.path} element={<Event />} />
            </Route>
          )}
        {user?.role &&
          [eRoles.ADMIN, eRoles.MODERATOR].includes(user?.role) && (
            <Route>
              <Route path={routes.admEventList.path} element={<EventList />}>
                <Route path={routes.newEvent.path} element={<Event />} />
                <Route
                  path={routes.inviteEvent.path}
                  element={<FormInvite />}
                />
              </Route>
            </Route>
          )}
        <Route path="/" element={<Navigate to={routes.login.path} />} />
        <Route
          path={routes.home2.path}
          element={<Navigate to={routes.login.path} />}
        />
        <Route path="*" element={<div>404</div>} />
      </Route>
    </Routes>
  );
}
