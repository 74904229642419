export default {
  header: {
    products: 'Produits',
    events: 'Évènements',
    societe: 'Société',
    exhibitor: 'Entreprises',
    users: 'Utilisateurs',
    company_settings: 'Paramètres',
    sign_out: 'Se déconnecter',
  },
  eventList: {
    question: 'Questionnaire',
    exhibitor: 'Exposants',
    events: 'Info de l’évenement',
  },
};
