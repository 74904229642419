import useApiContext from 'hooks/useApiContext';
import useRepo from 'hooks/useRepo';
import React, { useEffect, useState } from 'react';
import { ModalProvider } from 'contexts/ModalContext';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { M } from 'TOMA';
import Toma from 'TOMA/Toma';
import { ApiProvider } from './ApiContext';
import AppRouter from './router/AppRoutes';
import routes from 'router/routes';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import NotFound from 'pages/404';
import { Helmet } from 'react-helmet';
import { iCompany } from './services/Company/Company.repository';
import { HelmetProvider } from 'react-helmet-async';

function App(): JSX.Element {
  const { pathname } = useLocation();
  console.log(pathname, 'pathname');
  if (pathname === '/login') {
    window.location.href = routes.login.path;
  }
  if (pathname === '/signup') {
    window.location.href = routes.signup.path;
  }
  const { userRepository, companyRepository } = useApiContext();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<iCompany>();
  const [companyLoading, setCompanyLoading] = useState(true);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const reconnect = async () => {
      try {
        await userRepository.reconnectUser();
      } catch (error) {
        console.error('Failed to reconnect user', error);
      } finally {
        setLoading(false);
      }
    };
    reconnect();
  }, [userRepository]);

  // Company check logic
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchCompany = async () => {
      try {
        const fetchedCompany = await companyRepository.getCompanyRest();
        console.log(fetchedCompany, 'fetchedCompany');
        setCompany(fetchedCompany); // Set the company data
        setCompanyLoading(false);
      } catch (error) {
        console.error('Failed to fetch company2', error);
        setLoading(false);
      } finally {
        setCompanyLoading(false);
      }
    };
    fetchCompany().then(() => {
      if (company) {
        const metaTitle = document.querySelector('meta[property="og:title"]');
        if (metaTitle) {
          metaTitle.setAttribute('content', company.name);
        } else {
          const newMetaTitle = document.createElement('meta');
          newMetaTitle.setAttribute('property', 'og:title');
          newMetaTitle.setAttribute('content', company.name);
          document.head.appendChild(newMetaTitle);
        }
      }
    });
  }, [companyRepository]);
  if (companyLoading) {
    return <></>;
  }
  return (
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <Helmet>
          <title>{company?.name ?? 'Default Title'}</title>
          <meta name="description" content="Helmet application" />
        </Helmet>
        <M.Loading loading={loading} fullWiewport>
          <Toma />
          <ModalProvider>
            <ApiProvider>
              <AppRouter />
            </ApiProvider>
          </ModalProvider>
        </M.Loading>
      </I18nextProvider>
    </HelmetProvider>
  );
}

export default App;
