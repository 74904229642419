import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import {
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  Button,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { T, O, M, A } from 'TOMA';
import useApiContext from 'hooks/useApiContext';
import useRepo from 'hooks/useRepo';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { eTypeProduit } from 'services/Codification/Codification.type';
import { useNavigate } from 'react-router-dom';
import routes from 'router/routes';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface iProductMillesimeForm {
  name: string;
  millesime: string;
  id: number;
}

function AddNewMillesime({
  addNewMillesime,
  setAddNewMillesime,
  product,
  response,
}: any): JSX.Element {
  const { t } = useTranslation();
  const { productRepository, codificationRepository } = useApiContext();

  const [loadingForm, setLoadingForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [errorForm, setErrorForm] = useState('');

  const navigate = useNavigate();

  const schema = yup.object({
    name: yup.string().max(255).required(t('common:form.error.required')),
    millesime: yup.string().when('type_produit', {
      is: (tp: eTypeProduit) => [eTypeProduit.WINE].includes(tp),
      then: yup.string().required(t('common:form.error.required')),
      otherwise: yup.string().nullable(),
    }),
  });

  const handleClose = (): void => {
    setAddNewMillesime(false);
  };

  const form = useForm<iProductMillesimeForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { response: codifMillesime } = useRepo(
    async () => await codificationRepository.get('millesime')
  );

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = form;

  useEffect(() => {
    reset({
      name: product.name,
      id: product.id,
    });
  }, [product]);

  async function onSubmit(form: iProductMillesimeForm): Promise<void> {
    setLoadingForm(true);
    productRepository
      .copyProduct({ ...form, name: form.name.trim() }, response?.exhibitor.id)
      .then((e) => {
        if (e?.product?.id) {
          setSuccessForm(true);
          handleClose();
          navigate(routes.qrProductId.to({ productId: e?.product?.id }), {
            replace: true,
          });
        } else {
          throw new Error('Unknown error');
        }
      })
      .catch((err: Error) => {
        console.log(err);
        setErrorForm(err.message);
      })
      .finally(() => setLoadingForm(false));
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={setAddNewMillesime}
        aria-labelledby="customized-dialog-title"
        open={addNewMillesime}
        fullWidth
        maxWidth={'md'}
        sx={{
          zIndex: 99,
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {t('product:form.productInfo.new_milesime')}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <M.Loading loading={false} fullWiewport>
            <T.Block pt="50" p="20">
              <T.BlockResponsive>
                <Controller
                  name="millesime"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!errors.millesime}
                      sx={{ marginBottom: '20px' }}
                    >
                      <InputLabel>
                        {t('product:form.productInfo.millesime')}
                      </InputLabel>
                      <Select
                        {...field}
                        label={t('product:form.productInfo.millesime')}
                        value={field.value || ''}
                        onChange={(e) => field.onChange(e.target.value)}
                      >
                        {codifMillesime?.map(
                          ({ topicCode, label }) =>
                            product.related_products.some(
                              (relatedProduct: {
                                id: number;
                                millesime: string;
                              }) => relatedProduct.millesime === topicCode
                            ) === false && (
                              <MenuItem key={topicCode} value={topicCode}>
                                {label}
                              </MenuItem>
                            )
                        )}
                      </Select>
                      <Typography variant="caption" color="textSecondary">
                        {errors.millesime ? errors.millesime.message : ''}
                      </Typography>
                    </FormControl>
                  )}
                />
              </T.BlockResponsive>
            </T.Block>
          </M.Loading>
        </DialogContent>
        <DialogActions
          sx={{
            margin: '20px',
          }}
        >
          <T.Flex gap={2} halign="space-around" w="100">
            <div>
              <Button
                variant="contained"
                onClick={handleSubmit(
                  async (form: any) => await onSubmit(form)
                )}
                sx={{
                  backgroundColor: '#28AB6E',
                  color: 'white',
                  width: '350px',
                  '&:hover': {
                    backgroundColor: '#1e8d5f', // Darker shade for hover effect
                  },
                }}
              >
                {t('product:form.save')}
              </Button>
            </div>
          </T.Flex>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default AddNewMillesime;
