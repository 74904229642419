export default {
  common: {
    search: 'Choisissez',
    form: {
      addFile: 'Ajouter un fichier',
      chooseFile: 'Choisir un fichier',
      noFileSelected: 'Aucun fichier sélectionné',
      select: 'Selectionnez...',
      choose: 'Choisissez...',
      error: {
        email: 'Please enter a valid email address',
        required: 'This field is required',
        minCharacters: '{{length}} characters',
        oneLowerCase: 'one lowercase',
        oneUpperCase: 'one uppercase',
        oneNumber: 'one number',
        passwordMustContain: `The password must contain at least \${items}.`,
        passwordMustMatch: 'Passwords must match',
        and: 'and',
        fileTooLarge: 'Le fichier est trop lourd: maximun {{size}}Mo',
      },
    },
    header: {
      nav: {
        home: 'Home',
        signin: 'Sign in',
        signup: 'Sign up',
        signout: 'Sign out',
      },
    },
    subheader: {
      date: 'September 24, 2024',
      location: 'Sydney, Australia',
    },
    footer: 'Contacts',
    congratulation: 'Félicitation',
    footer_text: 'Texte de pieds de page (Texte e gras)',
    email_1: 'E-mail de contact 1 (Lien)',
    email_2: 'E-mail de contact 2 (Lien)',
  },
};
