import React, { useEffect, useState } from 'react';
import useApiContext from 'hooks/useApiContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Form, { InputText } from 'form';
import { T, O, M, A } from 'TOMA';
import { useNavigate, useLocation } from 'react-router-dom';
import { CustomLink } from 'components/CustomLink';
import { yupCustomMethods } from 'form/helpers/yupCustomMethods';
import routes from 'router/routes';
import { useTranslation } from 'react-i18next';
import useRepo from 'hooks/useRepo';

yupCustomMethods(yup);

export interface iFormLogin {
  email: string;
  password: string;
}

function Login(): JSX.Element {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const navigate = useNavigate();
  const { userRepository, eventRepository, companyRepository } =
    useApiContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { t } = useTranslation();
  const schema = yup.object({
    email: yup
      .string()
      .email(t('common:form.error.email'))
      .required(t('common:form.error.required')),
    password: yup.string().required(t('login:form.error.required')),
  });

  function handleSubmit({ email, password }: iFormLogin): void {
    setLoading(true);
    userRepository
      .login(email, password, code)
      .then(async (e) => {
        if (e.role === 'SUPER_ADMIN') {
          navigate(routes.users.to());
        } else {
          e.role === 'ADMIN' || e.role === 'MODERATOR'
            ? navigate(routes.admEventList.to(), { replace: true })
            : navigate(routes.eventlist.to(), { replace: true });
        }
      })
      .catch((err: Error) => {
        console.log(error, 'error');
        setError(err.message);
      })
      .finally(() => setLoading(false));
  }
  const { response } = useRepo(
    // eslint-disable-next-line
      async () => await companyRepository.getCompanyRest(),
    []
  );
  return (
    <M.Loading loading={false} error={null} fullWiewport>
      <T.Container py="50">
        <T.Block pb="50">
          <A.Title as="h1" fontSize="48" textAlign="center" fw="black">
            {response?.title}
          </A.Title>
        </T.Block>
        <T.Block pb="20">
          <A.Title as="h2" fontSize="32" textAlign="center" fw="black">
            {t('login:signIn')} :
          </A.Title>
        </T.Block>
        <T.Block mw="normal" mx="auto">
          <Form<iFormLogin>
            onSubmit={async (data) => handleSubmit(data)}
            resolver={yupResolver(schema)}
          >
            {({ control }) => (
              <div>
                <T.Block mb="40">
                  <InputText
                    label={t('login:email')}
                    name="email"
                    type="email"
                    inputMode="email"
                    control={control}
                    autocomplete="username"
                  />
                  <InputText
                    label={t('login:password')}
                    name="password"
                    type="password"
                    control={control}
                    autocomplete="current-password"
                  />
                  <CustomLink
                    to={routes.forgotPassword.path}
                    textAlign="end"
                    fontSize="14"
                  >
                    {t('login:forgotPassword')}
                  </CustomLink>
                </T.Block>

                <A.Alert alert="error" side="⚠️" mb="15">
                  {error}
                </A.Alert>

                <T.Flex valign="center" halign="center">
                  <A.Button type="submit" loading={loading} center>
                    {t('login:signIn')}
                  </A.Button>
                  <CustomLink
                    to={`${routes.signup.path}${code ? `?code=${code}` : ''}`}
                    mb="15"
                    ml="auto"
                    mr="auto"
                    color="black"
                    textDecoration="underline"
                  >
                    {t('login:signUp')}
                  </CustomLink>
                </T.Flex>
              </div>
            )}
          </Form>
        </T.Block>
      </T.Container>
    </M.Loading>
  );
}

export default Login;
