import React, { useEffect, useState } from 'react';
import { T, O, M, A } from 'TOMA';
import useApiContext from 'hooks/useApiContext';
import useRepo from 'hooks/useRepo';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import routes from 'router/routes';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { eTypeProduit } from 'services/Codification/Codification.type';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  TextField,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  Button,
  IconButton,
} from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface iProductCreateForm {
  name: string;
  millesime: string;
  type_produit: string;
}

function CreateProductForm({
  showNewProductForm,
  setShowNewProductForm,
}: any): JSX.Element {
  const { eventId, exhibitorEventId } = useParams();
  const { pathname } = useLocation();
  const { productRepository, codificationRepository } = useApiContext();
  const [open, setOpen] = React.useState(false);
  const [loadingForm, setLoadingForm] = React.useState(false);
  const [successForm, setSuccessForm] = React.useState(false);
  const [errorForm, setErrorForm] = React.useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schema = yup.object({
    name: yup.string().max(255).required(t('common:form.error.required')),
    type_produit: yup
      .string()
      .max(255)
      .required(t('common:form.error.required')),
    millesime: yup.string().when('type_produit', {
      is: (tp: eTypeProduit) => [eTypeProduit.WINE].includes(tp),
      then: yup.string().required(t('common:form.error.required')),
      otherwise: yup.string().nullable(),
    }),
  });

  const form = useForm<iProductCreateForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = form;
  const { response: codifMillesime } = useRepo(
    async () => await codificationRepository.get('millesime')
  );

  const { response: typeOfProduct } = useRepo(
    async () => await codificationRepository.get('type_produit')
  );

  const handleClickOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    reset();
    setShowNewProductForm(false);
  };

  const loading = false;
  const error = false;

  async function onSubmit(form: iProductCreateForm): Promise<void> {
    setLoadingForm(true);
    productRepository
      .createProduct({ ...form, name: form.name.trim() }, undefined)
      .then((e) => {
        if (e?.product?.id) {
          setSuccessForm(true);
          setErrorForm('');
          if (pathname.includes('event-registration')) {
            const path = generatePath(
              routes.eventRegistration.path + '/product/:productId',
              {
                exhibitorEventId,
                productId: e?.product?.id,
              }
            );
            navigate(path, { replace: true });
            setShowNewProductForm(false);
          } else {
            navigate(routes.qrProductId.to({ productId: e?.product?.id }), {
              replace: true,
            });
          }
        } else {
          throw new Error('Unknown error');
        }
      })
      .catch((err: Error) => {
        console.log(err);
        setErrorForm(err.message);
      })
      .finally(() => setLoadingForm(false));
  }

  const typeProduct = watch('type_produit');

  return (
    <div>
      <div>
        <T.Container py="50">
          <M.Loading loading={loading} error={error} fullWiewport>
            <T.Block
              pb="50"
              mw="medium"
              m="auto"
              // bg="grey-05"
              p="20"
              borderRadius="7"
            >
              <React.Fragment>
                <BootstrapDialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={showNewProductForm}
                  fullWidth
                >
                  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {t('home:product.new_product')}
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent dividers>
                    <Controller
                      name="type_produit"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!errors.type_produit}
                          sx={{ marginBottom: '20px' }}
                        >
                          <InputLabel>
                            {t('home:product.product_type')}
                          </InputLabel>
                          <Select
                            {...field}
                            label={t('home:product.product_type')}
                            value={field.value || ''}
                            defaultValue={''}
                            onChange={(e) => field.onChange(e.target.value)}
                          >
                            {typeOfProduct?.map(({ topicCode, label }) => (
                              <MenuItem key={topicCode} value={topicCode}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography variant="caption" color="textSecondary">
                            {errors.type_produit
                              ? errors.type_produit.message
                              : ''}
                          </Typography>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value || ''}
                          fullWidth
                          label={t('home:product.enter_name_of_the_vitange')}
                          variant="outlined"
                          error={!!errors.name}
                          helperText={errors.name ? errors.name.message : ''}
                          sx={{ marginBottom: '20px' }}
                        />
                      )}
                    />
                    {typeProduct === 'type_produit:WINE' && (
                      <Controller
                        name="millesime"
                        control={control}
                        render={({ field }) => (
                          <FormControl
                            fullWidth
                            variant="outlined"
                            error={!!errors.millesime}
                            sx={{ marginBottom: '20px' }}
                          >
                            <InputLabel>
                              {t('home:product.select_vintage_of_the_vitange')}
                            </InputLabel>
                            <Select
                              {...field}
                              label={t(
                                'home:product.select_vintage_of_the_vitange'
                              )}
                              value={field.value || ''}
                              defaultValue={''}
                              onChange={(e) => field.onChange(e.target.value)}
                            >
                              {codifMillesime?.map(({ topicCode, label }) => (
                                <MenuItem key={topicCode} value={topicCode}>
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                            <Typography variant="caption" color="textSecondary">
                              {errors.millesime ? errors.millesime.message : ''}
                            </Typography>
                          </FormControl>
                        )}
                      />
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleSubmit(
                        async (form: any) => await onSubmit(form)
                      )}
                      sx={{
                        backgroundColor: '#28AB6E',
                        color: 'white',
                        width: '100%',
                        '&:hover': {
                          backgroundColor: '#1e8d5f', // Darker shade for hover effect
                        },
                      }}
                    >
                      {t('signup:form.validate')}
                    </Button>
                  </DialogActions>
                </BootstrapDialog>
              </React.Fragment>
            </T.Block>
          </M.Loading>
        </T.Container>
      </div>
    </div>
  );
}

export default CreateProductForm;
