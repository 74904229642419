export default {
  admin: {
    title: 'List of exhibitors',
    deadline: 'Date limite',
    unlockAll: 'Unlock all',
    lockAll: 'Lock all',
    visibleAll: 'Tous visibles',
    hiddenAll: 'Tous invisibles',
    filter: 'Filter',
    simpleExtract: 'Simple extract',
    new: 'New',
    status: 'Status',
    number: 'Number',
    activate: 'Activé',
    locked: 'Verrouillé',
    visible: 'Visible',
    company: 'Raison sociale',
    commercialName: 'Nom commercial',
    year: 'Année',
    standNumber: 'Numéro de stand + Hall',
    edit: 'edit',
    removeExhibitor: "Supprimer l'exposant?",
    transmitted: 'Transmis',
    saved: 'Enregistré',
    progress: 'En cours',
    pending: 'En attente',
    confirm: 'confirmer',
    cancel: 'Annuler',
    booth: 'Stand',
    hall: 'Hall',
    list_of_users: 'Liste des utilisateurs',
    role: 'Rôle',
    number_of_authorized_exhibitors: "Nombre d'exposants autorisés",
    number_of_moderators: 'Nombre de modérateurs',
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    email: 'E-mail',
    create_a_new_moderator: 'Créer un nouveau modérateur',
    phone: 'Téléphone',
    exhibitors: 'Exposants',
    collectif: 'Collectif',
    events: 'Evènements',
    password: 'Mot de passe',
  },
};
