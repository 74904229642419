import Header from 'components/Header/Header';
import React, { useEffect, useState } from 'react';
// import { Helmet } from 'react-helmet';
import { T, O, M, A } from 'TOMA';
import { Outlet } from 'react-router-dom';
import styles from './Layout.module.scss';
import { useTranslation } from 'react-i18next';
import useApiContext from '../../hooks/useApiContext';
import { iCompany } from '../../services/Company/Company.repository';
import useRepo from '../../hooks/useRepo';
import { ToastContainer } from 'react-toastify';

export interface iSideBarContent {
  title?: string;
  text?: string;
  contact_email_1?: string;
  contact_email_2?: string;
}

function Layout(): JSX.Element {
  const { t } = useTranslation();
  const [data, setData] = useState<{
    title: string;
    footer: string;
  }>({
    title: '',
    footer: '',
  }); // Initialize state
  const { userRepository, companyRepository } = useApiContext();
  const { response, loading } = useRepo(
    // eslint-disable-next-line
      async () => await companyRepository.getCompanyRest(),
    []
  );
  useEffect(() => {
    const company = localStorage.getItem('company');
    if (response && !loading) {
      setData({
        title: response.title,
        footer: response.footer,
      });
    }
  }, [response, loading]); // Run once on mount

  return (
    <>
      {/* <Helmet></Helmet> */}
      <div className={styles.Layout}>
        <Header />
        <main className={styles.Layout__main}>
          <Outlet />
        </main>
        <footer className={styles.Layout__footer}>
          <T.Container bg="grey-05">
            <T.Block py="50">
              <A.Text color="grey-80">
                <b>{data.footer}</b>
              </A.Text>
            </T.Block>
          </T.Container>
        </footer>
        <ToastContainer stacked />
      </div>
    </>
  );
}

Layout.defaultProps = {
  title: null,
  content: null,
};

export default React.memo(Layout);
