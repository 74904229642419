export default {
  company: {
    company_informations: 'Paramètres',
    name: 'Nom',
    main_event: 'Evènement principal',
    color: 'Couleur',
    logo: 'Logo',
    right_header_logo: 'Logo de droite dans le header',
    title_of_the_plateform: 'Titre de la plateforme',
    address: 'Adresse',
    contact: 'Contact',
    phone: 'Téléphone',
    save: 'Sauvegarder',
  },
};
