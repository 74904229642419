import React, { useState } from 'react';
import { A } from '..';
import styles from './InputSelect.module.scss';
export interface iInputSelect {
  name: string;
  id?: string;
  value?: string | number;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  label?: string;
  error?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  options:
    | Array<{ value: string | number; label: string; disabled?: boolean }>
    | undefined;
}

export const InputSelect = React.forwardRef(function field(
  {
    name,
    id,
    value,
    disabled,
    required,
    placeholder,
    onChange,
    onBlur,
    label,
    error,
    options,
  }: iInputSelect,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  function isPresent(val?: string | number): boolean {
    return options?.some((option) => option.value === val) ?? false;
  }

  return (
    <div className={styles.InputSelect}>
      {label && (
        <label
          htmlFor={id ?? name}
          className={styles.InputSelect__label}
          data-error={!!error}
          data-disabled={disabled}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </label>
      )}
      <div className={styles.InputSelect__group}>
        <select
          name={name}
          placeholder={placeholder}
          value={isPresent(value) ? value : ''}
          disabled={disabled}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          data-error={!!error}
          data-placeholder={!isPresent(value)}
          required={required}
          id={id ?? name}
          className={styles.InputSelect__field}
        >
          <option value="">{placeholder ?? 'Choisissez...'}</option>
          {options?.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputSelect.defaultProps = {
  id: undefined,
  value: undefined,
  label: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
};
