import React, { useState } from 'react';
import { T, O, M, A } from 'TOMA';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import s from './FormInformationQuestion.module.scss';
import useApiContext from '../../hooks/useApiContext';
import useModalContext from 'hooks/useModalContext';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import { boolean, number, string } from 'yup';

interface Props {
  id: number;
  answers: string[];
  question: string;
  required: boolean;
  eventId: string | undefined;
  onDelete?: (questionId: number) => void;
}

interface FormValues {
  answers: string[];
  question: string;
  required: boolean;
  eventId: string | undefined;
  onDelete: () => any;
}

function FormInformationQuestion({
  id,
  question,
  answers,
  required,
  eventId,
  onDelete,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deleted, setDeleted] = useState(false);
  const [qId, setQID] = useState(id);
  const [isRequired, setIsRequired] = useState(required);
  const { eventRepository, userRepository } = useApiContext();
  const { setModalContent, setModalVisible } = useModalContext();
  const { control, handleSubmit, setValue, getValues, reset } =
    useForm<FormValues>({
      defaultValues: {
        question,
        answers,
        eventId,
      },
    });
  const [isVisible, setIsVisible] = useState(false);
  const onSubmit = (data: FormValues): any => {
    eventRepository.updateFormAnswers(qId, data).then((e) => {
      if (id === 0) {
        setQID(e.id);
      }
      setIsVisible(false);
    });
  };
  const user = userRepository.getUser();
  const toggleVisibility = (): any => {
    setIsVisible(!isVisible); // Toggle the visibility state
  };
  return (
    <T.Block style={{ display: deleted ? 'none' : 'block' }}>
      <T.Flex valign="baseline" halign="space-between" gap="2">
        <T.Flex mt="20">
          <T.Block>
            <button className={s.buttonQuestion} onClick={toggleVisibility}>
              {isVisible ? <CloseIcon /> : <CreateIcon />}
            </button>
          </T.Block>
          <T.Block w="small">
            <A.Text fontSize="24">{getValues().question}</A.Text>
          </T.Block>
          <T.Block>
            <A.Text mt="3">{t('home:formInformation.required_answer')}</A.Text>
          </T.Block>
          <T.Block ml="10">
            <A.InputSwitch
              disabled={user?.role !== 'ADMIN'}
              checked={isRequired}
              onChange={() => {
                setIsRequired(!isRequired);
                setValue('required', !isRequired);
                handleSubmit(() => onSubmit(getValues()))();
              }}
            />
          </T.Block>
        </T.Flex>

        <button
          className={s.button}
          disabled={user?.role !== 'ADMIN'}
          onClick={() => {
            setModalContent(
              <div>
                <A.Text fontSize="22" fw="bold" mb="20">
                  {t('home:formInformation.are_you_sure')}
                </A.Text>
                <T.Flex gap={2} valign="center" mb="-15">
                  <A.Button
                    bg="alert"
                    onClick={() => {
                      if (id !== 0) {
                        eventRepository.removeQuestion(id).then(() => {
                          setModalVisible(false);
                          setDeleted(true);
                          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        });
                      } else {
                        console.log('id is 0');
                        if (onDelete) {
                          onDelete(0);
                          setModalVisible(false);
                        }
                      }
                    }}
                  >
                    {t('home:formInformation.confirm')}
                  </A.Button>
                  <A.Button
                    appearance="link"
                    color="grey-80"
                    mb="15"
                    textDecoration="underline"
                    onClick={() => setModalVisible(false)}
                  >
                    {t('home:formInformation.cancel')}
                  </A.Button>
                </T.Flex>
              </div>
            );
          }}
        >
          <A.Icon icon="trash" fontSize="18" color="alert"></A.Icon>
        </button>
      </T.Flex>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ '& .MuiTextField-root': { m: 1 } }}
        className={isVisible ? s.visibleContent : s.hiddenContent}
        noValidate
      >
        <div>
          <Controller
            name="question"
            control={control}
            defaultValue={question} // Set default value
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id="question"
                label="Question" // Internationalization example
                variant="outlined"
                disabled={user?.role !== 'ADMIN'}
                fullWidth
                error={!!error} // Display error state if there is an error
                helperText={error ? error.message : ''} // Display error message if available
              />
            )}
          />
          {answers.map((item, index) => (
            <T.Flex key={index}>
              <Controller
                name={`answers.${index}`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id={`answer-${index}`}
                    disabled={user?.role !== 'ADMIN'}
                    label={`${t('home:formInformation.answer')} ${index + 1}`}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </T.Flex>
          ))}
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            size="medium"
            disabled={user?.role !== 'ADMIN'}
          >
            {t('home:formInformation.save')}
          </Button>
        </div>
      </Box>
    </T.Block>
  );
}

export default FormInformationQuestion;
