export default {
  forgotPassword: {
    title: 'Changement de mot de passe',
    email: 'Email',
    validate: 'Envoyer',
    success: 'Vous allez recevoir un email pour changer votre mot de passe.',
    new_password: 'Nouveau mot de passe*',
    validate_password: 'Confirmation du mot de passe*',
    password_must_match: 'Les mots de passe doivent être identiques',
    password_changed: 'Votre mot de passe a été changé.',
    send: 'Envoyer',
  },
};
