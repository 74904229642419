export default {
  company: {
    company_informations: 'Company settings',
    name: 'Name',
    main_event: 'Main event',
    color: 'Color',
    logo: 'Logo',
    right_header_logo: 'Right header logo',
    title_of_the_plateform: 'Title of the plateform',
    address: 'Address',
    contact: 'Contact',
    phone: 'Phone',
    save: 'Save',
  },
};
