import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { T, O, M, A } from 'TOMA';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Chip,
} from '@mui/material';
import {
  eClassification,
  eTypeProduit,
} from 'services/Codification/Codification.type';
import useApiContext from 'hooks/useApiContext';
import Paper from '@mui/material/Paper';

import { InputMedias } from 'form';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function TechnicalForm({
  showTechnicalForm,
  setShowTechnicalForm,
  control,
  errors,
  handleSubmit,
  onSubmit,
  loadingPdf,
  codifAppellation,
  typeProduit,
  codifCepage,
  codifAlcool,
  logo,
}: any): JSX.Element {
  const { t } = useTranslation();
  const { mediaRepository } = useApiContext();
  const [companyLogo, setCompanyLogo] = React.useState('');
  const handleClickOpen = (): void => {
    setShowTechnicalForm(true);
  };
  const handleClose = (): void => {
    setShowTechnicalForm(false);
  };

  React.useEffect(() => {
    if (logo !== '') {
      mediaRepository
        .get(logo)
        .then(({ id, w640, filename }) => setCompanyLogo(w640));
    } else {
      setCompanyLogo('');
    }
  }, [logo]);

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={setShowTechnicalForm}
        aria-labelledby="customized-dialog-title"
        open={showTechnicalForm}
        fullWidth
        maxWidth={'md'}
        sx={{
          zIndex: 99,
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Fiche technique du millésime
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <M.Loading loading={loadingPdf} fullWiewport>
            <T.Block p="20">
              <T.BlockResponsive>
                <Paper
                  sx={{
                    width: '100px',
                    height: '100px',
                    marginBottom: '20px',
                    padding: '10px',
                  }}
                  elevation={3}
                >
                  <img width={80} height={80} src={companyLogo} />
                </Paper>
                <Controller
                  name="slogan"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value || ''}
                      fullWidth
                      label={t('Slogan')}
                      variant="outlined"
                      error={!!errors.slogan}
                      helperText={errors.slogan ? errors.slogan.message : ''}
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      disabled
                      value={field.value || ''}
                      fullWidth
                      label={t('Nom du vin')}
                      variant="outlined"
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="millesime"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      disabled
                      value={field.value?.replace('millesime:', '') || ''}
                      fullWidth
                      label={t('Millésime')}
                      variant="outlined"
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="description_en"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      disabled
                      value={field.value || ''}
                      fullWidth
                      label={t('Description du vin')}
                      multiline
                      rows={3}
                      variant="outlined"
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="appellation"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!errors.appellation}
                      sx={{ marginBottom: '20px' }}
                    >
                      <InputLabel
                        sx={{
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        }}
                      >
                        {t('product:form.productInfo.appellation')}
                      </InputLabel>
                      <Select
                        {...field}
                        label={t('product:form.productInfo.appellation')}
                        value={field.value || ''}
                        onChange={(e) => field.onChange(e.target.value)}
                        disabled
                      >
                        {codifAppellation
                          ?.filter(({ filter }: any) => {
                            if (typeProduit === eTypeProduit.WINE && !filter) {
                              return true;
                            }
                            return filter === typeProduit;
                          })
                          ?.map(({ topicCode, label }: any) => (
                            <MenuItem key={topicCode} value={topicCode}>
                              {label}
                            </MenuItem>
                          ))}
                      </Select>
                      <Typography variant="caption" color="textSecondary">
                        {errors.appellation ? errors.appellation.message : ''}
                      </Typography>
                    </FormControl>
                  )}
                />
                <Controller
                  name="cepage"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={!!errors.cepage}
                      sx={{ marginBottom: '20px' }}
                    >
                      <InputLabel
                        sx={{
                          color: 'rgba(0, 0, 0, 0.38) !important',
                        }}
                      >
                        {t('product:form.productInfo.cepage')}
                      </InputLabel>
                      <Select
                        {...field}
                        multiple
                        label={t('product:form.productInfo.cepage')}
                        value={field.value || ''}
                        onChange={(e) => field.onChange(e.target.value)}
                        disabled
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.map((value: any) => {
                              const selectedLabel = codifCepage?.find(
                                (item: any) => item.topicCode === value
                              )?.label;
                              return (
                                <Chip
                                  key={value}
                                  label={selectedLabel ?? value}
                                />
                              );
                            })}
                          </Box>
                        )}
                      >
                        {codifCepage?.map(({ topicCode, label }: any) => (
                          <MenuItem key={topicCode} value={topicCode}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="caption" color="textSecondary">
                        {errors.cepage ? errors.cepage.message : ''}
                      </Typography>
                    </FormControl>
                  )}
                />
                <Controller
                  name="cepage_percentage"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      disabled
                      value={field.value || ''}
                      fullWidth
                      label={t('% Cépage')}
                      variant="outlined"
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="selection"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value || ''}
                      size="small"
                      fullWidth
                      label={t('Sélection')}
                      variant="outlined"
                      error={!!errors.selection}
                      helperText={
                        errors.selection ? errors.selection.message : ''
                      }
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="terroir"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={field.value || ''}
                      fullWidth
                      label={t('Terroir (450 caractères max.)')}
                      variant="outlined"
                      error={!!errors.terroir}
                      helperText={errors.terroir ? errors.terroir.message : ''}
                      multiline
                      rows={3}
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="culture"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value || ''}
                      fullWidth
                      label={t('Culture (450 caractères max.)')}
                      variant="outlined"
                      error={!!errors.culture}
                      helperText={errors.culture ? errors.culture.message : ''}
                      multiline
                      rows={3}
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="finification"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value || ''}
                      fullWidth
                      label={t('Finification (450 caractères max.)')}
                      variant="outlined"
                      error={!!errors.finification}
                      helperText={
                        errors.finification ? errors.finification.message : ''
                      }
                      multiline
                      rows={3}
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="rendement"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value || ''}
                      fullWidth
                      label={t('Rendement')}
                      variant="outlined"
                      error={!!errors.rendement}
                      helperText={
                        errors.rendement ? errors.rendement.message : ''
                      }
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="sulfites"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value || ''}
                      fullWidth
                      label={t('Sulfites')}
                      variant="outlined"
                      error={!!errors.sulfites}
                      helperText={
                        errors.sulfites ? errors.sulfites.message : ''
                      }
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="production"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value || ''}
                      fullWidth
                      label={t('Production')}
                      variant="outlined"
                      error={!!errors.production}
                      helperText={
                        errors.production ? errors.production.message : ''
                      }
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="provenance"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value || ''}
                      fullWidth
                      label={t('Provenance')}
                      variant="outlined"
                      error={!!errors.provenance}
                      helperText={
                        errors.provenance ? errors.provenance.message : ''
                      }
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="conservation"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value || ''}
                      fullWidth
                      label={t('Conservation')}
                      variant="outlined"
                      error={!!errors.conservation}
                      helperText={
                        errors.conservation ? errors.conservation.message : ''
                      }
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
                <Controller
                  name="accord_mets_et_vins"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      value={field.value || ''}
                      fullWidth
                      label={t('Accord mets et vins (450 caractères max.)')}
                      variant="outlined"
                      error={!!errors.accord_mets_et_vins}
                      helperText={
                        errors.accord_mets_et_vins
                          ? errors.accord_mets_et_vins.message
                          : ''
                      }
                      multiline
                      rows={3}
                      sx={{ marginBottom: '20px' }}
                    />
                  )}
                />
              </T.BlockResponsive>
            </T.Block>
          </M.Loading>
        </DialogContent>
        <DialogActions
          sx={{
            margin: '20px',
          }}
        >
          <T.Flex gap={2} halign="space-around" w="100">
            <div>
              <Button
                variant="contained"
                onClick={handleSubmit((form: any) => onSubmit(form, false))}
                sx={{
                  backgroundColor: '#28AB6E',
                  color: 'white',
                  width: '350px',
                  '&:hover': {
                    backgroundColor: '#1e8d5f', // Darker shade for hover effect
                  },
                }}
              >
                {t('save')}
              </Button>
            </div>
            <div>
              <Button
                variant="outlined"
                onClick={handleSubmit((form: any) => onSubmit(form, true))}
                sx={{
                  borderColor: '#28AB6E',
                  color: '#28AB6E',
                  width: '350px',
                  '&:hover': {
                    borderColor: '#1e8d5f', // Darker shade for hover effect
                    color: '#1e8d5f', // Darker shade for hover effect
                  },
                }}
              >
                Download Pdf
              </Button>
            </div>
          </T.Flex>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default TechnicalForm;
