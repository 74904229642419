import React, { useEffect, useState } from 'react';
import { T, O, M, A } from 'TOMA';
import { InputSelect, InputMedias, InputFile } from 'form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import s from './ProductForm.module.scss';
import { ReactComponent as EditSvg } from '../../assets/svg/edit.svg';
import useApiContext from 'hooks/useApiContext';
import useRepo from 'hooks/useRepo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  eClassification,
  eTypeProduit,
} from 'services/Codification/Codification.type';
import useModalContext from 'hooks/useModalContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import routes from 'router/routes';
import { eRoles } from 'interfaces/Roles';
import {
  TextField,
  Select,
  MenuItem,
  Typography,
  Box,
  InputLabel,
  FormControl,
  Chip,
  Button,
  Switch,
  FormControlLabel,
  Tabs,
  Tab,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';

import TechnicalForm from './TechnicalForm.page';
import AddNewMillesime from './AddNewMillesime.page';
import DeleteIcon from '@mui/icons-material/Delete';

interface iProductForm {
  removed: number;
  producer: string;
  trade_name: string;
  name: string;
  description_fr: string;
  description_en: string;
  photo_1: string;
  logo_on_label: boolean;

  type_produit: string;
  type_vin: string;
  couleur: string;
  region: string;
  classification: string;
  appellation: string;
  denomination_igp: string;
  denomination: string;
  type_culture: string;
  millesime: string;
  cepage: string[];
  format: string;
  price: string;
  alcool: string;
  cepage_percentage: string;

  type_vendange: string;
  allergene: string;
  arome: string;
  type_of_grape: string;

  technical_file: string;
  notes: string;

  slogan: string;
  selection: string;
  terroir: string;
  culture: string;
  finification: string;
  rendement: string;
  sulfites: string;
  production: string;
  provenance: string;
  conservation: string;
  accord_mets_et_vins: string;
}

function ProductForm(): JSX.Element {
  console.log('ProductForm');
  // prettier-ignore
  const { t } = useTranslation();
  const { setModalContent, setModalVisible } = useModalContext();
  const { exhibitorId, productId, exhibitorEventId } = useParams();
  console.log(exhibitorId, productId, 'exhibitorId, productId');
  const {
    userRepository,
    exhibitorRepository,
    mediaRepository,
    productRepository,
    codificationRepository,
  } = useApiContext();
  const user = userRepository.getUser();
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [errorForm, setErrorForm] = useState<string>();
  const [showTechnicalForm, setShowTechnicalForm] = useState(false);
  const [editingName, setEditingName] = useState(false);

  const {
    response,
    loading: loadingExhib,
    error: errorExhib,
  } = useRepo(
    async () => await exhibitorRepository.getUserExhibitor(exhibitorId),
    [exhibitorId, productId]
  );

  const {
    response: { product } = { product: null },
    loading: loadingProduct,
    error: errorProduct,
  } = useRepo(
    async () => await productRepository.getProduct(productId),
    [productId]
  );

  const { response: productList } = useRepo(
    async () => await productRepository.getProducts()
  );

  const isAdmin = user?.role === eRoles.ADMIN;

  const { response: codifTypeProduit } = useRepo(
    async () => await codificationRepository.get('type_produit')
  );
  const { response: codifTypeVin } = useRepo(
    async () => await codificationRepository.get('type_vin')
  );
  const { response: codifCouleur } = useRepo(
    async () => await codificationRepository.get('couleur')
  );
  const { response: codifTypeCulture } = useRepo(
    async () => await codificationRepository.get('type_culture')
  );
  const { response: codifRegion } = useRepo(
    async () => await codificationRepository.get('region')
  );
  const { response: codifClassification } = useRepo(
    async () => await codificationRepository.get('classification')
  );
  const { response: codifAppellation } = useRepo(
    async () => await codificationRepository.get('appellation')
  );
  const { response: codifDenominationIgp } = useRepo(
    async () => await codificationRepository.get('denomination_igp')
  );
  const { response: codifDenomination } = useRepo(
    async () => await codificationRepository.get('denomination')
  );
  const { response: codifMillesime } = useRepo(
    async () => await codificationRepository.get('millesime')
  );
  const { response: codifCepage } = useRepo(
    async () => await codificationRepository.get('cepage')
  );
  const { response: typeOfGrape } = useRepo(
    async () => await codificationRepository.get('type_of_grape')
  );

  const { response: codifFormat } = useRepo(
    async () => await codificationRepository.get('format')
  );

  const { response: codifPrices } = useRepo(
    async () => await codificationRepository.get('prices')
  );

  // const { response: codifAlcool } = useRepo(
  //   async () => await codificationRepository.get('alcool')
  // );
  const { pathname } = useLocation();
  const navigate = useNavigate();
  async function onSubmit(form: iProductForm, pdf: any = false): Promise<void> {
    setLoadingForm(true);
    console.log(form, 'FROMPRODUCT');
    console.log('NAVIGATEW');
    if (productId) {
      return await productRepository
        .updateProduct(
          { ...form, name: form.name.trim(), cepage: form.cepage.join(';') },
          response?.exhibitor.id,
          productId
        )
        .then((e) => {
          setSuccessForm(true);
          setErrorForm('');
          if (pdf) {
            downloadPdf();
          } else {
            setShowTechnicalForm(false);
          }
          if (pathname.includes('event-registration')) {
            navigate(
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `/businessfrance/eventlist/event-registration/${exhibitorEventId}?newProductId=${e?.product?.id}`
            );
          }
        })
        .catch((err: Error) => {
          console.log(err);
          setErrorForm(err.message);
        })
        .finally(() => {
          setLoadingForm(false);
        });
    } else {
      productRepository
        .newProduct(
          { ...form, name: form.name.trim(), cepage: form.cepage.join(';') },
          response?.exhibitor.id
        )
        .then((e) => {
          if (e?.product?.id) {
            setSuccessForm(true);
            setErrorForm('');
            if (pdf) {
              downloadPdf();
            } else {
              setShowTechnicalForm(false);
            }
            if (isAdmin) {
              navigate(
                routes.productIdAdmin.to({
                  exhibitorId: response?.exhibitor.id,
                  productId: e?.product?.id,
                }),
                {
                  replace: true,
                }
              );
            } else {
              if (pathname.includes('event-registration')) {
                navigate(
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  `/businessfrance/eventlist/event-registration/${exhibitorEventId}?newProductId=${e?.product?.id}`
                );
              }
              setLoadingForm(false);
              navigate(routes.productId.to({ productId: e?.product?.id }), {
                replace: true,
              });
            }
          } else {
            throw new Error('Unknown error');
          }
        })
        .catch((err: Error) => {
          console.log(err);
          setErrorForm(err.message);
        })
        .finally(() => setLoadingForm(false));
    }
  }

  const schema = yup.object({
    producer: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .max(255)
      .required(t('common:form.error.required')),
    name: yup.string().max(255).required(t('common:form.error.required')),
    description_en: yup
      .string()
      .max(500)
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .required(t('common:form.error.required')),
    photo_1: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .required(t('common:form.error.required')),

    type_produit: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .required(t('common:form.error.required')),
    type_vin: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .when('type_produit', {
        is: eTypeProduit.WINE,
        then: yup.string().required(t('common:form.error.required')),
        otherwise: yup.string().nullable(),
      }),
    couleur: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .when('type_produit', {
        is: (e: eTypeProduit) => [eTypeProduit.WINE].includes(e),
        then: yup.string().required(t('common:form.error.required')),
        otherwise: yup.string().nullable(),
      }),
    region: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .required(t('common:form.error.required')),
    classification: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .required(t('common:form.error.required')),
    appellation: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .when(['type_produit', 'classification'], {
        is: (tp: eTypeProduit, c: eClassification) =>
          [eTypeProduit.WINE].includes(tp) && eClassification.AOC_AOP_PDO === c,
        then: yup.string().required(t('common:form.error.required')).nullable(),
        otherwise: yup.string().nullable(),
      }),
    denomination_igp: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .when(['type_produit', 'classification'], {
        is: (tp: eTypeProduit, c: eClassification) =>
          [eTypeProduit.WINE].includes(tp) && eClassification.IGP_PGI === c,
        then: yup.string().required(t('common:form.error.required')).nullable(),
        otherwise: yup.string().nullable(),
      }),
    denomination: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .when(['type_produit', 'classification'], {
        is: (tp: eTypeProduit, c: eClassification) =>
          [
            eTypeProduit.CIDER,
            eTypeProduit.SPIRITS,
            eTypeProduit.WINE,
            eTypeProduit.BEER,
          ].includes(tp) &&
          [eClassification.VIN_DE_FRANCE, eClassification.AUTRES_VINS].includes(
            c
          ),
        then: yup.string().required('This field is required').nullable(),
        otherwise: yup.string().nullable(),
      }),
    type_culture: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .when('type_produit', {
        is: (tp: eTypeProduit) => [eTypeProduit.WINE].includes(tp),
        then: yup.string().required(t('common:form.error.required')),
        otherwise: yup.string().nullable(),
      }),
    millesime: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .when('type_produit', {
        is: (tp: eTypeProduit) => [eTypeProduit.WINE].includes(tp),
        then: yup.string().required(t('common:form.error.required')),
        otherwise: yup.string().nullable(),
      }),
    cepage: yup
      .array()
      .of(yup.string())
      .when('type_produit', {
        is: (tp: eTypeProduit) => [eTypeProduit.WINE].includes(tp),
        then: yup
          .array()
          .required()
          .min(1, t('common:form.error.required'))
          .required(t('common:form.error.required')),
        otherwise: yup.array().nullable(),
      }),
    price: yup
      .string()
      .transform((value, originalValue) =>
        originalValue === null ? undefined : value
      )
      .required(t('common:form.error.required')),

    type_vendange: yup.string().max(255).nullable(),
    allergene: yup.string().max(255).nullable(),
    arome: yup.string().max(255).nullable(),
    terroir: yup.string().nullable().max(500),
    culture: yup.string().nullable().max(500),
    finification: yup.string().nullable().max(500),
    accord_mets_et_vins: yup.string().nullable().max(500),
    // technical_file: yup
    //   .string()
    //   .required(t('common:form.error.required'))
    //   .nullable(),
  });

  const disabled = !isAdmin && !!response?.exhibitor.locked;

  const form = useForm<iProductForm>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const searchForm = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    getValues,
    formState: { errors },
  } = form;
  useEffect(() => {
    if (!product) {
      reset({
        name: '',
        region: 'region:VALLEE_DE_LA_LOIRE',
        cepage: [],
      });
    } else {
      reset({ ...product, cepage: product?.cepage?.split(';') || [] });
    }
  }, [product]);

  const typeProduit = watch('type_produit');
  const classification = watch('classification');

  useEffect(() => {
    if (!product) return;
    if (typeProduit === eTypeProduit.BEER) {
      setValue('type_vin', '');
      setValue('appellation', '');
      setValue('denomination_igp', '');
      setValue('type_culture', '');
      setValue('millesime', '');
      setValue('cepage', []);

      const denomination = getValues('denomination');
      if (
        denomination &&
        codifDenomination &&
        !codifDenomination?.some(({ topicCode }) => topicCode === denomination)
      ) {
        setValue('denomination', '');
      }
    }
    if (typeProduit === eTypeProduit.CIDER) {
      setValue('type_vin', '');
      setValue('couleur', '');
      setValue('denomination_igp', '');

      const denomination = getValues('denomination');
      if (
        denomination &&
        codifDenomination &&
        !codifDenomination?.some(({ topicCode }) => topicCode === denomination)
      ) {
        setValue('denomination', '');
      }

      const appellation = getValues('appellation');
      if (
        appellation &&
        codifAppellation &&
        !codifAppellation?.some(({ topicCode }) => topicCode === appellation)
      ) {
        setValue('appellation', '');
      }
    }
    if (typeProduit === eTypeProduit.SPIRITS) {
      setValue('type_vin', '');
      setValue('couleur', '');
      setValue('denomination_igp', '');

      const denomination = getValues('denomination');
      if (
        denomination &&
        codifDenomination &&
        !codifDenomination?.some(({ topicCode }) => topicCode === denomination)
      ) {
        setValue('denomination', '');
      }
      const appellation = getValues('appellation');
      if (
        appellation &&
        codifAppellation &&
        !codifAppellation?.some(({ topicCode }) => topicCode === appellation)
      ) {
        setValue('appellation', '');
      }
    }
    if (typeProduit === eTypeProduit.WINE) {
      const denomination = getValues('denomination');
      if (
        denomination &&
        codifDenomination &&
        !codifDenomination?.some(({ topicCode }) => topicCode === denomination)
      ) {
        setValue('denomination', '');
      }
      const appellation = getValues('appellation');
      if (
        appellation &&
        codifAppellation &&
        !codifAppellation?.some(({ topicCode }) => topicCode === appellation)
      ) {
        setValue('appellation', '');
      }
    }
  }, [typeProduit]);

  useEffect(() => {
    if (!product) return;
    if (classification === eClassification.AOC_AOP_PDO) {
      setValue('denomination_igp', '');
      setValue('denomination', '');
    }
    if (classification === eClassification.IGP_PGI) {
      setValue('appellation', '');
      setValue('denomination', '');
    }
    if (classification === eClassification.VIN_DE_FRANCE) {
      setValue('appellation', '');
      setValue('denomination_igp', '');

      const denomination = getValues('denomination');
      if (
        denomination &&
        codifDenomination &&
        !codifDenomination?.some(({ topicCode }) => topicCode === denomination)
      ) {
        setValue('denomination', '');
      }
    }
    if (classification === eClassification.AUTRES_VINS) {
      setValue('appellation', '');
      setValue('denomination_igp', '');

      const denomination = getValues('denomination');

      if (
        denomination &&
        codifDenomination &&
        !codifDenomination?.some(({ topicCode }) => topicCode === denomination)
      ) {
        setValue('denomination', '');
      }
    }
  }, [classification]);

  useEffect(() => {
    if (!product) return;
    if (classification === eClassification.AOC_AOP_PDO) {
      setValue('denomination_igp', '');
    }
    if (classification === eClassification.IGP_PGI) {
      setValue('appellation', '');
    }
  }, [classification]);

  const indexProduct = response?.products?.findIndex(
    (e) => e.id === product?.id
  );

  function resetForm(): void {
    reset({ ...product, cepage: product?.cepage?.split(';') ?? [] });
  }

  async function downloadPdf(): Promise<void> {
    setLoadingPdf(true);
    try {
      const blob: Blob = await productRepository.downloadPdf(productId);
      const url: string = window.URL.createObjectURL(new Blob([blob]));
      const link: HTMLAnchorElement = document.createElement('a');
      const fileName: string = `product-${String(productId)}.pdf`;
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error('Failed to download PDF', error);
    }
    setLoadingPdf(false);
  }

  const products = [
    {
      id: 1,
      name: 'test wine',
    },
    {
      id: 2,
      name: 'another wine',
    },
  ];

  const handleProductNavigation = (
    event: React.SyntheticEvent,
    newValue: string
  ): any => {
    navigate(routes.qrProductId.to({ productId: newValue }), {
      replace: true,
    });
    return true;
  };

  const [addNewMillesime, setAddNewMillesime] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState<any>('');

  const handleDeleteClick = (item: any): void => {
    setConfirmDelete(true);
    setDeleteProduct(item);
  };

  const handleCancelDelete = (): void => {
    setConfirmDelete(false);
    setDeleteProduct('');
  };

  const handleConfirmDelete = async (): Promise<void> => {
    await productRepository
      .deleteProduct(deleteProduct)
      .then((e) => {
        setSuccessForm(true);
        setErrorForm('');
        setConfirmDelete(false);
        setDeleteProduct('');
        if (e.next) {
          navigate(routes.qrProductId.to({ productId: e.next }), {
            replace: true,
          });
        } else {
          navigate(routes.products.path, {
            replace: true,
          });
        }
      })
      .catch((err: Error) => {
        console.log(err);
        setErrorForm(err.message);
      })
      .finally(() => setLoadingForm(false));
  };

  const isWine = typeProduit === 'type_produit:WINE';
  const filteredColors = codifCouleur?.filter(({ filter }) =>
    filter?.split(';').includes(typeProduit)
  );

  return (
    <T.Container py="20">
      <M.Loading loading={loadingProduct} error={errorProduct} fullWiewport>
        <T.Container>
          <T.Flex
            m="auto"
            bg="grey-10"
            p="20"
            borderRadius="7"
            mb="20"
            valign="center"
            gap="2"
          >
            <a
              style={{
                cursor: 'pointer',
              }}
              onClick={() =>
                navigate(routes.products.path, {
                  replace: true,
                })
              }
            >
              <i className="bi bi-house"></i>
            </a>
            <T.Block mb="-15" w="25">
              <InputSelect
                label={''}
                placeholder={t('home:product.change_vintage')}
                name="select_product"
                control={searchForm.control}
                onChange={(e) => {
                  navigate(routes.qrProductId.to({ productId: e }), {
                    replace: true,
                  });
                }}
                options={productList?.products?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              />
            </T.Block>
          </T.Flex>
          <T.Flex mt="30" pl="20" direction="column">
            <T.Flex p="10" gap="2" valign="center">
              {editingName ? (
                <>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value || ''}
                        fullWidth
                        label={t('product:form.productInfo.title')}
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ''}
                        sx={{ marginBottom: '20px' }}
                      />
                    )}
                  />
                  <IconButton onClick={() => setEditingName(false)}>
                    <SaveIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <div className={s.CreateProduct_title}>
                        {field.value || ''}
                      </div>
                    )}
                  />
                  <div
                    onClick={() => setEditingName(true)}
                    className={s.CreateProduct_icon_container}
                  >
                    <EditSvg />
                  </div>
                </>
              )}
            </T.Flex>
            {isWine && (
              <T.Flex className={s.yearListContainer}>
                <T.Block className={s.yearListContainerScroll}>
                  <T.Flex gap={1} w="auto">
                    <Box>
                      <Tabs
                        value={product?.id}
                        onChange={handleProductNavigation}
                        sx={{
                          '& .MuiTab-root': {
                            color: 'rgba(40, 171, 110)', // Default text color
                          },
                          '& .Mui-selected': {
                            color: 'rgb(40, 171, 110) !important', // Active tab text color
                          },
                          '& .MuiTabs-indicator': {
                            backgroundColor: 'rgb(40, 171, 110)', // Indicator color
                          },
                        }}
                      >
                        {product?.related_products.map((item: any, index) => {
                          return (
                            <Tab
                              key={item.id}
                              value={item.id}
                              label={
                                <div
                                  key={item.id}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                  }}
                                >
                                  {product?.id === item.id && (
                                    <IconButton
                                      sx={{
                                        color: 'rgb(40, 171, 110) !important',
                                      }}
                                      onClick={() => handleDeleteClick(item.id)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                  <div>
                                    {item.millesime?.replace('millesime:', '')}
                                  </div>
                                </div>
                              }
                            />
                          );
                        })}
                      </Tabs>
                    </Box>
                    <IconButton
                      onClick={() => setAddNewMillesime(true)}
                      sx={{
                        marginLeft: '0', // Push the icon to the far right if needed
                        width: '60px',
                      }}
                    >
                      <AddIcon sx={{ color: 'rgb(40, 171, 110)' }} />
                    </IconButton>
                  </T.Flex>
                </T.Block>
              </T.Flex>
            )}
          </T.Flex>
          <T.Block pt="50" p="20">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={s.productForm}
              noValidate
            >
              <T.Block>
                <T.BlockResponsive>
                  <Controller
                    name="producer"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value || ''}
                        fullWidth
                        label={t('home:product.producer_name')}
                        variant="outlined"
                        error={!!errors.producer}
                        helperText={
                          errors.producer ? errors.producer.message : ''
                        }
                        sx={{ marginBottom: '20px' }}
                      />
                    )}
                  />

                  <Controller
                    name="trade_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value || ''}
                        fullWidth
                        label={t('home:product.commercial_name_of_the_vintage')}
                        variant="outlined"
                        error={!!errors.trade_name}
                        helperText={
                          errors.trade_name ? errors.trade_name.message : ''
                        }
                        sx={{ marginBottom: '20px' }}
                      />
                    )}
                  />

                  <Controller
                    name="description_fr"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value || ''}
                        fullWidth
                        label={t('home:product.description_of_the_product')}
                        variant="outlined"
                        error={!!errors.description_fr}
                        helperText={
                          errors.description_fr
                            ? errors.description_fr.message
                            : ''
                        }
                        multiline
                        rows={4}
                        sx={{ marginBottom: '20px' }}
                      />
                    )}
                  />

                  <Controller
                    name="description_en"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value || ''}
                        fullWidth
                        label={t('home:product.product_description_in_english')}
                        variant="outlined"
                        error={!!errors.description_en}
                        helperText={
                          errors.description_en
                            ? errors.description_en.message
                            : ''
                        }
                        multiline
                        rows={4}
                        sx={{ marginBottom: '20px' }}
                      />
                    )}
                  />

                  {/* <Controller */}
                  {/*  name="logo_on_label" */}
                  {/*  control={control} */}
                  {/*  render={({ field }) => ( */}
                  {/*    <FormControlLabel */}
                  {/*      control={ */}
                  {/*        <Switch {...field} checked={field.value ?? false} /> */}
                  {/*      } */}
                  {/*      label={t('home:product.logo_on_label')} */}
                  {/*      labelPlacement="end" // This places the label on the right and switch on the left */}
                  {/*    /> */}
                  {/*  )} */}
                  {/* /> */}

                  <T.Flex gap={1} mt="10">
                    <InputMedias
                      addFileLabel={t('home:product.pics_of_product')}
                      name="photo_1"
                      control={control}
                      onInit={async (mediaId) => {
                        return await mediaRepository
                          .get(mediaId)
                          .then(({ id, w640, filename }) => ({
                            id,
                            src: w640,
                            filename,
                          }));
                      }}
                      onChange={async (file) => {
                        const formdata = new FormData();
                        formdata.append('media', file);
                        return await mediaRepository
                          .upload(formdata)
                          .then(({ id, w640, filename }) => ({
                            id,
                            src: w640,
                            filename,
                          }));
                      }}
                      onRemove={async (mediaId) => {
                        return await mediaRepository.remove('photo_1', mediaId);
                      }}
                      onZoom={(src) => {
                        setModalContent(
                          <img src={src} style={{ maxWidth: '100%' }} />
                        );
                      }}
                      disabled={disabled}
                      required
                    />
                  </T.Flex>
                </T.BlockResponsive>
              </T.Block>
              <T.Block pt="50" p="20">
                <A.Title as="h2" fontSize="24" mb="20" fw="black">
                  {t('home:product.cuvee_information')}
                </A.Title>
                <A.Line mb="20" />
                <T.BlockResponsive>
                  <Controller
                    name="type_produit"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={!!errors.type_produit}
                        sx={{ marginBottom: '20px' }}
                      >
                        <InputLabel>
                          {t('home:product.product_type')}
                        </InputLabel>
                        <Select
                          {...field}
                          label={t('home:product.product_type')}
                          value={field.value || ''}
                          defaultValue={typeProduit}
                          onChange={(e) => field.onChange(e.target.value)}
                          disabled={true}
                        >
                          {codifTypeProduit?.map(({ topicCode, label }) => (
                            <MenuItem key={topicCode} value={topicCode}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography variant="caption" color="textSecondary">
                          {errors.type_produit
                            ? errors.type_produit.message
                            : ''}
                        </Typography>
                      </FormControl>
                    )}
                  />
                  {isWine && (
                    <Controller
                      name="type_vin"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!errors.type_vin}
                          sx={{ marginBottom: '20px' }}
                        >
                          <InputLabel>
                            {t('home:product.type_of_wines')}
                          </InputLabel>
                          <Select
                            {...field}
                            label={t('home:product.type_of_wines')}
                            value={isWine ? field.value || '' : ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={!isWine}
                          >
                            {codifTypeVin?.map(({ topicCode, label }) => (
                              <MenuItem key={topicCode} value={topicCode}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography variant="caption" color="textSecondary">
                            {errors.type_vin ? errors.type_vin.message : ''}
                          </Typography>
                        </FormControl>
                      )}
                    />
                  )}
                  {filteredColors && filteredColors?.length > 0 && (
                    <Controller
                      name="couleur"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!errors.couleur}
                          sx={{ marginBottom: '20px' }}
                        >
                          <InputLabel>{t('home:product.color')}</InputLabel>
                          <Select
                            {...field}
                            label={t('home:product.color')}
                            value={field.value || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                          >
                            {codifCouleur
                              ?.filter(({ filter }) => {
                                return filter?.split(';').includes(typeProduit);
                              })
                              ?.map(({ topicCode, label }) => (
                                <MenuItem key={topicCode} value={topicCode}>
                                  {label}
                                </MenuItem>
                              ))}
                          </Select>
                          <Typography variant="caption" color="textSecondary">
                            {errors.couleur ? errors.couleur.message : ''}
                          </Typography>
                        </FormControl>
                      )}
                    />
                  )}

                  <Controller
                    name="region"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={!!errors.region}
                        sx={{ marginBottom: '20px' }}
                      >
                        <InputLabel>{t('home:product.region')}</InputLabel>
                        <Select
                          {...field}
                          label={t('home:product.region')}
                          value={field.value || ''}
                          onChange={(e) => field.onChange(e.target.value)}
                        >
                          {codifRegion?.map(({ topicCode, label }) => (
                            <MenuItem key={topicCode} value={topicCode}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography variant="caption" color="textSecondary">
                          {errors.region ? errors.region.message : ''}
                        </Typography>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="classification"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={!!errors.classification}
                        sx={{ marginBottom: '20px' }}
                      >
                        <InputLabel>
                          {t('home:product.classification')}
                        </InputLabel>
                        <Select
                          {...field}
                          label={t('home:product.classification')}
                          value={field.value || ''}
                          onChange={(e) => field.onChange(e.target.value)}
                          disabled={disabled}
                        >
                          {codifClassification
                            ?.filter(({ filter }) => {
                              if (typeProduit === eTypeProduit.BEER) {
                                return filter
                                  ?.split(';')
                                  .includes(eTypeProduit.BEER);
                              }
                              if (typeProduit === eTypeProduit.CIDER) {
                                return filter
                                  ?.split(';')
                                  .includes(eTypeProduit.CIDER);
                              }
                              if (typeProduit === eTypeProduit.SPIRITS) {
                                return filter
                                  ?.split(';')
                                  .includes(eTypeProduit.SPIRITS);
                              }
                              return true;
                            })
                            ?.map(({ topicCode, label }) => (
                              <MenuItem key={topicCode} value={topicCode}>
                                {label}
                              </MenuItem>
                            ))}
                        </Select>
                        <Typography variant="caption" color="textSecondary">
                          {errors.classification
                            ? errors.classification.message
                            : ''}
                        </Typography>
                      </FormControl>
                    )}
                  />

                  {[eClassification.AOC_AOP_PDO].includes(
                    classification as eClassification
                  ) && (
                    <Controller
                      name="appellation"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!errors.appellation}
                          sx={{ marginBottom: '20px' }}
                        >
                          <InputLabel>
                            {t('product:form.productInfo.appellation')}
                          </InputLabel>
                          <Select
                            {...field}
                            label={t('product:form.productInfo.appellation')}
                            value={field.value || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                          >
                            {codifAppellation
                              ?.filter(({ filter }) => {
                                if (
                                  typeProduit === eTypeProduit.WINE &&
                                  !filter
                                ) {
                                  return true;
                                }
                                return filter === typeProduit;
                              })
                              ?.map(({ topicCode, label }) => (
                                <MenuItem key={topicCode} value={topicCode}>
                                  {label}
                                </MenuItem>
                              ))}
                          </Select>
                          <Typography variant="caption" color="textSecondary">
                            {errors.appellation
                              ? errors.appellation.message
                              : ''}
                          </Typography>
                        </FormControl>
                      )}
                    />
                  )}

                  {[eClassification.IGP_PGI].includes(
                    classification as eClassification
                  ) && (
                    <Controller
                      name="denomination_igp"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!errors.denomination_igp}
                          sx={{ marginBottom: '20px' }}
                        >
                          <InputLabel>
                            {t('product:form.productInfo.denominationIgp')}
                          </InputLabel>
                          <Select
                            {...field}
                            label={t(
                              'product:form.productInfo.denominationIgp'
                            )}
                            value={field.value || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                          >
                            {codifDenominationIgp?.map(
                              ({ topicCode, label }) => (
                                <MenuItem key={topicCode} value={topicCode}>
                                  {label}
                                </MenuItem>
                              )
                            )}
                          </Select>
                          <Typography variant="caption" color="textSecondary">
                            {errors.denomination_igp
                              ? errors.denomination_igp.message
                              : ''}
                          </Typography>
                        </FormControl>
                      )}
                    />
                  )}

                  {[
                    eClassification.VIN_DE_FRANCE,
                    eClassification.AUTRES_VINS,
                  ].includes(classification as eClassification) && (
                    <Controller
                      name="denomination"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!errors.denomination_igp}
                          sx={{ marginBottom: '20px' }}
                        >
                          <InputLabel>
                            {t(
                              'Dénomination autre : VDF / autre vins / Spiritueux'
                            )}
                          </InputLabel>
                          <Select
                            {...field}
                            label={t(
                              'Dénomination autre : VDF / autre vins / Spiritueux'
                            )}
                            value={field.value || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                          >
                            {codifDenomination
                              ?.filter(({ filter }) => {
                                if (typeProduit === eTypeProduit.WINE) {
                                  return filter === classification;
                                }
                                return filter === typeProduit;
                              })
                              ?.map(({ topicCode, label }) => (
                                <MenuItem key={topicCode} value={topicCode}>
                                  {label}
                                </MenuItem>
                              ))}
                          </Select>
                          <Typography variant="caption" color="textSecondary">
                            {errors.denomination_igp
                              ? errors.denomination_igp.message
                              : ''}
                          </Typography>
                        </FormControl>
                      )}
                    />
                  )}
                  {eTypeProduit.WINE === typeProduit && (
                    <Controller
                      name="type_culture"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!errors.type_culture}
                          sx={{ marginBottom: '20px' }}
                        >
                          <InputLabel>
                            {t('home:product.type_of_culture')}
                          </InputLabel>
                          <Select
                            {...field}
                            label={t('home:product.type_of_culture')}
                            value={field.value || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={
                              disabled || eTypeProduit.WINE !== typeProduit
                            }
                          >
                            {codifTypeCulture?.map(({ topicCode, label }) => (
                              <MenuItem key={topicCode} value={topicCode}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography variant="caption" color="textSecondary">
                            {errors.type_culture
                              ? errors.type_culture.message
                              : ''}
                          </Typography>
                        </FormControl>
                      )}
                    />
                  )}

                  {eTypeProduit.WINE === typeProduit && (
                    <Controller
                      name="cepage"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!errors.cepage}
                          sx={{ marginBottom: '20px' }}
                        >
                          <InputLabel>
                            {t('home:product.grape_variety')}
                          </InputLabel>
                          <Select
                            {...field}
                            multiple
                            label={t('home:product.grape_variety')}
                            value={field.value || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={
                              disabled || eTypeProduit.WINE !== typeProduit
                            }
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => {
                                  const selectedLabel = codifCepage?.find(
                                    (item) => item.topicCode === value
                                  )?.label;
                                  return (
                                    <Chip
                                      key={value}
                                      label={selectedLabel ?? value}
                                    />
                                  );
                                })}
                              </Box>
                            )}
                          >
                            {codifCepage?.map(({ topicCode, label }) => (
                              <MenuItem key={topicCode} value={topicCode}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography variant="caption" color="textSecondary">
                            {errors.cepage ? errors.cepage.message : ''}
                          </Typography>
                        </FormControl>
                      )}
                    />
                  )}
                  {eTypeProduit.WINE === typeProduit && (
                    <Controller
                      name="type_of_grape"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={!!errors.type_of_grape}
                          sx={{ marginBottom: '20px' }}
                        >
                          <InputLabel>
                            {t('home:product.type_of_grape')}
                          </InputLabel>
                          <Select
                            {...field}
                            label={t('home:product.type_of_grape')}
                            value={field.value || ''}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={
                              disabled || eTypeProduit.WINE !== typeProduit
                            }
                          >
                            <MenuItem value="">---</MenuItem>
                            {typeOfGrape?.map(({ topicCode, label }) => (
                              <MenuItem key={topicCode} value={topicCode}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography variant="caption" color="textSecondary">
                            {errors.type_of_grape
                              ? errors.type_of_grape.message
                              : ''}
                          </Typography>
                        </FormControl>
                      )}
                    />
                  )}
                  {/* {eTypeProduit.WINE === typeProduit && ( */}
                  {/*  <Controller */}
                  {/*    name="cepage_percentage" */}
                  {/*    control={control} */}
                  {/*    render={({ field }) => ( */}
                  {/*      <TextField */}
                  {/*        {...field} */}
                  {/*        value={field.value || ''} */}
                  {/*        fullWidth */}
                  {/*        label={t('home:product.perntage_grape_variety')} */}
                  {/*        variant="outlined" */}
                  {/*        error={!!errors.cepage_percentage} */}
                  {/*        helperText={ */}
                  {/*          errors.cepage_percentage */}
                  {/*            ? errors.cepage_percentage.message */}
                  {/*            : '' */}
                  {/*        } */}
                  {/*        sx={{ marginBottom: '20px' }} */}
                  {/*      /> */}
                  {/*    )} */}
                  {/*  /> */}
                  {/* )} */}
                  <Controller
                    name="alcool"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={!!errors.format}
                        sx={{ marginBottom: '20px' }}
                      >
                        <InputLabel>
                          {t('home:product.percentage_alcohol')}
                        </InputLabel>
                        <Select
                          {...field}
                          label={t('home:product.format')}
                          value={field.value || ''}
                          onChange={(e) => field.onChange(e.target.value)}
                        >
                          <MenuItem value="no alcohol">No Alcohol</MenuItem>

                          {Array.from({ length: 50 }, (_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography variant="caption" color="textSecondary">
                          {errors.format ? errors.format.message : ''}
                        </Typography>
                      </FormControl>
                    )}
                  />
                  {/* <Controller */}
                  {/*  name="alcool" */}
                  {/*  control={control} */}
                  {/*  defaultValue="" */}
                  {/*  render={({ field }) => ( */}
                  {/*    <TextField */}
                  {/*      {...field} */}
                  {/*      value={field.value || ''} */}
                  {/*      fullWidth */}
                  {/*      label={t('home:product.percentage_alcohol')} */}
                  {/*      variant="outlined" */}
                  {/*      error={!!errors.alcool} */}
                  {/*      helperText={errors.alcool ? errors.alcool.message : ''} */}
                  {/*      sx={{ marginBottom: '20px' }} */}
                  {/*    /> */}
                  {/*  )} */}
                  {/* /> */}

                  <Controller
                    name="allergene"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value || ''}
                        fullWidth
                        label={t('home:product.allergen')}
                        variant="outlined"
                        error={!!errors.allergene}
                        helperText={
                          errors.allergene ? errors.allergene.message : ''
                        }
                        sx={{ marginBottom: '20px' }}
                        disabled={disabled}
                      />
                    )}
                  />

                  <Controller
                    name="arome"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        value={field.value || ''}
                        fullWidth
                        label={t('home:product.flavors')}
                        variant="outlined"
                        error={!!errors.arome}
                        helperText={errors.arome ? errors.arome.message : ''}
                        sx={{ marginBottom: '20px' }}
                        disabled={disabled}
                      />
                    )}
                  />

                  <Controller
                    name="format"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={!!errors.format}
                        sx={{ marginBottom: '20px' }}
                      >
                        <InputLabel>{t('home:product.format')}</InputLabel>
                        <Select
                          {...field}
                          label={t('home:product.format')}
                          value={field.value || ''}
                          onChange={(e) => field.onChange(e.target.value)}
                        >
                          {codifFormat
                            ?.filter(({ filter }) => {
                              if (typeProduit === eTypeProduit.BEER) {
                                return filter
                                  ?.split(';')
                                  .includes(eTypeProduit.BEER);
                              }
                              if (typeProduit === eTypeProduit.CIDER) {
                                return filter
                                  ?.split(';')
                                  .includes(eTypeProduit.CIDER);
                              }
                              if (typeProduit === eTypeProduit.SPIRITS) {
                                return filter
                                  ?.split(';')
                                  .includes(eTypeProduit.SPIRITS);
                              }
                              return true;
                            })
                            ?.map(({ topicCode, label }) => (
                              <MenuItem key={topicCode} value={topicCode}>
                                {label}
                              </MenuItem>
                            ))}
                        </Select>
                        <Typography variant="caption" color="textSecondary">
                          {errors.format ? errors.format.message : ''}
                        </Typography>
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="price"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={!!errors.price}
                        sx={{ marginBottom: '20px' }}
                      >
                        <InputLabel>{t('home:product.price_range')}</InputLabel>
                        <Select
                          {...field}
                          label={t('home:product.price_range')}
                          value={field.value || ''}
                          onChange={(e) => field.onChange(e.target.value)}
                        >
                          {codifPrices?.map(({ topicCode, label }) => (
                            <MenuItem key={topicCode} value={topicCode}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography variant="caption" color="textSecondary">
                          {errors.price ? errors.price.message : ''}
                        </Typography>
                      </FormControl>
                    )}
                  />

                  <InputFile
                    label={t('home:product.technical_sheet')}
                    download={true}
                    // information={t('home:form.companyInfo.HdLogoFormat')}
                    name="technical_file"
                    control={control}
                    onInit={async (mediaId) => {
                      console.log('mediaId', mediaId);
                      return await mediaRepository
                        .get(mediaId)
                        .then(({ filename, file }) => ({
                          filename,
                          src: file,
                        }));
                    }}
                    onChange={async (file) => {
                      const formdata = new FormData();
                      formdata.append('file', file);
                      return await mediaRepository
                        .uploadFile(formdata)
                        .then(({ id, src }) => ({
                          mediaId: id,
                          src,
                        }));
                    }}
                    onRemove={async (mediaId) => {
                      return await mediaRepository
                        .remove('logo', mediaId)
                        .then((e) => console.log(e));
                    }}
                    disabled={disabled}
                    accept="application/pdf"
                    maxFileSize={10}
                  />

                  {/* <Controller */}
                  {/*  name="notes" */}
                  {/*  control={control} */}
                  {/*  defaultValue="" */}
                  {/*  render={({ field }) => ( */}
                  {/*    <TextField */}
                  {/*      {...field} */}
                  {/*      value={field.value || ''} */}
                  {/*      fullWidth */}
                  {/*      label={t('home:product.tasting_note')} */}
                  {/*      variant="outlined" */}
                  {/*      error={!!errors.notes} */}
                  {/*      helperText={errors.notes ? errors.notes.message : ''} */}
                  {/*      multiline */}
                  {/*      rows={4} */}
                  {/*      sx={{ marginBottom: '20px', marginTop: '20px' }} */}
                  {/*    /> */}
                  {/*  )} */}
                  {/* /> */}
                </T.BlockResponsive>
                <T.BlockResponsive>
                  {/* Alert Success */}
                  <A.Alert alert="success" side="🎉" mb="15">
                    {successForm && t('product:form.success')}
                  </A.Alert>

                  {/* Alert fail */}
                  <A.Alert alert="error" side="⚠️" mb="15">
                    {errorForm && <A.InnerHTML html={errorForm} />}
                  </A.Alert>

                  {/* Button */}
                  {!disabled && (
                    <T.Flex gap={2} halign="center" mt="20">
                      <div>
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => {
                            handleSubmit(
                              async () => await onSubmit(getValues(), false)
                            )();
                          }}
                          sx={{
                            backgroundColor: '#28AB6E',
                            color: 'white',
                            width: '350px',
                            '&:hover': {
                              backgroundColor: '#1e8d5f', // Darker shade for hover effect
                            },
                          }}
                        >
                          {t('product:form.save')}
                        </Button>
                      </div>
                      <div>
                        {/* <Button */}
                        {/*  variant="outlined" */}
                        {/*  sx={{ */}
                        {/*    borderColor: '#28AB6E', */}
                        {/*    color: '#28AB6E', */}
                        {/*    width: '350px', */}
                        {/*    '&:hover': { */}
                        {/*      borderColor: '#1e8d5f', // Darker shade for hover effect */}
                        {/*      color: '#1e8d5f', // Darker shade for hover effect */}
                        {/*    }, */}
                        {/*  }} */}
                        {/*  onClick={() => { */}
                        {/*    setShowTechnicalForm(true); */}
                        {/*  }} */}
                        {/* > */}
                        {/*  {t('home:product.edit_technical_sheet')} */}
                        {/* </Button> */}
                        <TechnicalForm
                          showTechnicalForm={showTechnicalForm}
                          setShowTechnicalForm={setShowTechnicalForm}
                          control={control}
                          errors={errors}
                          handleSubmit={handleSubmit}
                          onSubmit={onSubmit}
                          loadingPdf={loadingPdf}
                          codifAppellation={codifAppellation}
                          typeProduit={typeProduit}
                          codifCepage={codifCepage}
                          logo={response?.exhibitor.logo}
                        />
                        <AddNewMillesime
                          addNewMillesime={addNewMillesime}
                          setAddNewMillesime={setAddNewMillesime}
                          product={product}
                          response={response}
                        />
                      </div>
                      <div>
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => handleDeleteClick(product?.id)}
                          sx={{
                            backgroundColor: '#CF2A2A',
                            color: 'white',
                            width: '350px',
                            '&:hover': {
                              backgroundColor: '#CF2A2A', // Darker shade for hover effect
                            },
                          }}
                        >
                          {t('product:form.remove')}
                        </Button>
                      </div>
                    </T.Flex>
                  )}
                </T.BlockResponsive>
              </T.Block>
            </form>
          </T.Block>
        </T.Container>
        <Dialog open={confirmDelete} onClose={handleCancelDelete}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </M.Loading>
    </T.Container>
  );
}

export default ProductForm;
