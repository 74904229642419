import i18next from 'i18next';

import common_en from './i18n/en/common.js';
import login_en from './i18n/en/login.js';
import signup_en from './i18n/en/signup.js';
import home_en from './i18n/en/home.js';
import product_en from './i18n/en/product.js';
import admin_en from './i18n/en/admin.js';
import forgotPassword_en from './i18n/en/forgotPassword.js';
import header_en from './i18n/en/header.js';
import event_en from './i18n/en/event.js';
import company_en from './i18n/en/company.js';
import users_en from './i18n/en/users.js';
import exhibitors_en from './i18n/en/exhibitors.js';

import common_fr from './i18n/fr/common.js';
import login_fr from './i18n/fr/login.js';
import signup_fr from './i18n/fr/signup.js';
import home_fr from './i18n/fr/home.js';
import product_fr from './i18n/fr/product.js';
import admin_fr from './i18n/fr/admin.js';
import forgotPassword_fr from './i18n/fr/forgotPassword.js';
import header_fr from './i18n/fr/header.js';
import NotFound from 'pages/404';
import event_fr from './i18n/fr/event.js';
import company_fr from './i18n/fr/company.js';
import users_fr from './i18n/fr/users.js';
import exhibitors_fr from './i18n/fr/exhibitors.js';

const storedLanguage = localStorage.getItem('appLanguage');

i18next.init({
  interpolation: { escapeValue: false },
  lng: storedLanguage ?? 'fr',
  resources: {
    en: {
      ...common_en,
      ...login_en,
      ...signup_en,
      ...home_en,
      ...product_en,
      ...admin_en,
      ...forgotPassword_en,
      ...header_en,
      ...event_en,
      ...company_en,
      ...users_en,
      ...exhibitors_en,
    },
    fr: {
      ...common_fr,
      ...login_fr,
      ...signup_fr,
      ...home_fr,
      ...product_fr,
      ...admin_fr,
      ...forgotPassword_fr,
      ...header_fr,
      ...event_fr,
      ...company_fr,
      ...users_fr,
      ...exhibitors_fr,
    },
  },
});

export default i18next;
