import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { T, O, M, A } from 'TOMA';
import useApiContext from 'hooks/useApiContext';
import routes from '../../router/routes';
import { CustomLink } from '../../components/CustomLink';
import { useTranslation } from 'react-i18next';

function Accept(): JSX.Element {
  const location = useLocation();
  const { code } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const invitationCode = queryParams.get('invitation_code');
  const navigate = useNavigate();
  if (typeof code === 'string') {
    console.log(code, 'code');
    localStorage.setItem('invitation_code', code);
  }
  const { t } = useTranslation();
  const { eventRepository, userRepository } = useApiContext();
  const user = userRepository.getUser();
  const data = user ? eventRepository.acceptInvite(code) : '';
  console.log(user, 'data', 'user');
  if (!user) {
    window.location.href = routes.login.path;
  } else {
    if (user?.role === 'EXHIBITOR') {
      window.location.href = routes.eventlist.path;
    } else {
      window.location.href = routes.admEventList.path;
    }
  }
  return (
    <div>
      <T.Container py="50">
        <h1>{t('common:congratulation')}</h1>
      </T.Container>
    </div>
  );
}

export default Accept;
