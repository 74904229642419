export default {
  home: {
    title: 'Data registration',
    deadline: 'Registration deadline:',
    form: {
      success: 'Form submitted successfully!',
      folderContact: {
        title: 'Contact for this file',
        subtitle: 'Information not visible on communication materials',
        firstname: 'First name',
        lastname: 'Last name',
        phone: 'Contact phone',
        mobile: 'Contact mobile',
        email: 'Contact email / login',
        password: 'Password',
      },
      qrcode: {
        title: 'QRCode company profile',
        text: 'View your company profile online',
        download: 'Download the QRCode',
      },
      companyInfo: {
        title: 'Company information',
        company: 'Company name',
        commercialName: 'Trade name',
        commercialNameInfo:
          'As they will appear on the different communications media',
        address: 'Address',
        zipcode: 'Post code',
        city: 'City',
        country: 'Country',
        phone: 'General phone',
        email: 'General company email',
        website: 'Website',
        companyType: 'Type of company',
        siret: 'ABN',
        domainSize: 'Domain size',
        domainSizeDetail: '(in ha between 0 and 100,000)',
        yearProduct: 'Annual production',
        yearProductDetail: '(in number of bottles between 0 and 10,000,000)',
        exportPercent: 'Export percentage',
        exportMarket: 'Your export markets (multiple choices possible)',
        exportMarketWish:
          'Export development wishes? (multiple choices possible)',
        compagnyPresentation:
          'Company presentation in english (500 characters maximum)',
        webLogo: 'Web company logo',
        webLogoFormat: '(jpg, png, 5MB maximum)',
        HdLogo: 'HD Logo',
        HdLogoFormat: '(pdf, eps, ai: 10MB maximum)',
      },
      galery: {
        title: 'Gallery',
        photos: 'Company photos',
        photosDetail: '(5 items, jpg, png, 5MB maximum)',
        videoLink: 'Company Youtube video link',
      },
      contact: {
        title: 'Contact',
        titleDetail: '(Information visible on communication materials)',
        firstname: 'First name',
        lastname: 'Last name',
        mobile: 'Mobile phone',
        phone: 'Phone',
        email: 'Email',
      },
      products: {
        title: 'Your selection of products',
        info: 'Possibility to present up to 30 references',
        addProduct: 'Add a product',
        product: 'Product',
        edit: 'Edit',
        checkbox:
          'The personal information collected is processed by the event organizer. They are necessary for the organization of the event or to send you any documents and/or services. The organizer may use this data to offer you products and/or services useful for the development of your activity. To exercise your rights or if you do not wish to obtain proposals from the organizer concerning its actions, services, events, we invite you to send them an e-mail. To find out more: Personal data protection charter.',
      },
      buttons: {
        save: 'Save',
        locked: 'Locked',
        submit: 'Save and submit',
      },
      asterix: {
        '*': 'You can reconnect and sequence the information entry',
        '**': 'Your entry is finalized, elements can no longer be modified.',
      },
    },
    formInformation: {
      required_answer: 'Mandatory response',
      add_new_question: 'Add a new question',
      answer: 'Answer',
      save: 'Save',
      are_you_sure: 'Are you sure you want to delete this question?',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    product: {
      catalog_of_your_product: 'Catalog of your products',
      new_product: 'New product',
      search: 'Search',
      type_of_product: 'Type of product',
      enter_name_of_the_vitange: 'Enter the name of the product',
      select_vintage_of_the_vitange: 'Select the vintage of this product',
      technical_sheet: 'PDF file, 5mo max',
      edit: 'Edit',
      create_new_product: 'Create a new product',
      change_vintage: 'Change product',
      product_information: 'Product information',
      producer_name: 'Producer name',
      commercial_name_of_the_vintage: 'Commercial name of the product',
      description_of_the_product:
        'Product description in French (500 characters maximum)',
      product_description_in_english:
        'Product description in English (500 characters maximum)',
      logo_on_label: 'Collectif logo on the label',
      pics_of_product: 'Pics of product',
      cuvee_information: 'Product information',
      product_type: 'Product type',
      type_of_wines: 'Type of wines',
      color: 'Color',
      region: 'Region',
      classification: 'Classification',
      type_of_culture: 'Type of culture',
      grape_variety: 'Grape variety',
      perntage_grape_variety: '% Grape variety',
      percentage_alcohol: '% Alcohol',
      type_of_harvest: 'Type of grapping',
      allergen: 'Allergen(s)',
      flavors: 'Flavors',
      format: 'Format',
      price_range: 'Price range',
      no_file_selected: 'No file selected',
      choose_a_file: 'Choose a file',
      tasting_note: 'Tasting note',
      edit_technical_sheet: 'Edit technical sheet',
      type_of_grape: 'Type of grapping',
    },
  },
};
