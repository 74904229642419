import React, { useState, useMemo, useEffect, useRef } from 'react';
import useApiContext from 'hooks/useApiContext';
import {
  useLocation,
  Outlet,
  Link,
  useParams,
  useNavigate,
} from 'react-router-dom';
import useRepo from 'hooks/useRepo';
import { T, O, M, A } from 'TOMA';
import routes from 'router/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { eRoles } from '../../interfaces/Roles';
import Button from '@mui/material/Button';
import s from './AddExhibitor.module.scss';
import { useTranslation } from 'react-i18next';
const columnHelper = createColumnHelper<any>();

function AddExhibitor(): JSX.Element {
  const { pathname } = useLocation();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const {
    userRepository,
    exhibitorRepository,
    eventRepository,
    codificationRepository,
  } = useApiContext();
  const { t } = useTranslation();
  const user = userRepository.getUser();
  const isModerator = user?.role === eRoles.MODERATOR;
  const isAdmin = user?.role === eRoles.ADMIN;
  const [reload, setReload] = useState<number>(0);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const { eventId } = useParams();
  const [dataResp, setDataResp] = useState<any>();
  const {
    response = [],
    loading,
    error,
  } = useRepo(async () => {
    if (
      !activeTypeProduct &&
      !activeCouleur &&
      !activeTypeCepage &&
      !activeTypeCulture &&
      !activeTypeSearch
    ) {
      setReload(0);
    }
    return await exhibitorRepository.getExhibitorsList(eventId, {
      type_product: activeTypeProduct,
      couleur: activeCouleur,
      type_cepage: activeTypeCepage,
      type_culture: activeTypeCulture,
      search: activeTypeSearch,
      reload,
    });
  }, [reload]);
  const refreshData = (): any => {
    setReload((prev) => prev + 1);
  };
  const searchByRaison = (string: any): any => {
    setDataResp(
      response.filter((item) => item.raison_sociale.includes(string))
    );
  };
  const [itemChecked, setItemChecked] = useState<number[]>([]);
  const [isMainChecked, setIsMainChecked] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const itemSelect = (val: number): void => {
    setItemChecked((prevState) => {
      if (prevState.includes(val)) {
        return prevState.filter((item) => item !== val);
      }
      return [...prevState, val];
    });
  };

  const handleMainCheckbox = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const isChecked = e.target.checked;
    setIsMainChecked(isChecked);

    if (isChecked) {
      setItemChecked(response.map((item) => item.id));
    } else {
      setItemChecked([]);
    }
  };
  const columns = [
    columnHelper.accessor('checkbox', {
      header: () => (
        <div style={{ padding: '10px' }}>
          <A.InputCheckbox
            name="mainCheck"
            label=""
            checked={isMainChecked}
            onChange={(e) => handleMainCheckbox(e)}
          />
        </div>
      ),
      cell: (info: any) => {
        const val = info.getValue();
        return (
          <div className="checkbox-wrapper" data-key={val}>
            <A.InputCheckbox
              name={`check_${val}`}
              label=""
              checked={itemChecked.includes(val)}
              onChange={() => itemSelect(val)}
            />
          </div>
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('raison_sociale', {
      header: t('event:company'),
      enableColumnFilter: true,
      cell: (info) => {
        const val = info.getValue();
        return (
          <div className={s.ExhibitorList__status} data-key={val}>
            {val}
          </div>
        );
      },
    }),
    columnHelper.accessor('type_of_product', {
      header: t('event:type_of_product'),
      cell: (info) => {
        const val = info.getValue();
        const labels = val.map((item: any) => item.label);
        return (
          <div className={s.ExhibitorList__status} data-key={val}>
            {labels.join(', ')}
          </div>
        );
      },
    }),
    columnHelper.accessor('color_wine', {
      header: t('event:color_of_wine'),
      cell: (info) => {
        const val = info.getValue();
        const labels = val.map((item: any) => item.label);
        return (
          <div className={s.ExhibitorList__status} data-key={val}>
            {labels.join(', ')}
          </div>
        );
      },
    }),
    columnHelper.accessor('grape_variety', {
      header: t('event:grape_varietal'),
      cell: (info) => {
        const val = info.getValue();
        const labels = val.map((item: any) => item.label);
        return (
          <div className={s.ExhibitorList__status} data-key={val}>
            {labels.join(', ')}
          </div>
        );
      },
    }),
    columnHelper.accessor('type_of_wine', {
      header: t('event:type_of_growing'),
      cell: (info) => {
        const val = info.getValue();
        const labels = val.map((item: any) => item.label);
        return (
          <div className={s.ExhibitorList__status} data-key={val}>
            {labels.join(', ')}
          </div>
        );
      },
    }),
    columnHelper.accessor('invite', {
      header: '',
      cell: (info) => {
        const val = info.getValue();
        return (
          <Button
            variant="outlined"
            onClick={async () => {
              const resp = await eventRepository.inviteExhibitor(eventId, {
                id: val,
              });
              toast(resp.message, { type: resp.status ? 'success' : 'error' });
              refreshData();
            }}
          >
            {t('event:add_exhibitor')}
          </Button>
        );
      },
    }),
  ];
  const data = useMemo(
    () =>
      response.map((e) => {
        return {
          checkbox: e.id,
          raison_sociale: e.raison_sociale,
          type_of_product: e.type_of_product,
          color_wine: e.color_wine,
          grape_variety: e.grape_variety,
          type_of_wine: e.type_of_wine,
          invite: e.id,
        };
      }),
    [response]
  );
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter, // Pass the global filter state here
    },
    manualPagination: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(), // Enable filtering model
  });
  const navigate = useNavigate();

  const [filterCouleur, setFilterCouleur] = useState([]);
  const [filterTypeCulture, setFilterTypeCulture] = useState([]);
  const [filterTypeCepage, setFilterTypeCepage] = useState([]);
  const [filterTypeProduct, setFilterTypeProduct] = useState([]);
  const [filterTypeSearch, setFilterTypeSearch] = useState([]);

  const [activeCouleur, setActiveCouleur] = useState<string>();
  const [activeTypeCulture, setActiveTypeCulture] = useState<string>();
  const [activeTypeCepage, setActiveTypeCepage] = useState<string>();
  const [activeTypeProduct, setActiveTypeProduct] = useState<string>();
  const [activeTypeSearch, setActiveTypeSearch] = useState<string>();

  const { response: couleur } = useRepo(async () => {
    // Fetch data from repository
    return await codificationRepository.get('couleur');
  });
  const { response: type_culture } = useRepo(async () => {
    // Fetch data from repository
    return await codificationRepository.get('type_culture');
  });
  const { response: type_cepage } = useRepo(async () => {
    // Fetch data from repository
    return await codificationRepository.get('cepage');
  });
  const { response: type_product } = useRepo(async () => {
    // Fetch data from repository
    return await codificationRepository.get('type_produit');
  });
  useEffect(() => {
    // Update state when couleur changes
    if (couleur) {
      setFilterCouleur(
        // @ts-expect-error
        couleur.map((item) => ({
          label: item.label,
          value: item.code,
        }))
      );
    }
  }, [couleur]);
  useEffect(() => {
    if (type_culture) {
      setFilterTypeCulture(
        // @ts-expect-error
        type_culture.map((item) => ({
          label: item.label,
          value: item.code,
        }))
      );
    }
  }, [type_culture]);
  useEffect(() => {
    // Update state when couleur changes
    if (type_cepage) {
      setFilterTypeCepage(
        // @ts-expect-error
        type_cepage.map((item) => ({
          label: item.label,
          value: item.code,
        }))
      );
    }
  }, [type_cepage]);
  useEffect(() => {
    // Update state when couleur changes
    if (type_product) {
      setFilterTypeProduct(
        // @ts-expect-error
        type_product.map((item) => ({
          label: item.label,
          value: item.code,
        }))
      );
    }
  }, [type_product]);

  const inviteSelected = (): any => {
    // checkedItems
    eventRepository
      .inviteExhibitor(eventId, { id: itemChecked })
      // @ts-expect-error
      .finally((e: any): any => {
        console.log(e, 'fetchedData');
        refreshData();
      });
  };

  const classActive =
    pathname === routes.home.path
      ? false
      : !pathname.includes('exhibitor-list');
  return (
    <div className={s.Body}>
      <div className={classActive ? s.blur : undefined}>
        <T.Container>
          <M.Loading loading={loading} error={error} fullWiewport>
            <A.Title pt="25" as="h1" fontSize="32" fw="bold" textAlign="center">
              {t('event:add_exhibitor_to_the_event')}
            </A.Title>
            <T.Block mb="20" py="25">
              <T.BlockResponsive bg="grey-05" w="100" p="15">
                <T.Block mx="auto" w="25">
                  <A.InputText
                    type="text"
                    placeholder={t('event:search')}
                    name="search"
                    onChange={(e) => {
                      const column = table.getColumn('raison_sociale');
                      if (column.getCanFilter()) {
                        // Check if filtering is enabled
                        column.setFilterValue(e); // Pass the input value directly
                      } else {
                        console.warn(
                          "Filtering is not enabled for 'raison_sociale'"
                        );
                      }
                    }}
                  />
                </T.Block>
                <T.Flex gap={2}>
                  <A.InputSelect
                    placeholder={t('event:type_of_product')}
                    name="type_product"
                    options={filterTypeProduct}
                    value={activeTypeProduct}
                    onChange={(e) => {
                      setActiveTypeProduct(e.target.value ?? '');
                      refreshData();
                    }}
                  />
                  <A.InputSelect
                    placeholder={t('event:color_of_wine')}
                    name="couleur"
                    options={filterCouleur}
                    value={activeCouleur}
                    onChange={(e) => {
                      setActiveCouleur(e.target.value ?? '');
                      refreshData();
                    }}
                  />
                  <A.InputSelect
                    placeholder={t('event:grape_varietal')}
                    name="type_cepage"
                    options={filterTypeCepage}
                    value={activeTypeCepage}
                    onChange={(e) => {
                      setActiveTypeCepage(e.target.value ?? '');
                      refreshData();
                    }}
                  />
                  <A.InputSelect
                    placeholder={t('event:type_of_growing')}
                    name="type_culture"
                    options={filterTypeCulture}
                    value={activeTypeCulture}
                    onChange={(e) => {
                      setActiveTypeCulture(e.target.value ?? '');
                      refreshData();
                    }}
                  />
                </T.Flex>
              </T.BlockResponsive>
              <div className={s.TableContainer}>
                <table className={s.Table}>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id} className={s.TableTh}>
                            <A.Button
                              appearance="link"
                              color="grey-80"
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}

                              {{
                                asc: <A.Icon icon="chevron-down" ml="5" />,
                                desc: <A.Icon icon="chevron-up" ml="5" />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </A.Button>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => {
                      return (
                        <tr
                          key={row.id}
                          className={s.ExhibitorList__row}
                          data-removed={
                            row.getIsSelected() ? 'true' : undefined
                          }
                        >
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className={s.TableTd}>
                              <div className={s.ExhibitorList__row__div}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </div>
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </T.Block>
            <T.Block pb="20">
              <div style={{ textAlign: 'center' }}>
                <Button
                  variant="outlined"
                  onClick={inviteSelected}
                  disabled={itemChecked.length <= 0}
                >
                  {t('event:add_exhibitor_to_the_event_selection')}
                </Button>
              </div>
            </T.Block>
          </M.Loading>
        </T.Container>
      </div>
      {classActive && (
        <div className={s.ExhibitorList__outlet}>
          <Link
            className={s.ExhibitorList__outlet__close}
            to={
              pathname.includes('events')
                ? routes.home.path + `events/${eventId ?? ''}`
                : ''
            }
            onClick={() => setReload((e) => e + 1)}
          >
            <div>
              <A.Icon icon="x-lg" />
            </div>
          </Link>
          <div className={s.ExhibitorList__outlet__content}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
}

export default AddExhibitor;
