import React, { useState } from 'react';
import { A } from '..';
import styles from './InputPhone.module.scss';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

export interface iInputPhone {
  name: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  label?: string;
  error?: React.ReactNode;
  onChange?: (e?: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const InputPhone = React.forwardRef(function field(
  {
    name,
    value,
    disabled,
    required,
    placeholder,
    onChange,
    onBlur,
    label,
    error,
  }: iInputPhone,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <div className={styles.InputPhone}>
      {label && (
        <label
          className={styles.InputPhone__label}
          data-error={!!error}
          data-disabled={disabled}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </label>
      )}
      <div className={styles.InputPhone__field} data-error={!!error}>
        <PhoneInput
          name={name}
          addInternationalOption={false}
          international
          countries={['AU', 'FR', 'BE', 'DE']}
          defaultCountry="FR"
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>

      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputPhone.defaultProps = {
  value: undefined,
  label: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
};
