import React, { useEffect, useState } from 'react';
import { A } from '..';
import Select, {
  MultiValue,
  SingleValue,
  OptionsOrGroups,
  GroupBase,
} from 'react-select';
import styles from './InputAutocomplete.module.scss';
import join from 'TOMA/_helpers/join';
import { useTranslation } from 'react-i18next';

export interface iOption {
  value: string;
  label: string;
}
export interface iInputAutocomplete {
  name: string;
  options: OptionsOrGroups<iOption, GroupBase<iOption>> | undefined;
  label?: string;
  id?: string;
  value?: MultiValue<iOption> | SingleValue<iOption>;
  defaultValue?: MultiValue<iOption> | SingleValue<iOption>;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: React.ReactNode;
  multiple?: boolean;
  loading?: boolean;
  onChange?: (e: MultiValue<iOption> | SingleValue<iOption>) => void;
  onBlur?: () => void;
}
export const InputAutocomplete = React.forwardRef(function field(
  {
    name,
    id,
    value,
    defaultValue,
    disabled,
    required,
    options,
    placeholder,
    onChange,
    onBlur,
    label,
    error,
    loading,
    multiple,
  }: iInputAutocomplete,
  ref: React.ForwardedRef<any>
) {
  const { t } = useTranslation();
  return (
    <div className={styles.InputAutocomplete}>
      {label && (
        <label
          htmlFor={id ?? name}
          className={styles.InputAutocomplete__label}
          data-error={!!error}
          data-disabled={disabled}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </label>
      )}
      <div className={styles.InputAutocomplete__group}>
        <Select
          value={value}
          defaultValue={defaultValue}
          name={name}
          isMulti={multiple}
          options={options}
          placeholder={placeholder ?? `${t('common:select')}...`}
          classNamePrefix="react-select"
          className={join(
            styles.InputAutocomplete__field,
            error ? styles.error : ''
          )}
          isDisabled={disabled}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          isLoading={loading}
        />
        <A.Text as="div" mt="5" fontSize="14" color="alert">
          {error}
        </A.Text>
      </div>
    </div>
  );
});

InputAutocomplete.defaultProps = {
  id: undefined,
  defaultValue: undefined,
  label: undefined,
  disabled: undefined,
  required: undefined,
  error: undefined,
};
