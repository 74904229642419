import React from 'react';
import {
  ExhibitorRepository,
  iExhibitorRepository,
} from 'services/Exhibitor/Exhibitor.repository';
import { UserRepository, iUserRepository } from 'services/User/User.repository';
import {
  MediaRepository,
  iMediaRepository,
} from 'services/Media/Media.repository';
import {
  ProductRepository,
  iProductRepository,
} from 'services/Product/Product.repository';
import {
  CodificationRepository,
  iCodificationRepository,
} from 'services/Codification/Codification.repository';
import {
  ConfirmationRepository,
  iConfirmationRepository,
} from 'services/Confirmation/Confirmation.repository';
import {
  PreferenceRepository,
  iPreferenceRepository,
} from 'services/Preference/Preference.repository';
import {
  EventRepository,
  iEventRepository,
} from 'services/Event/Event.repository';
import {
  ExhibitorEventsRepository,
  iExhibitorsEventRepository,
} from 'services/ExhibitorsEvent/ExhibitorsEvent.repository';
import {
  CompanyRepository,
  iCompanyRepository,
} from './services/Company/Company.repository';

const apiRef = {
  exhibitorRepository: new ExhibitorRepository(),
  userRepository: new UserRepository(),
  mediaRepository: new MediaRepository(),
  productRepository: new ProductRepository(),
  codificationRepository: new CodificationRepository(),
  confirmationRepository: new ConfirmationRepository(),
  preferenceRepository: new PreferenceRepository(),
  eventRepository: new EventRepository(),
  exhibitorEventRepository: new ExhibitorEventsRepository(),
  companyRepository: new CompanyRepository(),
};

export interface iApiContext {
  exhibitorRepository: iExhibitorRepository;
  userRepository: iUserRepository;
  mediaRepository: iMediaRepository;
  productRepository: iProductRepository;
  codificationRepository: iCodificationRepository;
  confirmationRepository: iConfirmationRepository;
  preferenceRepository: iPreferenceRepository;
  eventRepository: iEventRepository;
  exhibitorEventRepository: iExhibitorsEventRepository;
  companyRepository: iCompanyRepository;
}

const ApiContext = React.createContext<iApiContext>(apiRef);

function ApiProvider({ children }: { children: React.ReactNode }): JSX.Element {
  return <ApiContext.Provider value={apiRef}>{children}</ApiContext.Provider>;
}

export default ApiContext;
export { ApiProvider };
