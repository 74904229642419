import React, { useState } from 'react';
import { A } from '..';
import { iIcons } from '../Icon/Icons.type';
import styles from './InputText.module.scss';

export interface iInputText {
  name: string;
  id?: string;
  value?: string;
  type: 'text' | 'password' | 'email' | 'number' | 'tel' | 'url';
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  min?: number;
  max?: number;
  maxLength?: number;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  label?: string;
  error?: React.ReactNode;
  // onChange?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  loading?: boolean;
  valid?: boolean;
  icon?: iIcons;
  information?: React.ReactNode;
  labelHidePwd?: string;
  labelShowPwd?: string;
  format?: (value: string) => string;
  autocomplete?: string;
  after?: () => React.ReactNode;
}

export const InputText = React.forwardRef(function field(
  {
    name,
    id,
    value,
    type,
    inputMode,
    min,
    max,
    maxLength,
    disabled,
    required,
    placeholder,
    onChange,
    onBlur,
    label,
    error,
    loading,
    valid,
    icon,
    information,
    labelHidePwd,
    labelShowPwd,
    after,
    format = (e) => e || '',
    autocomplete,
  }: iInputText,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const [newType, setNewType] = useState<string>(type);

  function togglePassword(): void {
    setNewType((t) => (t === 'password' ? 'text' : 'password'));
  }

  function handleOnChange(value: string): void {
    if (onChange) onChange(format(value));
  }

  return (
    <div className={styles.InputText}>
      {label && (
        <label
          htmlFor={id ?? name}
          className={styles.InputText__label}
          data-error={!!error}
          data-disabled={disabled}
        >
          {label}
          {required && <i data-asterix>*</i>}
        </label>
      )}
      {information && (
        <A.Text
          as="div"
          mb="10"
          mt="-3"
          fontSize="14"
          color={disabled ? 'grey-20' : 'grey-50'}
        >
          {information}
        </A.Text>
      )}
      <div className={styles.InputText__group}>
        <input
          name={name}
          type={newType}
          value={value}
          min={min}
          max={max}
          maxLength={maxLength}
          inputMode={inputMode}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => {
            if (onChange) handleOnChange(e.target.value);
          }}
          onBlur={onBlur}
          className={styles.InputText__field}
          required={required}
          data-error={!!error}
          id={id ?? name}
          ref={ref}
          autoComplete={autocomplete}
        />
        <div className={styles.InputText__icons}>
          {loading && (
            <span className={styles.InputText__loading}>
              <A.Loading width="20px" />
            </span>
          )}

          {valid === true && (
            <span className={styles.InputText__valid}>
              <A.Icon icon="check2-circle" />
            </span>
          )}

          {valid === false && (
            <span className={styles.InputText__notValid}>
              <A.Icon icon="x-circle" />
            </span>
          )}

          {icon && (
            <span className={styles.InputText__icon}>
              <A.Icon icon={icon} />
            </span>
          )}

          {after?.()}

          {type === 'password' && (
            <button
              className={styles.InputText__passwordButton}
              type="button"
              onClick={togglePassword}
              aria-label={newType === 'password' ? labelShowPwd : labelHidePwd}
              title={newType === 'password' ? labelShowPwd : labelHidePwd}
            >
              <A.Icon icon={newType === 'password' ? 'eye-slash' : 'eye'} />
            </button>
          )}
        </div>
      </div>

      <A.Text as="div" mt="5" fontSize="14" color="alert">
        {error}
      </A.Text>
    </div>
  );
});

InputText.defaultProps = {
  id: undefined,
  value: undefined,
  inputMode: undefined,
  label: undefined,
  min: undefined,
  max: undefined,
  maxLength: undefined,
  disabled: undefined,
  required: undefined,
  placeholder: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
  loading: undefined,
  valid: undefined,
  icon: undefined,
  information: undefined,
  labelHidePwd: undefined,
  labelShowPwd: undefined,
};
