export interface iCodificationRest {
  topicCode: string;
  topic: string;
  code: string;
  label: string;
  filter: string;
}

export enum eTypeProduit {
  BEER = 'type_produit:BEER_OTHER',
  CIDER = 'type_produit:CIDER_AOC_OTHER',
  SPIRITS = 'type_produit:SPIRITS',
  WINE = 'type_produit:WINE',
}

export enum eClassification {
  AOC_AOP_PDO = 'classification:AOC_AOP_PDO',
  IGP_PGI = 'classification:IGP_PGI',
  VIN_DE_FRANCE = 'classification:VIN_DE_FRANCE',
  AUTRES_VINS = 'classification:AUTRES_VINS',
}
