import React, { ReactNode } from 'react';
import styles from './Col.module.scss';
import { join } from '../../_HOC/withStyle';

type tCol =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

interface iCol {
  children?: ReactNode;
  maxWidth?: React.CSSProperties['maxWidth'];
  col?: tCol;
  colxs?: tCol;
  colsm?: tCol;
  colmd?: tCol;
  collg?: tCol;
  colxl?: tCol;
  colxxl?: tCol;
  offset?: tCol;
  flex?: React.CSSProperties['flex'];
  className?: string;
}

export function Col({
  children,
  maxWidth,
  col,
  colxs,
  colsm,
  colmd,
  collg,
  colxl,
  colxxl,
  offset,
  flex,
  className,
}: iCol): JSX.Element {
  let style: React.CSSProperties = {};
  function setStyle(c: tCol, name: string): void {
    style = { ...style, [`--${name}`]: `${8.3333 * parseInt(c, 10)}%` };
  }

  if (col) setStyle(col, 'col');
  if (colxs) setStyle(colxs, 'colxs');
  if (colsm) setStyle(colsm, 'colsm');
  if (colmd) setStyle(colmd, 'colmd');
  if (collg) setStyle(collg, 'collg');
  if (colxl) setStyle(colxl, 'colxl');
  if (colxxl) setStyle(colxxl, 'colxxl');
  if (colxxl) setStyle(colxxl, 'colxxl');
  if (offset) setStyle(offset, 'offset');
  if (flex) style = { ...style, flex };

  if (maxWidth) style.maxWidth = maxWidth;

  return (
    <div className={join(className, styles.Col)} style={style}>
      {children}
    </div>
  );
}

Col.defaultProps = {
  maxWidth: undefined,
  col: undefined,
  colxs: undefined,
  colsm: undefined,
  colmd: undefined,
  collg: undefined,
  colxl: undefined,
  colxxl: undefined,
  offset: undefined,
  flex: undefined,
  className: undefined,
};
