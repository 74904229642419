export default {
  signup: {
    my_comp_exhibiting: 'Mon entreprise expose sur l’évènement',
    sur_un_stand_individual:
      'Sur un stand individuel ou partagé (avec Business France)',
    sur_un_stand_collectif:
      'Sur un stand collectif régional ou interprofessionnel',
    selecting_event: 'Selectionner l’évènement',
    selecting_collectif: 'Sélectionner le collectif',
    title: 'Plateforme d’enregistrement pour Loire Wines Trade Tasting',
    subtitle: 'Demande de création de compte :',
    form: {
      text: {
        bold: 'Contact pour la création des logins > ',
        normal: 'Information non visible** sur les supports de communication',
      },
      labels: {
        firstname: 'Prénom',
        lastname: 'Nom',
        phone: 'Téléphone',
        companyName: 'Raison Sociale',
        siret: 'Siret',
        email: 'Email / login',
        password: 'Choisir votre mot de passe',
        passwordConfirmation: 'Confirmer votre mot de passe',
      },
      validate: 'Valider',
      asterix: {
        required: 'Information obligatoire',
        info: 'Les informations demandées ci-dessus servent à la création du compte et des logins de connexion pour votre entreprise. Ces informations ne seront pas visibles, elles sont conservées pour la gestion du dossier. (1 adresse e-mail = 1 compte entreprise)',
      },
      success:
        "Vous allez recevoir un e-mail d'ici quelques minutes pour confirmer votre compte.",
    },
  },
};
