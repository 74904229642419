export default {
  login: {
    title: 'Importer registration for Loire Wines Trade Tasting',
    signIn: 'Se connecter',
    signUp: "S'inscrire",
    forgotPassword: 'Mot de passe oublier?',
    email: 'Email',
    password: 'Mot de passe',
    test: 'Test',
  },
};
