export default {
  event: {
    events: 'Évènements',
    societe: 'Société',
    contact: 'Contact pour cet évènement',
    list_of_events: 'Liste des évènements',
    new_event: 'Nouvel évènement',
    participer: 'Visible',
    name_of_the_event: "Nom de l'évènement",
    date: 'Date',
    country: 'Pays',
    creator: 'Créateur',
    status: 'Statut',
    simple_extraction: 'Extraction simple',
    full_extraction: 'Extraction complète',
    upload_csv: 'Charger CSV',
    add_exhibitors: 'Ajouter des exposants',
    find_an_exhibitors: 'Trouver un exposant',
    list_of_exhibitors: 'Listes des exposants',
    export_exhibitors: 'Export exposants',
    event_informations: "Informations sur l'événement",
    start_event: "Date du début de l'événement",
    end_event: "Date de la fin de l'événement",
    city_of_event: "Ville de l'événement",
    country_of_event: "Pays de l'événement",
    registration_closing_date: 'Date de fermeture des inscriptions',
    showroom_on_event: 'Showroom sur cet événement',
    sample_informations: 'Informations sur les échantillons',
    numbers_of_sample_send: "Nombre d'échantillons à envoyer",
    address_to_send_semple: 'Adresse pour envoyer les échantillons',
    limit_date_to_receive_sample: 'Date limite pour recevoir des échantillons',
    registration: 'Enregistrement du dossier',
    event_information: 'Informations de l’évènement',
    contact_for_this_event: 'Contact pour cet évènement',
    information_de_la_societe: 'Informations de la société',
    sur_name: 'Prenom',
    last_name: 'Nom',
    mobile_phone: 'Téléphone portable',
    email: 'E-Mail',
    contact_position: 'Position du contact',
    your_products: 'Votre sélection de produits',
    possibility_of_presenting_up_to_10_references:
      'Possibilité de présenter jusqu’à 10 références',
    select_product: 'Selectionner un produit',
    add_a_new_product: 'Créer un nouveau produit',
    save: 'Enregister',
    save_and_transmit: 'Enregister et transmettre',
    possibility_to_reconnect_and_sequence_the_entry_of_information:
      '*Possibilité de vous reconnecter et séquencer la saisie des informations.',
    your_entry_is_finalized_the_elements_can_no_longer_be_modified:
      '**Votre saisie est finalisée, les éléments ne peuvent plus être modifiés.',
    date_of_event: "Date de l'événement",
    icon: 'Ajouter le produit selectionné du catalogue',
    add_product_from_catalog:
      'Ajouter le produit selectionné dans votre catalogue',
    invite: 'Inviter',
    subtitle_of_the_event: "Sous titre de l'évènement",
    logo: 'Logo',
    e_catalogue_url: 'URL du e-catalogue',
    inviter_par_email: 'Inviter par e-mail',
    send_email: 'Envoyer un e-mail',
    copy_the_link: 'Copier le lien',
    copy_the_link_success: "Le code d'invitation a été copié avec succès",
    invite_with_email: 'Inviter par e-mail',
    import_exhibitor_database: 'Importer base de données exposants',
    active: 'Activé',
    dossier: 'Dossier',
    edit: 'Editer',
    required: 'Ce champ est obligatoire',
    number_exhibitors: "Nombre d'exposants enregistrés à cet événement",
    add_exhibitor_to_the_event: "Ajouter un exposant à l'événement",
    company: 'Raison sociale',
    type_of_product: 'Type de produit',
    color_of_wine: 'Couleur du vin',
    grape_varietal: 'Cépage',
    type_of_growing: 'Type de viticulture',
    add_exhibitor: 'Ajouter un exposant',
    add_exhibitor_to_the_event_selection: 'Ajouter les exposants selectionnés',
    exhibitror_successfilly_updated:
      'Les renseignements sur les exposants ont été mis à jour avec succès',
  },
};
